/* PMA Reducer */

import { pmaActionConstants } from "../../_constants";
import { GetStateStorage, IsValidLoginAuth } from "common/storage";
import { tableSortUtil } from "views/Pma/TableSortUtil";
import { renderLogicUtil } from "../../views/Pma/RenderLogicUtil";
import { propertyUtil } from "views/Pma/PropertyUtil";
import moment from "moment";

// const stateData = GetStateStorage();
// const agentData = stateData.userData;

//const isFullValidSession = IsValidLoginAuth();

const getTodayDate = moment(new Date()).format("YYYY-MM-DD");

const initState = {
  currentPeriod: 5,
  version: "year-end-2022", //version that agent is looking at
  //version: "mq3", //version that agent is looking at
  //version: "qv4",
  mailingVersion: "q", //version that we gonna print
  pdfPrintVersion: "mq",
  typeOfMQVersion: "mq2",
  typeOfQVersion: "qv4",
  typeOfVersion: "mq2",
  typeOfYEVersion: "ye2",
  pmaMailerDataLoaded: false,
  currentStep: 0,
  currentArea: {},
  missionStatement: null,
  showDataVerification: true,
  currentAreaFirstMailProID: 0,
  recentlyUpdatedMailProID: null,
  isLoading: false,
  hoSearch: "",
  hoFilter: "active", //filter by active by default
  hoQuickFilter: "",
  hoAdvanceFilter: "",
  advanceFilterOptionData: null,
  viewingGeneratedCL: true,
  mailProHeader: "homeValuation",
  mailProSortDirection: "desc",
  propertyData: [],
  MQCityDetails: [],
  mlsAreaObjects: [],
  getSingleHOData: [],
  marketActivity: [],
  mlsAreas: [],
  quarterlyAreaTable: [],
  quarterlyCityTable: [],
  propertyPhotoDetailsDTO: [],
  localSellingAgents: [],
  dataVerification: [],
  coverPageDetails: null,
  nextPMAPrintDate: null,
  singleHOData: [],
  testimonials: null,
  agentDesignations: null,
  agentAdvantages: null,
  salePriceForCities: null,
  oldPhotoDetails: [],
  yearEnd: null,
  qArea: null,
  qCity: null,
  aprilData: null,
  pmaQuarterlyCombinedCityDTO: null,
  explicitEnableNewMailProInit: false, // whether explict show new mailpro init dialog or not
  disableNewMailingForCurrentSession: false,
  newMailProInitDialogMode: null,
  newMailingMailProData: [],
  newMailingEditedData: [],
  quadrantModalOpen: false,
  missionStatementModalOpen: false,
  photoCheckboxModalOpen: false,
  recentActivityPhotoList: null,
  oldRecentActivityPhotoList: null,
  quadrantDetails: [],
  volunteerDetails: [],
  volunteerList: [],
  qrCodeMailerProID: "",
  agentData: null,
  altAgentData: null,
};

export function pmaReducer(state = initState, action) {
  switch (action.type) {
    case pmaActionConstants.IS_LOADING:
      // console.log('switching loading status reducer to', action.payload.status);
      return {
        ...state,
        isLoading: action.payload.status,
      };
    case pmaActionConstants.SWITCH_VERSION:
      //console.log("inside reducer switch version", action.payload);
      return {
        ...state,
        version: action.payload.version,
        hoSearch: "",
        hoFilter: "active",
        hoQuickFilter: "",
        hoAdvanceFilter: "",
        advanceFilterOptionData: "",
        mailProHeader: "homeValuation",
        mailProSortDirection: "desc",
      };

    case pmaActionConstants.UPDATE_MISSION_STATEMENT_MODAL:
      console.log("inside reducer UPDATE MISSSION STATEMENT", action.payload);
      return {
        ...state,
        missionStatementModalOpen: action.payload.isModalOpen,
      };

    case pmaActionConstants.UPDATE_QUADRANT_MODAL:
      //console.log("inside reducer", action.payload);
      return {
        ...state,
        quadrantModalOpen: action.payload.isModalOpen,
      };

    case pmaActionConstants.UPDATE_CHECKBOX_PHOTO_MODAL:
      console.log("inside reducer update mphoto modal", action.payload);
      return {
        ...state,
        photoCheckboxModalOpen: action.payload.isModalOpen,
      };

    case pmaActionConstants.SWITCH_STEP:
      return {
        ...state,
        isLoading: false,
        currentStep: action.payload.step,
      };
    case pmaActionConstants.SWITCH_AREA:
      //  console.log("reducer switch area", action.payload);
      return {
        ...state,
        hoSearch: "",
        hoFilter: "active",
        hoQuickFilter: "",
        hoAdvanceFilter: "",
        advanceFilterOptionData: "",
        mailProHeader: "homeValuation",
        mailProSortDirection: "desc",
        isLoading: action.payload.isLoading,
        currentArea: action.payload.area,
        propertyData: [],
        singleHOData: [],
        pmaMailerDataLoaded: false,
        currentAreaFirstMailProID: 0,
      };
    case pmaActionConstants.SWITCH_AREA_WITH_STEP:
      return {
        ...state,
        hoSearch: "",
        hoFilter: "active",
        hoQuickFilter: "",
        hoAdvanceFilter: "",
        advanceFilterOptionData: "",
        mailProHeader: "homeValuation",
        mailProSortDirection: "desc",
        isLoading: true,
        currentArea: action.payload.area,
        currentStep: action.payload.step,
        propertyData: [],
        singleHOData: [],
        pmaMailerDataLoaded: false,
        currentAreaFirstMailProID: 0,
      };

    case pmaActionConstants.UPDATE_CURRENT_AREA_DATA:
      return {
        ...state,
        currentArea: action.payload,
      };
    case pmaActionConstants.SWITCH_CL_VERSION:
      const { version } = action.payload;
      return {
        ...state,
        viewingGeneratedCL: version,
      };
    case pmaActionConstants.RESET_MAILPRO_HEADER_AND_ALL_FILTERS:
      return {
        ...state,
        hoSearch: "",
        hoFilter: "active", //show active props only by default
        hoQuickFilter: "",
        hoAdvanceFilter: "",
        advanceFilterOptionData: "",
        mailProHeader: "", // have to reset mailpro header inc ase of any operation except first load/area change
        mailProSortDirection: "desc",
      };
    case pmaActionConstants.SET_CURRENT_RECORD:
      return {
        ...state,
        currentRecord: action.payload.record,
      };
    case pmaActionConstants.SET_BLOCKED_REASON:
      return {
        ...state,
        blockedReason: action.payload.reason,
      };
    case pmaActionConstants.SET_TAGS:
      return {
        ...state,
      };
    case pmaActionConstants.UPDATE_HO_SEARCH:
      return {
        ...state,
        hoSearch: action.payload.searchTerm,
        //we dont have to reset the mailpro as in this case we are doing to perform search on already sorted data
        // mailProHeader: "",// have to reset mailpro header inc ase of any operation except first load/area change
        // mailProSortDirection: "desc",
      };
    case pmaActionConstants.UPDATE_HO_FILTER:
      return {
        ...state,
        hoFilter: action.payload.filterStatus,
        mailProHeader: "", // have to reset mailpro header inc ase of any operation except first load/area change
        mailProSortDirection: "desc",
      };
    case pmaActionConstants.UPDATE_HO_QUICK_FILTER:
      return {
        ...state,
        hoQuickFilter: action.payload.quickFilter,
        mailProHeader: action.payload.quickFilter ? "lastSaleDate" : "",
        mailProSortDirection: action.payload.quickFilter ? "desc" : "",
      };
    case pmaActionConstants.UPDATE_HO_ADVANCE_FILTER:
      return {
        ...state,
        hoAdvanceFilter: action.payload.advanceFilter,
        mailProHeader: "",
        mailProSortDirection: "",
      };
    case pmaActionConstants.UPDATE_HO_ADVANCE_FILTER_OPTION_DATA:
      return {
        ...state,
        advanceFilterOptionData: action.payload.advanceFilterOptionData,
        //mailProHeader: "",
        //mailProSortDirection: "",
      };
    case pmaActionConstants.RESET_MAILPRO_HEADER_SORT_ONLY:
      return {
        ...state,
        mailProHeader: "", // have to reset mailpro header inc ase of any operation except first load/area change
        mailProSortDirection: "desc",
      };
    case pmaActionConstants.TABLE_SORT:
      return {
        ...state,
        propertyData: action.payload.propertyData,
        mailProHeader: action.payload.mailProHeader,
        mailProSortDirection: action.payload.mailProSortDirection,
        isLoading: false,
      };
    case pmaActionConstants.APPROVE_MAILING_LIST:
      return {
        ...state,
      };
    case pmaActionConstants.POST_HOMEOWNERS_DATA:
      return { ...state };

    case pmaActionConstants.GET_MAILER_PRO_DETAILS_SUCCESS:
      const lastPMAPrintDate = propertyUtil.getLastPrintDate();
      const nextPMAPrintDate =
        action.payload.nextPMAPrintDate || state.nextPMAPrintDate;

      let propertyData = renderLogicUtil.getQuickFilterData(
        action.payload.propertyData,
        nextPMAPrintDate,
        lastPMAPrintDate,
        action.payload.agentData
      );

      let finalData = action.payload.defaultSort
        ? tableSortUtil.hoValuationSorting(
            propertyData,
            action.payload.agentData,
            false
          )
        : propertyData;

      return {
        ...state,
        propertyData: finalData, //add data by default sorted by homeValuation in desc order
        currentAreaFirstMailProID: finalData.find(
          (property) =>
            property.mailerProID !== "" && property.mailerProID !== null
        ).mailerProID, //this will tell that new area data is loaded.
        isLoading: false,
        pmaMailerDataLoaded: action.payload.pmaMailerDataLoaded,
      };

    case pmaActionConstants.GET_MAILER_PRO_DETAILS_FAILURE:
      console.log("inside mailer pro failure");
      return {
        ...state,
        propertyData: action.payload.propertyData,
        pmaMailerDataLoaded: action.payload.pmaMailerDataLoaded,
      };

    case pmaActionConstants.UPDATE_HOMEOWNER_PROP:
      const { mailerProID, changeDetails, hoData } = action.payload;

      let index = state.propertyData
        .map((e) => e.mailerProID)
        .indexOf(mailerProID);

      const actualHO = state.propertyData[index];
      actualHO.changeDetails = { ...changeDetails };
      state.propertyData[index] = { ...actualHO };
      return {
        ...state,
        propertyData: [...state.propertyData],
      };
    case pmaActionConstants.UPDATE_RECENT_UPDATED_MAILPRO_ID:
      return {
        ...state,
        recentlyUpdatedMailProID: action.payload,
      };
    case pmaActionConstants.GET_CITY_AREA_MAP:
      // console.log('reducer getting city area map');
      return {
        ...state,
      };
    case pmaActionConstants.GET_MAILPRO_ID_DETAILS:
      // console.log('reducer getting mailpro id details', action.payload);
      return {
        ...state,
        getSingleHOData: action.payload,
      };
    case pmaActionConstants.GET_MAILPRO_STATUS:
      //console.log('reducer getting mailpro status', action.payload);
      return {
        ...state,
      };
    case pmaActionConstants.GET_MARKET_ACTIVITY:
      // console.log('getting market activity reducer', action.payload);
      if (action.payload.clear) {
        return {
          ...state,
          marketActivity: action.payload,
          // quarterlyAreaTable: [],
          // quarterlyCityTable: [],
        };
      } else {
        return {
          ...state,
          marketActivity: action.payload,
        };
      }

    case pmaActionConstants.GET_NAVBAR_INFO:
      console.log("GET NAVBAR INFO REDUCER", action.payload);
      const modifiedDetails = renderLogicUtil.buildNavbarDataForMailPro(
        action.payload
      );
      return {
        ...state,
        mlsAreas: modifiedDetails,
      };

    case pmaActionConstants.UPDATE_NAVBAR:
      let areaData = action.payload;
      //we call same action in case of approaval of mailing list so we have to check if its array or not. if its array no need to convert it because it already an area data
      if (!Array.isArray(areaData)) {
        areaData = renderLogicUtil.buildNavbarDataForMailPro(action.payload);
      }
      return {
        ...state,
        mlsAreas: areaData,
      };

    case pmaActionConstants.GET_MQ_CITY_DETAILS:
      // console.log('reducer getting total units', action.payload);
      return {
        ...state,
        MQCityDetails: action.payload,
      };
    case pmaActionConstants.GET_DATA_VERIFICATION:
      return {
        ...state,
        dataVerification: action.payload,
      };

    case pmaActionConstants.GET_APRIL_API:
      // console.log("reducer getting april API", action.payload);
      return {
        ...state,
        aprilData: action.payload,
        // MQCityDetails: null,
        // agentAdvantages: null,
        // agentDesignations: null,
        // marketActivity: null,
        // qAreaAndCityDetails: null,
        // testimonials: null,
        // yearEnd: null
      };
    case pmaActionConstants.GET_MAY_API:
      //  console.log("reducer getting MAY API", action.payload);
      const {
        quarterlyAreaTable,
        MQCityDetails,
        agentDesignations,
        coverPageDetails,
        // testimonials
      } = action.payload;

      return {
        ...state,
        quarterlyAreaTable,
        MQCityDetails,
        agentDesignations,
        testimonials: action.payload.testimonials,
        coverPageDetails,
      };

    case pmaActionConstants.UPDATE_RECENT_ACTIVITY_DATA:
      console.log(
        "reducer getting UPDATE_RECENT_ACTIVITY_DATA",
        action.payload
      );

      return {
        ...state,
        recentActivityPhotoList: action.payload,
      };

    case pmaActionConstants.GET_RECENT_ACTIVITY_PHOTOS:
      // console.log("reducer getting GET_RECENT_ACTIVITY_PHOTOS", action.payload);

      return {
        ...state,
        recentActivityPhotoList: action.payload,
        oldRecentActivityPhotoList: action.payload,
      };

    case pmaActionConstants.GET_TABLE_PMA_API:
      //console.log("reducer getting TABLE PMA API", action.payload);

      return {
        ...state,
        quarterlyAreaTable: action.payload.quarterlyAreaTable,
        MQCityDetails: action.payload.MQCityDetails,
        testimonials: action.payload.testimonials,
        coverPageDetails: action.payload.coverPageDetails,
      };
    case pmaActionConstants.GET_DONUT_AND_TABLE:
      //console.log("action.payload is", action.payload);
      return {
        ...state,
        singleHOData: action.payload.mailerPrintInfoDTO.mailerProDTO,
        agentData: action.payload.mailerPrintInfoDTO.listgenUserDTO,
        // currentArea,
        coverPageDetails: action.payload.pmaCoverPhotoDTO,
        quarterlyAreaTable: action.payload.pmaQuarterlyAreaDTO,
        quarterlyCityTable: action.payload.pmaQuarterlyCityDTO,

        MQCityDetails: action.payload.pmaQuarterlyCityDTO,
        testimonials: action.payload.mailerPrintInfoDTO.lgTestimonialsDTO,
        missionStatement: action.payload.missionStatementDTO,
        pmaQuarterlyCombinedCityDTO: action.payload.pmaQuarterlyCombinedCityDTO,
        quadrantDetails: action.payload.quadrantInfoList,
        volunteerDetails: action.payload.bpciList,
        propertyPhotoDetailsDTO: action.payload.propertyPhotoDetailsDTO,
      };
    case pmaActionConstants.GET_DASHBOARD_DONUT_API:
      return {
        ...state,
        coverPageDetails: action.payload.pmaCoverPhotoDTO,
        aprilData: {
          pmaQuarterlyAreaDTO: action.payload.pmaQuarterlyAreaDTO,
          pmaQuarterlyCityDTO: action.payload.pmaQuarterlyCityDTO,
        },
      };

    case pmaActionConstants.GET_PMA_DETAILS_API:
      // console.log(
      //   "inside reducer pma details",
      //   action.payload
      //console.log("****pma", action.payload);

      console.log("**********action.payload", action.payload);
      return {
        ...state,
        testimonials: action.payload.mailerPrintInfoDTO.lgTestimonialsDTO,
        singleHOData: action.payload.mailerPrintInfoDTO.mailerProDTO,
        agentData: action.payload.mailerPrintInfoDTO.listgenUserDTO,
        altAgentData: action.payload.mailerPrintInfoDTO.listgenUserDTO,
        coverPageDetails: action.payload.pmaCoverPhotoDTO,
        quarterlyAreaTable: action.payload.pmaQuarterlyAreaDTO,
        quarterlyCityTable: action.payload.pmaQuarterlyCityDTO,
        MQCityDetails: action.payload.pmaQuarterlyCityDTO,
        quadrantDetails: action.payload.quadrantInfoList,
        volunteerDetails: action.payload.bpciList,
        bubbleBoxes: action.payload.marketValueInfoList,
        propertyPhotoDetailsDTO: action.payload.propertyPhotoDetailsDTO,
        aprilData: {
          pmaQuarterlyCityDTO: action.payload.pmaQuarterlyCityDTO,
          pmaQuarterlyAreaDTO: action.payload.pmaQuarterlyAreaDTO,
        },
        missionStatement: action.payload.missionStatementDTO,
      };

    case pmaActionConstants.GET_MARKET_TRENDS_API:
      const {
        pmaQuarterlyCityDTO,
        pmaQuarterlyAreaDTO,
      } = action.payload.resData;

      return {
        ...state,
        coverPageDetails: action.payload.resData.pmaCoverPhotoDTO,
        aprilData: { pmaQuarterlyCityDTO, pmaQuarterlyAreaDTO },
        pmaQuarterlyCombinedCityDTO:
          action.payload.resData.pmaQuarterlyCombinedCityDTO,
        quadrantDetails: action.payload.resData.quadrantInfoList,
        oldPhotoDetails: JSON.parse(
          JSON.stringify(
            action.payload.resData.pmaQuarterlyAreaDTO.currentSoldListings
          )
        ),
        singleHOData: action.payload.singleHOData,
        propertyPhotoDetailsDTO: action.payload.resData.propertyPhotoDetailsDTO,
        testimonials:
          action.payload.resData.mailerPrintInfoDTO.lgTestimonialsDTO,
      };

    case pmaActionConstants.GET_AGENT_QR:
      return {
        ...state,
        qrCodeMailerProID: action.payload,
      };

    case pmaActionConstants.GET_PROPERTY_PHOTO_DETAILS:
      return {
        ...state,
        photoTableData: action.payload.resData.photoDetails,
      };

    case pmaActionConstants.GET_TABLE_BUBBLE_DATA:
      return {
        ...state,
        testimonials: action.payload.testimonials,
        coverPageDetails: action.payload.coverPageDetails,
        quarterlyCityTable: action.payload.quarterlyCityTable,
        quarterlyAreaTable: action.payload.quarterlyAreaTable,
      };

    case pmaActionConstants.GET_BUBBLE_PRICE_RANGE:
      return {
        ...state,
        testimonials: action.payload.lgTestmonialsDTO,
        coverPageDetails: action.payload.pmaCoverPhotoDTO,
        quarterlyCityTable: action.payload.pmaQuarterlyCityDTO,
        quarterlyAreaTable: action.payload.pmaQuarterlyAreaDTO,
        singleHOData: action.payload.mailerPrintInfoDTO.mailerProDTO,
        bubbleBoxes: action.payload.marketValueInfoList,
      };

    case pmaActionConstants.GET_SPEEDOMETER_DATA:
      return {
        ...state,
        coverPageDetails: action.payload.coverPageDetails,
        quarterlyAreaTable: action.payload.quarterlyAreaTable,
        quarterlyCityTable: action.payload.quarterlyCityTable,
        singleHOData: action.payload.singleHOData,
        testimonials: action.payload.testimonials,
      };

    case pmaActionConstants.GET_BUBBLE_BOX_DETAILS:
      return {
        ...state,
        bubbleBoxes: action.payload.bubbleBoxDetails,
      };

    case pmaActionConstants.GET_QUADRANT_DETAILS:
      return {
        ...state,
        quadrantDetails: action.payload.quadrantDetails,
      };

    case pmaActionConstants.GET_VOLUNTEER_LIST:
      console.log("inside reducer get volunteer list", action.payload);
      return {
        ...state,
        volunteerList: action.payload,
      };

    case pmaActionConstants.GET_DECEMBER_DATA:
      return {
        ...state,
        coverPageDetails: action.payload.coverPageDetails,
        aprilData: {
          pmaAprilCityDTO: action.payload.quarterlyCityTable,
          pmaAprilRunAreaDTO: action.payload.quarterlyAreaTable,
        },
        propertyPhotoDetailsDTO: action.payload.propertyPhotoDetailsDTO,
        testimonials: action.payload.testimonials,
        quarterlyAreaTable: action.payload.quarterlyAreaTable,
        quarterlyCityTable: action.payload.quarterlyCityTable,
        singleHOData: action.payload.singleHOData,
        agentData: action.payload.agentData,
        pmaQuarterlyCombinedCityDTO: action.payload.pmaQuarterlyCombinedCityDTO,
        quadrantDetails: action.payload.quadrantInfoList,
      };
    case pmaActionConstants.GET_MQ2_CITY_DETAILS:
      const { testimonials, pmaMidQuarterlyAreaDTO } = action.payload;
      return {
        ...state,
        testimonials,
        MQCityDetails: pmaMidQuarterlyAreaDTO,
      };

    case pmaActionConstants.GET_LOCAL_SELLING_AGENTS:
      return {
        ...state,
        localSellingAgents: action.payload,
      };

    case pmaActionConstants.GET_QUARTER_AREA_TABLE:
      if (action.payload.clear) {
        return {
          ...state,
          quarterlyAreaTable: action.payload,
        };
      } else {
        return {
          ...state,
          quarterlyAreaTable: action.payload,
        };
      }

    case pmaActionConstants.GET_QUARTER_CITY_TABLE:
      return {
        ...state,
        quarterlyCityTable: action.payload,
      };

    case pmaActionConstants.GET_MISSION_STATEMENT:
      return {
        ...state,
        missionStatement: action.payload,
      };

    case pmaActionConstants.GET_PMA_COVER_PHOTO_DETAILS:
      return {
        ...state,
        coverPageDetails: action.payload,
      };
    case pmaActionConstants.GET_YEAR_END_DETAILS:
      console.log("reducer getting YEAR END DETAILS", action.payload);
      return {
        ...state,
        yearEnd: action.payload,
      };

    case pmaActionConstants.GET_SAT:
      const {
        lgTestimonialsDTO,
        listAgentAdvantagesDTO,
        listAgentDesignationsDTO,
      } = action.payload;
      console.log("inside GET_SAT reducer", action.payload);
      return {
        ...state,
        testimonials: lgTestimonialsDTO ? lgTestimonialsDTO : "none",
        agentAdvantages: listAgentAdvantagesDTO
          ? listAgentAdvantagesDTO
          : "none",
        agentDesignations: listAgentDesignationsDTO
          ? listAgentDesignationsDTO
          : "none",
      };

    case pmaActionConstants.GET_SALE_PRICE_FOR_CITIES:
      return {
        ...state,
        salePriceForCities: action.payload ? action.payload : "none",
      };

    case pmaActionConstants.GET_AGENT_DESIGNATIONS:
      return {
        ...state,
        agentDesignations: action.payload,
      };
    case pmaActionConstants.CHANGE_PDF_PRINT_VERSION:
      console.log("inside reducer change pdf print version", action.payload);
      return {
        ...state,
        pdfPrintVersion: action.payload,
      };

    case pmaActionConstants.GET_AGENT_ADVANTAGES:
      return {
        ...state,
        agentAdvantages: action.payload,
      };

    case pmaActionConstants.GET_TESTIMONIALS:
      return {
        ...state,
        testimonials: action.payload,
      };

    case pmaActionConstants.GET_NEXT_PMA_PRINT_DATE:
      return {
        ...state,
        nextPMAPrintDate: action.payload,
      };

    case pmaActionConstants.NEW_MAILING_ADD_UPDATED_DATA:
      let currentNewMMailingEditedData = state.newMailingEditedData;
      if (action.payload.resetMode) {
        currentNewMMailingEditedData = [];
      } else if (action.payload.isPropertyAlreadyEdited) {
        //if current property is already updated update at old index
        currentNewMMailingEditedData = currentNewMMailingEditedData.map(
          (item) => {
            if (item.mailerProID === action.payload.mailerProID) {
              return action.payload;
            }
            return item;
          }
        );
      } else {
        currentNewMMailingEditedData.push(action.payload);
      }

      return {
        ...state,
        newMailingEditedData: [...currentNewMMailingEditedData],
      };

    case pmaActionConstants.SET_NEW_MAILPRO_INIT_DIALOG_MODE:
      return {
        ...state,
        newMailProInitDialogMode: action.payload,
      };

    case pmaActionConstants.DISABLE_NEW_MAILING_FOR_CURRENT_SESSION:
      return {
        ...state,
        disableNewMailingForCurrentSession: action.payload,
      };

    case pmaActionConstants.EXPLICIT_ENABLE_NEW_MAILPRO_INIT_SCREEN:
      return {
        ...state,
        explicitEnableNewMailProInit: action.payload,
      };

    case pmaActionConstants.NEW_MAILING_MAILPRO_DATA:
      return {
        ...state,
        newMailingMailProData: action.payload,
      };

    case pmaActionConstants.RESET_NEW_MAILPRO_INIT_DIALOG_DATA:
      return {
        ...state,
        explicitEnableNewMailProInit: false,
        disableNewMailingForCurrentSession: false,
        newMailProInitDialogMode: null,
        newMailingMailProData: [],
        newMailingEditedData: [],
      };

    default:
      return state;
  }
}
