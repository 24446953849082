import axios from 'common/interceptors';
import querystring from "querystring";
import { CommonAxiosAPIHandler, GetAuthorization } from "common/helpers";
import { GetOAuthTokenByKey } from "common/storage";
import moment from "moment";

const SAVE_MAILING_DETAILS = "/admin/savePMACalendars";
const GET_MAILING_SCHEDULE_DETAILS = "/agent/getPMACalendarForYearWithScheduleID";
const DELETE_CALENDAR = "/admin/deletePMACalendar";
const UPLOAD_PMA_PDF = "/agent/uploads/uploadPMATemplateFile";
const GET_ALL_TEMPLATES = "/admin/getAllPMATemplates";
const UPDATE_TEMPLATE_DATE = "/admin/updatePMATemplate";
const GET_SCHEDULES = "/admin/getPMASchedules";
const GET_ROLLING_SCHEDULES = "/agent/getCurrent12MonthsPMACalendar";
const COPY_SCHEDULE_TO_ANOTHER = "/admin/duplicatePMACalendar";
const GET_NEXT_PRINT_DATE = "/agent/getNextPMAPrintDate";

export const MailingMonthNumberMapping = { "Jan": "01", "Feb": "02", "Mar": "03", "Apr": "04", "May": "05", "June": "06", "July": "07", "Aug": "08", "Sept": "09", "Oct": "10", "Nov": "11", "Dec": "12" };
export const MailingMonthNameMapping = { "Jan": "January", "Feb": "February", "Mar": "March", "Apr": "April", "May": "May", "June": "June", "July": "July", "Aug": "August", "Sept": "September", "Oct": "October", "Nov": "November", "Dec": "December" };

export const isNonMailingSchedule = (version) => {
  return version === 'non_mailing';
}

export const getVersionFullName = (shortVersionName) => {
  return {
    speedometer: "Speedometer",
    table: "Table Layout",
    seller: "Seller Market",
    buyer: "Buyer Market",
    seller_buyer: "Seller Buyer Market",
    year_end: "Year End Review"
  }[shortVersionName];
}

export const convertToFormat = (dateString, format = 'date') => {
  return moment(dateString).format(format === 'date' ? 'ddd\xa0\xa0\xa0MMM D, YYYY' : format === 'time' ? '[@] hh A' : 'ddd\xa0\xa0\xa0MMM D, YYYY hh a')
}

export const getMonthYearForDisplay = (dateString) => {
  if (moment(dateString).format('YYYY') === moment(new Date()).format('YYYY')) {
    return moment(dateString).format('MMMM')
  } else {
    return moment(dateString).format('MMMM, YYYY')
  }
}

export const getYearForApprovalandNextPMAPrintDate = () => {
  //We cannot use moment(new Date()).format('YYYY'); every time there is a catch. So we need to use this function. If We are in December month and date has been passed after 15 December then we need to show next year.
  return moment(new Date()).format('MM') === '12' && moment(new Date()).format('DD') > '15' ? moment(new Date()).add(1, 'year').format('YYYY') : moment(new Date()).format('YYYY');
}

export const getPMAApprovalDateForAAgentFromSchedule = async (agentData, onSuccess = () => { }, onFailure = () => { }) => {
  const { pmascheduleID } = agentData || {};
  if (!pmascheduleID) {
    console.info('No schedule found for print.')
  }
  try {
    const fullYear = getYearForApprovalandNextPMAPrintDate();
    const data = await getScheduleByYear(fullYear, pmascheduleID);
    if (Array.isArray(data)) {
      const currentPrint = data.find(item => item.version !== "non_mailing" && item.status !== "delivered");
      onSuccess(
        currentPrint.cutOffDateTime || moment(new Date()).format('YYYY-MM-DD H:i:s'),
        MailingMonthNameMapping[currentPrint.mailingMonth]
      );
    }
  } catch (err) {
    onFailure(err);
  }
}

export const GetNextPMAPrintDate = () => {
  return CommonAxiosAPIHandler("GET", [
    `${GET_NEXT_PRINT_DATE}`
  ]);
};
/**
 * @name getPMAHoNextPrintDateForAAgentFromSchedule
 * @param {*} agentData 
 * @returns Next PMA print date from schedule. It uses printDateEnd to calculate next PMA print date.
 */
export const getPMAHoNextPrintDateForAAgentFromSchedule = async (agentData) => {
  const { pmascheduleID } = agentData || {};
  //we are not updating GetNextPMAPrintDate anymore so no point of using it.
  const staticPrintDate = moment(new Date()).format('YYYY-MM-DD');
  if (!pmascheduleID) {
    console.info('No schedule found for print.');
    return moment(staticPrintDate).format('YYYY-MM-DD');
  }

  const fullYear = getYearForApprovalandNextPMAPrintDate();
  return getScheduleByYear(fullYear, pmascheduleID).then(data => {
    if (Array.isArray(data)) {
      const currentPrint = data.find(item => item.version !== "non_mailing" && item.status !== "delivered");
      //recenty we moved to cutOffDateTime from printDateEnd. Current Date Time: 2021-09-29 12:00:00
      if (currentPrint.cutOffDateTime) {
        //now we need to return cut off date month end date. i.e. last day of month.
        console.log('Schedule Cut-off Date:', currentPrint.cutOffDateTime, 'Print End Date:', moment(currentPrint.cutOffDateTime).endOf('month').format('YYYY-MM-DD'));
        return moment(currentPrint.cutOffDateTime).endOf('month').format('YYYY-MM-DD');
      }
    } else {
      return moment(staticPrintDate).format('YYYY-MM-DD');
    }
  }).catch(err => {
    return moment(staticPrintDate).format('YYYY-MM-DD');
  })
}

export const saveSchedule = (scheduleData) => {
  console.log('scheduleData==', scheduleData);
  //return;
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .post(SAVE_MAILING_DETAILS, scheduleData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*"
            }
          }
        )
        .then(res => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch(err => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};


export const getScheduleByYear = (currentYear, pmaScheduleID, pmaCalendarID, mode = 'get') => {
  console.log()
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(mode === 'get' ?
          (GET_MAILING_SCHEDULE_DETAILS + '/?pmaScheduleID=' + pmaScheduleID + (currentYear ? ('&year=' + currentYear) : ''))
          : (mode === 'get_rolling' ? (GET_ROLLING_SCHEDULES + '/?pmaScheduleID=' + pmaScheduleID) : (DELETE_CALENDAR + '/?pmaCalendarID=' + pmaCalendarID)),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*"
            },
            data: {},
          }
        )
        .then(res => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch(err => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const deleteASchedule = (pmaCalendarID) => {
  // return new Promise(async (resolve, reject) => {
  //   resolve(true)
  // });
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get((DELETE_CALENDAR + '/?pmaCalendarID=' + pmaCalendarID),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*"
            },
            data: {},
          }
        )
        .then(res => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch(err => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
}

export const ScheduleUploadPMAPDFs = (
  version,
  fileData
) => {
  console.log("version", version);
  console.log("fileData", fileData);
  const formData = new FormData();
  formData.append("file", fileData);
  formData.append("version", version);
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .post(UPLOAD_PMA_PDF, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const saveTemplate = (templateData) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .post(UPDATE_TEMPLATE_DATE, templateData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*"
            }
          }
        )
        .then(res => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch(err => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const getAllTemplates = () => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(GET_ALL_TEMPLATES,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*"
            },
            data: {},
          }
        )
        .then(res => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch(err => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};


export const getAllSchedules = () => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(
          `${GET_SCHEDULES}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*"
            },
            data: {}
          }
        )
        .then(res => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch(err => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const copyScheduleToAnother = (fromScheduleID, toScheduleID) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(
          `${COPY_SCHEDULE_TO_ANOTHER}?fromPMAScheduleID=${fromScheduleID}&toPMAScheduleID=${toScheduleID}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*"
            },
            data: {}
          }
        )
        .then(res => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch(err => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};