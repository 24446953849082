import React, { Component } from "react";
import { Link } from "react-router-dom";

class QVFourTestimonial extends Component {
  componentDidMount = () => {
    if (this.props.printVersion) {
      require("./print.scss");
    } else {
      require("./editor.scss");
    }
  };
  render() {
    const {
      testimonials,
      agentData,
      printVersion,
      currentArea,
      tallerStyles,
    } = this.props;
    const { mlsAreaID } = currentArea;
    const { listgenUserID } = agentData;

    if (
      testimonials === "none" ||
      !testimonials ||
      !testimonials.testimonialContent
    ) {
      return (
        <div
          className={
            printVersion
              ? "print-april-2023-qv4-testimonial no-testimonial-available"
              : "editor-april-2023-qv4-testimonial no-testimonial-available"
          }
        >
          <Link to="/agent/clienttestimonials">
            <div className="qv4-testimonial-text-container">
              <div className="testimonial-content-row">
                <div className="add-testimonial-text">
                  Please add Testimonial to profile page. Click HERE!
                </div>
              </div>
            </div>
          </Link>
        </div>
      );
    } else {
      let {
        clientFirstName,
        clientLastName,
        testimonialContent,
      } = testimonials;
      // if (listgenUserID === "102843") {
      //   testimonialContent =
      //     "I have nothing but praise for Michael Galli and his excellent team. They are thorough, attentive, efficient and prompt—all things that you want when you are planning to buy or sell your house.";
      //   if (mlsAreaID === "SCL-209A") {
      //     clientFirstName = "Recent Cuesta Park Sellers";
      //     clientLastName = "— June 19";
      //   } else {
      //     clientFirstName = "Recent Mountain View Sellers";
      //     clientLastName = "— June 19";
      //   }
      // }

      const testimonialLength = testimonialContent.length;
      let testimonialTextStyles;
      let testimonialContainerStyles;
      if (testimonialContent && testimonialLength > 400) {
        testimonialTextStyles = "testimonial-medium-text";
      }
      // if (tallerStyles) {
      //   testimonialContainerStyles = "taller-testimonial";
      // }
      let clientNameLength = 0;
      if (clientFirstName && clientLastName) {
        clientNameLength =
          (clientFirstName.length || 0) + (clientLastName.length || 0);
      }

      return (
        <div
          className={`
            ${
              printVersion
                ? `print-april-2023-qv4-testimonial ${testimonialContainerStyles}`
                : `editor-april-2023-qv4-testimonial ${testimonialContainerStyles}`
            }
          `}
        >
          <div className="qv4-testimonial-text-container">
            <div className="qv4-testimonial-title">Earning Client Trust</div>

            <div className="testimonial-content-row">
              <div className="mq3-testimonial-quotation start-quote">"</div>
              {listgenUserID === "102843" ? (
                <div
                  className={`qv4-testimonial-text ${testimonialTextStyles}`}
                >
                  I have nothing but praise for Michael Galli and his excellent
                  team. They are{" "}
                  <strong> thorough, attentive, efficient and prompt</strong>
                  —all things that you want when you are planning to buy or sell
                  your house.
                </div>
              ) : (
                <div
                  className={`qv4-testimonial-text ${testimonialTextStyles}`}
                >
                  {testimonialContent}{" "}
                </div>
              )}

              <div className="mq3-testimonial-quotation end-quote">"</div>
            </div>
            {clientFirstName && (
              <div
                className={`qv4-testimonial-signoff ${
                  clientNameLength > 42 && "shorter-margin-signoff"
                } ${testimonialTextStyles}`}
              >
                ~ {clientFirstName} {clientLastName}
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}

export default QVFourTestimonial;
