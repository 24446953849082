import React from "react";
import PmaEditor from "./PMAEditor";
import axios from "common/interceptors";
// import axios from "axios";

class PrintPMA extends React.Component {
  state = {
    singleHOData: null,
    agentData: null,
    mlsAreas: null,
    marketActivity: null,
    MQCityDetails: null,
    currentArea: null,
    localSellingAgents: null,
    bubbleBoxes: [],
    // version: "bubble-compare",
    //version: "qv4",
    //version: "qv4",
    //version: "qv4",
    version: "year-end-2022",
    //version: "table-quadrants",
    coverPageDetails: null,
    quarterlyAreaTable: null,
    testimonials: null,
    agentDesignations: null,
    agentAdvantages: null,
    salePriceForCities: null,
    yearEnd: null,
    qArea: null,
    qCity: null,
    missionStatement: null,
    aprilData: null,
    flipBook: false,
  };

  async componentDidMount() {
    let mailProId = this.props.match.params.mailproid;
    let listgenUserID = this.props.match.params.listgenUserID;
    const { version } = this.state;
    let url = window.location.href;

    let SanMateoAgents = ["105882", "106087", "105896", "106144"];
    // if (SanMateoAgents.includes(listgenUserID)) {
    //   this.setState({
    //     version: "year-end-2022",
    //   });
    // }

    if (url.includes("test-print")) {
      listgenUserID = url[url.length - 2];
    }

    let date = new Date();
    let thisYear = date.getFullYear();
    const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
    const today = `${date.getDate()}`.padStart(2, 0);
    try {
      let mailPrint = await axios
        .get(
          `/admin/getMailPrintInfoDetails?listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
          {
            headers: {
              "content-type": "application/json",
            },
            data: {},
          }
        )
        .then((res) => {
          const singleHOData = res.data.mailerProDTO;
          const agentData = res.data.listgenUserDTO;

          this.setState({
            singleHOData,
            agentData,
          });
        })
        .catch((err) => console.log("error retrieving idDetails" + err));
    } catch (error) {
      console.log("error", error);
    }
    let navBar = await axios
      .get(`/agent/getNavbarInfo?listgenUserID=${listgenUserID}`, {
        headers: {
          "content-type": "application/json",
        },
        data: {},
      })
      .then((res) => {
        this.setState({
          mlsAreas: res.data,
        });
      })
      .catch((err) => {});
    const thisArea = this.state.singleHOData.areaID;
    let areaAndDate = `${thisArea}-${thisYear}-${thisMonth}-${today}`;
    let isDemo = false;
    if (
      thisArea === "DEMO-SCL-16A" ||
      thisArea === "DEMO-SCL-16B" ||
      thisArea === "DEMO-SCL-16D" ||
      thisArea === "DEMO-SCL-16E" ||
      thisArea === "DEMO-SCL-16N"
    ) {
      isDemo = true;
    }
    let diffVersionToUse;
    let usesDifferentVersion = false;
    if (version === "ye2" && !isDemo) {
      const { singleHOData } = this.state;
      const currentArea = this.state.mlsAreas.filter(
        (area) => area.mlsAreaID === thisArea
      )[0];
      const { subdivisionName } = singleHOData;
      const { mlsCityID, mlsAreaID } = currentArea;
      console.log("****dashboard donut");
      let dashBoardDonutApi = await axios
        .get(
          `/agent/getDashBoardDonutAndAvgSalePriceChart?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
            subdivisionName ? subdivisionName : "-"
          }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
          {
            headers: {
              "content-type": "application/json",
            },
            params: {},
            data: {},
          }
        )
        .then((res) => {
          const {
            navbarInfoList,
            pmaCoverPhotoDTO,
            mailerPrintInfoDTO,
            pmaQuarterlyAreaDTO,
            pmaQuarterlyCityDTO,
          } = res.data;
          const singleHOData = mailerPrintInfoDTO.mailerProDTO;
          const agentData = mailerPrintInfoDTO.listgenUserDTO;
          this.setState({
            singleHOData,
            agentData,
            currentArea,
            coverPageDetails: pmaCoverPhotoDTO,
            quarterlyAreaTable: pmaQuarterlyAreaDTO,

            aprilData: { pmaQuarterlyAreaDTO, pmaQuarterlyCityDTO },
            version: "ye2",
          });
        })
        .catch((err) => {
          console.log("error retrieving may api" + err);
        });
    } else if (
      (version === "mq2" && !isDemo) ||
      (version === "xbubble-compare" && !isDemo && !usesDifferentVersion) ||
      (isDemo && thisArea === "DEMO-SCL-16N") ||
      (usesDifferentVersion && diffVersionToUse === "bubble-compare")
    ) {
      const { version } = this.state;
      const mailProId = this.props.match.params.mailproid;
      const listgenUserID = this.props.match.params.listgenUserID;
      let date = new Date();
      let thisYear = date.getFullYear();
      const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
      const today = `${date.getDate()}`.padStart(2, 0);
      // let mailPrint = await axios
      //   .get(
      //     `/admin/getMailPrintInfoDetails?listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
      //     {
      //       headers: {
      //         "content-type": "application/json",
      //       },
      //       data: {},
      //     }
      //   )
      //   .then((res) => {
      //     const singleHOData = res.data.mailerProDTO;
      //     const agentData = res.data.listgenUserDTO;
      //     this.setState({
      //       singleHOData,
      //       agentData,
      //     });
      //   })
      //   .catch((err) => console.log("error retrieving idDetails" + err));

      const thisArea = this.state.singleHOData.areaID;
      // if (thisArea) {
      //   let navBar = await axios
      //     .get(`/agent/getNavbarInfo?listgenUserID=${listgenUserID}`, {
      //       headers: {
      //         "content-type": "application/json",
      //       },
      //       data: {},
      //     })
      //
      //     .then((res) => {
      //       this.setState({
      //         mlsAreas: res.data,
      //       });
      //     })
      //     .catch((err) => console.log("error retrieving navbarInfo" + err));
      // }

      const currentArea = this.state.mlsAreas.filter(
        (area) => area.mlsAreaID === thisArea
      )[0];

      const { mlsAreaID, mlsCityID } = currentArea;
      const { singleHOData } = this.state;
      const { subdivisionName } = singleHOData;
      if (currentArea) {
        let getPmaDetails = await axios
          .get(
            `/agent/getPMADetails?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
              subdivisionName ? subdivisionName : "-"
            }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,

            {
              headers: {
                "content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
              data: {},
            }
          )
          .then((res) => {
            const {
              mailerPrintInfoDTO,
              propertyPhotoDetailsDTO,
              quadrantInfoList,
            } = res.data;
            const singleHOData = mailerPrintInfoDTO.mailerProDTO;
            const agentData = mailerPrintInfoDTO.listgenUserDTO;
            this.setState({
              singleHOData,
              agentData,
              currentArea,
              coverPageDetails: res.data.pmaCoverPhotoDTO,
              quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
              quarterlyCityTable: res.data.pmaQuarterlyCityDTO,
              propertyPhotoDetailsDTO: propertyPhotoDetailsDTO,
              aprilData: {
                pmaQuarterlyAreaDTO: res.data.pmaQuarterlyAreaDTO,
                pmaQuarterlyCityDTO: res.data.pmaQuarterlyCityDTO,
              },
              quadrantDetails: quadrantInfoList,
              bubbleBoxes: res.data.marketValueInfoList,
              testimonials: res.data.lgTestmonialsDTO,
            });
          })
          .catch((err) =>
            console.log("error retrieving table bubble data" + err)
          );
      }
    } else if (version === "qv2" && !isDemo) {
      const mailProId = this.props.match.params.mailproid;
      // const listgenUserID = this.props.match.params.listgenUserID;
      let date = new Date();
      let thisYear = date.getFullYear();
      const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
      const today = `${date.getDate()}`.padStart(2, 0);

      try {
        const mailPrint = await axios.get(
          `/admin/getMailPrintInfoDetails?listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
          {
            headers: {
              "content-type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            data: {},
          }
        );

        this.setState({
          singleHOData: mailPrint.data.mailerProDTO,
          agentData: mailPrint.data.listgenUserDTO,
        });
      } catch (error) {
        console.log("error", error);
      }

      let navBar = await axios
        .get(`/agent/getNavbarInfo?listgenUserID=${listgenUserID}`, {
          headers: {
            "content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
        })

        .then((res) => {
          this.setState({
            mlsAreas: res.data,
          });
        })
        .catch((err) => {
          // console.log('error retrieving navbarInfo' + err)
        });

      const thisArea = this.state.singleHOData.areaID;
      let areaAndDate = `${thisArea}-${thisYear}-${thisMonth}-${today}`;

      const currentArea = this.state.mlsAreas.filter(
        (area) => area.mlsAreaID === thisArea
      )[0];
      const { mlsCityID, mlsAreaID } = currentArea;
      const cityAndDate = `${currentArea.mlsCityID}-${thisYear}-${thisMonth}-${today}`;
      const { singleHOData } = this.state;
      console.log("inside qv2");
      if (singleHOData) {
        const { subdivisionName } = singleHOData;
        // let qv2Details = await axios
        //   .get(
        //     `/agent/getQuarterlyPMADetails?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
        //       subdivisionName ? subdivisionName : "-"
        //     }`,
        //     {
        //       headers: {
        //         "content-type": "application/json",
        //       },
        //       params: {},
        //       data: {},
        //     }
        //   )
        //   .then((res) => {
        //     // console.log("data from area", res.data);
        //     this.setState({
        //       qArea: res.data.pmaQuarterlyAreaDTO,
        //       qCity: res.data.pmaQuarterlyCityDTO,
        //       currentArea,
        //     });
        //   })
        //   .catch((err) => {
        //     // console.log('error retrieving quarter area table' + err);
        //   });
        // let midquarterPMADetails = await axios
        //   .get(
        //     `/agent/getMidQuarterlyPMADetails?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
        //       subdivisionName ? subdivisionName : "-"
        //     }&listgenUserID=${listgenUserID}`,
        //     {
        //       headers: {
        //         "content-type": "application/json",
        //       },
        //       data: {},
        //     }
        //   )
        //   .then((res) => {
        //     this.setState({
        //       // testimonials: res.data.lgTestmonialsDTO,
        //       MQCityDetails: res.data.pmaMidQuarterlyAreaDTO,
        //     });
        //   })
        //   .catch((err) =>
        //     console.log("error retrieving MQCityDetails activity" + err)
        //   );
        // let dashBoardDonutApi = await axios
        //   .get(
        //     `/agent/getDashBoardDonutAndAvgSalePriceChart?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
        //       subdivisionName ? subdivisionName : "-"
        //     }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
        //     {
        //       headers: {
        //         "content-type": "application/json",
        //       },
        //       params: {},
        //       data: {},
        //     }
        //   )
        //   .then((res) => {
        //     console.log("6d", res.data);
        //     const {
        //       navbarInfoList,
        //       pmaCoverPhotoDTO,
        //       mailerPrintInfoDTO,
        //       listgenUserDTO,
        //       pmaQuarterlyAreaDTO,
        //       pmaQuarterlyCityDTO,
        //       missionStatementDTO,
        //     } = res.data;
        //
        //     const singleHOData = mailerPrintInfoDTO.mailerProDTO;
        //     const agentData = mailerPrintInfoDTO.listgenUserDTO;
        //     this.setState({
        // //version: "qv2",
        // singleHOData,
        // agentData,
        // currentArea,
        // coverPageDetails: pmaCoverPhotoDTO,
        // quarterlyAreaTable: pmaQuarterlyAreaDTO,
        // aprilData: { pmaQuarterlyAreaDTO, pmaQuarterlyCityDTO },
        //     });
        //   })
        //   .catch((err) => {
        //     console.log("error retrieving may api" + err);
        //   });
        // let aprilPMA = await axios
        //   .get(
        //     `/agent/getAprilPMADetails?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=-`,
        //     {
        //       headers: {
        //         "content-type": "application/json",
        //       },
        //       data: {},
        //     }
        //   )
        //   .then((res) => {
        //     console.log("data", res.data);
        //     this.setState({
        //       aprilData: res.data,
        //       // testimonials: res.data.lgTestmonialsDTO,
        //       // MQCityDetails: res.data.pmaMidQuarterlyAreaDTO
        //     });
        //   })
        //   .catch((err) =>
        //     console.log("error retrieving MQCityDetails activity" + err)
        //   );

        let getPmaDetails = await axios
          .get(
            `/agent/getPMADetails?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
              subdivisionName ? subdivisionName : "-"
            }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,

            {
              headers: {
                "content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
              data: {},
            }
          )
          .then((res) => {
            const { mailerPrintInfoDTO } = res.data;
            const singleHOData = mailerPrintInfoDTO.mailerProDTO;
            const agentData = mailerPrintInfoDTO.listgenUserDTO;
            console.log("first api", res.data);
            this.setState({
              //version: "qv2",
              singleHOData,
              agentData,
              currentArea,
              coverPageDetails: res.data.pmaCoverPhotoDTO,
              quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
              aprilData: {
                pmaQuarterlyAreaDTO: res.data.pmaQuarterlyAreaDTO,
                pmaQuarterlyCityDTO: res.data.pmaQuarterlyCityDTO,
              },
              bubbleBoxes: res.data.marketValueInfoList,
              //   // testimonials: res.data.lgTestmonialsDTO,
              //   // coverPageDetails: res.data.pmaCoverPhotoDTO,
              //   // quarterlyCityTable: res.data.pmaQuarterlyCityDTO,
              //   // quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
              //   bubbleBoxes: res.data.marketValueInfoList,
              //   // currentArea,
              //   // singleHOData,
              //   // agentData,
              //   // version: "bubble-compare",
            });
          })
          .catch((err) =>
            console.log("error retrieving table bubble data" + err)
          );

        let getRecentActivityPhotos = await axios
          .get(
            `/agent/getPropertyPhotoDetailsForListgenUserArea?listgenUserAreaID=165`,
            {
              headers: {
                "content-type": "application/json",
              },
              data: {},
            }
          )
          .then((res) => {
            console.log("****recent activity", res.data);
            this.setState({
              //version: "qv2",
              // photoDetails
            });
          })
          .catch((err) =>
            console.log("error retrieving table bubble data" + err)
          );
        // //property highlights
        let getPropertyHighlights = await axios
          .get(
            `/agent/getPropertyPhotoDetailsForListgenUserArea?listgenUserAreaID=100047-165`,
            {
              headers: {
                "content-type": "application/json",
              },
              data: {},
            }
          )
          .then((res) => {
            console.log("***OTHERproperty highlights", res.data);
            this.setState({
              // propertyPhotoDetailsDTO,
            });
          })
          .catch((err) =>
            console.log("error retrieving table bubble data" + err)
          );

        // let getBubbleBoxes = await axios
        //   .get(
        //     `/agent/getPriceRangeDashboardPriceRangeWithBubbleDataPMA?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
        //       subdivisionName ? subdivisionName : "-"
        //     }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
        //
        //     {
        //       headers: {
        //         "content-type": "application/json",
        //       },
        //       data: {},
        //     }
        //   )
        //   .then((res) => {
        //     const { mailerPrintInfoDTO } = res.data;
        //     const singleHOData = mailerPrintInfoDTO.mailerProDTO;
        //     const agentData = mailerPrintInfoDTO.listgenUserDTO;
        //
        //     this.setState({
        //       // testimonials: res.data.lgTestmonialsDTO,
        //       // coverPageDetails: res.data.pmaCoverPhotoDTO,
        //       // quarterlyCityTable: res.data.pmaQuarterlyCityDTO,
        //       // quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
        //       bubbleBoxes: res.data.marketValueInfoList,
        //       // currentArea,
        //       // singleHOData,
        //       // agentData,
        //       // version: "bubble-compare",
        //     });
        //   })
        //   .catch((err) =>
        //     console.log("error retrieving table bubble data" + err)
        //   );
      }
      // let areaDetails = await axios
      //   .get(`/agent/getPMAQuarterlyAreaTableDetails`, {
      //     headers: {
      //       "content-type": "application/json",
      //     },
      //     params: {
      //       pMAQuarterlyAreaIDRunDate: areaAndDate,
      //     },
      //     data: {},
      //   })
      //   .then((res) => {
      //     //console.log('data from area', res.data);
      //     this.setState({
      //       quarterlyAreaTable: res.data,
      //     });
      //   })
      //   .catch((err) => {
      //     // console.log('error retrieving quarter area table' + err);
      //   });

      // let salePriceForCities = await axios
      //   .get(
      //     `/agent/getAvgSalePriceForCitiesLast12Months?avgSalePricePeriod=Last12MonthsCityAverages-${2021}-${6}-${1}`,
      //     {
      //       headers: {
      //         "content-type": "application/json",
      //       },
      //       data: {},
      //     }
      //   )
      //
      //   .then((res) => {
      //     // console.log("salePriceForCities", res.data);
      //     this.setState({
      //       salePriceForCities: res.data,
      //     });
      //   })
      //   .catch((err) => {
      //     // console.log('error retrieving navbarInfo' + err)
      //   });
      // let cityDetails = await axios
      //   .get("/agent/getPMAQuarterlyCityTableDetails", {
      //     headers: {
      //       "content-type": "application/json",
      //     },
      //     params: {
      //       pMAQuarterlyCityIDRunDate: `${currentArea.mlsCityID}-2020-11-13`,
      //     },
      //     data: {},
      //   })
      //   .then((res) => {
      //     //  console.log('data from city', res.data);
      //     this.setState({
      //       quarterlyCityTable: res.data,
      //       // currentArea
      //     });
      //   })
      //   .catch((err) =>
      //     console.log("error retrieving quarter city details" + err)
      //   );

      let replacedArea = mlsCityID;

      // if (currentArea) {
      //   let marketActivity = await axios
      //     .get(
      //       `/agent/getPMAMidQuarterMarketActivity?areaIDPMAMidQuarterDailyRunDate=${currentArea.mlsAreaID}-2021-01-13`,
      //       {
      //         headers: {
      //           "content-type": "application/json",
      //         },
      //         data: {},
      //       }
      //     )
      //     .then((res) => {
      //       // console.log("^^^^^^ market activity", res.data);
      //       this.setState({
      //         marketActivity: res.data,
      //         version: "qv3",
      //       });
      //     })
      //     .catch((err) =>
      //       console.log("error retrieving market activity" + err)
      //     );
      // }
    } else if (version === "qv3") {
      const currentArea = this.state.mlsAreas.filter(
        (area) => area.mlsAreaID === thisArea
      )[0];

      const { mlsAreaID, mlsCityID } = currentArea;
      const { singleHOData } = this.state;
      const { subdivisionName } = singleHOData;
      // let coverPageDetails1 = await axios
      //   .get(
      //     `/agent/getTableDataWithBubbleDataPMA?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
      //       subdivisionName ? subdivisionName : "-"
      //     }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
      //     {
      //       headers: {
      //         "content-type": "application/json",
      //       },
      //       data: {},
      //     }
      //   )
      //   .then((res) => {
      //     const { mailerPrintInfoDTO } = res.data;
      //     const singleHOData = mailerPrintInfoDTO.mailerProDTO;
      //     const agentData = mailerPrintInfoDTO.listgenUserDTO;
      //     console.log("new api", res.data);
      //     this.setState({
      //       version: "qv3",
      //       testimonials: res.data.lgTestmonialsDTO,
      //       coverPageDetails: res.data.pmaCoverPhotoDTO,
      //       quarterlyCityTable: res.data.pmaQuarterlyCityDTO,
      //       quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
      //
      //       currentArea,
      //       singleHOData,
      //       agentData,
      //     });
      //   })
      //   .catch((err) =>
      //     console.log("error retrieving table bubble data" + err)
      //   );
      let getPmaDetails = await axios
        .get(
          `/agent/getPMADetails?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
            subdivisionName ? subdivisionName : "-"
          }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,

          {
            headers: {
              "content-type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            data: {},
          }
        )
        .then((res) => {
          const {
            mailerPrintInfoDTO,
            propertyPhotoDetailsDTO,
            pmaQuarterlyAreaDTO,
            pmaQuarterlyCityDTO,
            quadrantInfoList,
            pmaCoverPhotoDTO,
          } = res.data;
          const singleHOData = mailerPrintInfoDTO.mailerProDTO;
          let agentData = mailerPrintInfoDTO.listgenUserDTO;
          if (agentData.listgenUserID === "103") {
            agentData.brokerageName = "Compass";
            let updatedDemoQuadrants = [
              {
                footerLine1:
                  "Let’s set up a time to review the multitude of options you have in today’s market.",
                footerLine2: "(415)256-7890",
                listgenUserID: agentData.listgenUserID,
                mktValAreaID: currentArea.mlsAreaID,
                mktValAreaTitle: "IN TODAY’S SHIFTING MARKET...",
                mktValBox1Text:
                  "Chances are, your property has significantly appreciated over the past few years — giving you access to investment funds that might be used to acquire an income property.",
                mktValBox1SubText: "Contact me for investment opportunities.",
                mktValBox1Title: "Leverage Your Equity",
                mktValBox2Text:
                  "Despite shifting economic conditions, today's market favors home sellers due to a shortage of inventory. You haven't missed the window to capitalize on your property's current high resale value.",
                mktValBox2SubText: "Contact me for a current home valuation.",
                mktValBox2Title: "Sell in a High-Priced Market",
                mktValBox3Text:
                  "Research shows that the top value-adding improvements are modern kitchens, luxury bathrooms, and enhanced outdoor living spaces. Another value-add is an Accessory Dwelling Unit (ADU). Recent state-wide code changes have made ADU's easier than ever to build.",
                mktValBox3SubText: "Contact me for trusted local contractors",
                mktValBox3Title: "Increase Your Property's Value",
                mktValSubTitle: "It Pays to Consider Your Real Estate Options",
              },
            ];
            console.log(
              "res.data.marketValueInfoList",
              res.data.marketValueInfoList
            );
            res.data.marketValueInfoList = updatedDemoQuadrants;
          }
          console.log("**PMA", res.data);
          this.setState({
            quarterlyAreaTable: pmaQuarterlyAreaDTO,
            quarterlyCityTable: pmaQuarterlyCityDTO,
            MQCityDetails: pmaQuarterlyCityDTO,
            coverPageDetails: pmaCoverPhotoDTO,
            currentArea,
            testimonials: mailerPrintInfoDTO.lgTestimonialsDTO,
            quadrantDetails: quadrantInfoList,
            propertyPhotoDetailsDTO: propertyPhotoDetailsDTO,
            //version: "qv2",
            // singleHOData,
            agentData,
            // currentArea,
            // coverPageDetails: res.data.pmaCoverPhotoDTO,
            // quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
            aprilData: {
              pmaQuarterlyAreaDTO: res.data.pmaQuarterlyAreaDTO,
              pmaQuarterlyCityDTO: res.data.pmaQuarterlyCityDTO,
            },
            bubbleBoxes: res.data.marketValueInfoList,
            //   // testimonials: res.data.lgTestmonialsDTO,
            //   // coverPageDetails: res.data.pmaCoverPhotoDTO,
            //   // quarterlyCityTable: res.data.pmaQuarterlyCityDTO,
            //   // quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
            //   bubbleBoxes: res.data.marketValueInfoList,
            //   // currentArea,
            //   // singleHOData,
            //   // agentData,
            //   // version: "bubble-compare",
          });
        })
        .catch((err) =>
          console.log("error retrieving table bubble data" + err)
        );
    } else if (thisArea === "DEMO-SCL-16D" || version === "ye2") {
      const { singleHOData } = this.state;
      const currentArea = this.state.mlsAreas.filter(
        (area) => area.mlsAreaID === thisArea
      )[0];
      const { subdivisionName } = singleHOData;
      const { mlsCityID, mlsAreaID } = currentArea;
      let updatedID = "";
      // let decemberApi = await axios
      //   .get(
      //     `/agent/getAprilPMADetails?areaCityPeriod=${
      //       updatedID ? updatedID : mlsAreaID
      //     }-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
      //       subdivisionName ? subdivisionName : "-"
      //     }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
      //
      //     {
      //       headers: {
      //         "content-type": "application/json",
      //       },
      //       data: {},
      //     }
      //   )
      //   .then((res) => {
      //     const { pmaAprilCityDTO, pmaAprilRunAreaDTO } = res.data;
      //
      //     this.setState({
      //       aprilData: { pmaAprilCityDTO, pmaAprilRunAreaDTO },
      //     });
      //   })
      //   .catch((err) =>
      //     console.log("error retrieving MQCityDetails activity" + err)
      //   );
      console.log("*****dashboard donut");
      let dashBoardDonutApi = await axios
        .get(
          `/agent/getDashBoardDonutAndAvgSalePriceChart?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
            subdivisionName ? subdivisionName : "-"
          }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
          {
            headers: {
              "content-type": "application/json",
            },
            params: {},
            data: {},
          }
        )
        .then((res) => {
          console.log("6d", res.data);
          const {
            navbarInfoList,
            pmaCoverPhotoDTO,
            mailerPrintInfoDTO,
            listgenUserDTO,
            pmaQuarterlyAreaDTO,
            pmaQuarterlyCityDTO,
            missionStatementDTO,
          } = res.data;

          const singleHOData = mailerPrintInfoDTO.mailerProDTO;
          const agentData = mailerPrintInfoDTO.listgenUserDTO;
          this.setState({
            version: "qv2",
            singleHOData,
            agentData,
            currentArea,
            coverPageDetails: pmaCoverPhotoDTO,
            quarterlyAreaTable: pmaQuarterlyAreaDTO,
            aprilData: { pmaQuarterlyAreaDTO, pmaQuarterlyCityDTO },
          });
        })
        .catch((err) => {
          console.log("error retrieving may api" + err);
        });
    } else if (
      (version === "xyear-end-2022" && !isDemo) ||
      version === "table-quadrants" ||
      (isDemo && thisArea === "DEMO-SCL-16A") ||
      (usesDifferentVersion && diffVersionToUse === "year-end-2022")
    ) {
      let date = new Date();
      let thisYear = date.getFullYear();
      const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
      const today = `${date.getDate()}`.padStart(2, 0);
      // try {
      //   let mailPrint = await axios
      //     .get(
      //       `/admin/getMailPrintInfoDetails?listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
      //       {
      //         headers: {
      //           "content-type": "application/json",
      //         },
      //         data: {},
      //       }
      //     )
      //     .then((res) => {
      //       const singleHOData = res.data.mailerProDTO;
      //       const agentData = res.data.listgenUserDTO;
      //       this.setState({
      //         singleHOData,
      //         agentData,
      //       });
      //     })
      //     .catch((err) => console.log("error retrieving idDetails" + err));
      // } catch (error) {
      //   console.log("error", error);
      // }

      // let navBar = await axios
      //   .get(`/agent/getNavbarInfo?listgenUserID=${listgenUserID}`, {
      //     headers: {
      //       "content-type": "application/json",
      //     },
      //     data: {},
      //   })
      //   .then((res) => {
      //     this.setState({
      //       mlsAreas: res.data,
      //     });
      //   })
      //   .catch((err) => {});
      const thisArea = this.state.singleHOData.areaID;
      let areaAndDate = `${thisArea}-${thisYear}-${thisMonth}-${today}`;
      let currentArea = this.state.mlsAreas.filter(
        (area) => area.mlsAreaID === thisArea
      )[0];
      const { mlsCityID, mlsAreaID } = currentArea;
      const cityAndDate = `${currentArea.mlsCityID}-${thisYear}-${thisMonth}-${today}`;
      const { singleHOData } = this.state;
      if (singleHOData) {
        const { subdivisionName, mailerProID } = singleHOData;
        let updatedID;
        if (listgenUserID === "100037" && mlsAreaID === "160SalesDemo") {
          updatedID = "160";
        } else if (listgenUserID === "100037" && mlsAreaID === "143SalesDemo") {
          updatedID = "143";
        } else if (listgenUserID === "100037" && mlsAreaID === "126SalesDemo") {
          updatedID = "126";
        } else {
          updatedID = mlsAreaID;
        }
        // let getPropertyHighlights = await axios
        //   .get(
        //     `/agent/getPropertyPhotoDetailsForListgenUserArea?listgenUserAreaID=${listgenUserID}-${mlsAreaID}`,
        //     {
        //       headers: {
        //         "content-type": "application/json",
        //       },
        //       data: {},
        //     }
        //   )
        //   .then((res) => {
        //     console.log("***OTHERproperty highlights", res.data);
        //
        //     this.setState({
        //       propertyPhotoDetailsDTO: res.data,
        //       //version: "qv2",
        //     });
        //   })
        //   .catch((err) =>
        //     console.log("error retrieving table bubble data" + err)
        //   );

        // let dashboardAndTable = await axios
        //   .get(
        //     `/agent/getTableDataWithDonutAndAvgSalePriceChartPMADetails?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
        //       subdivisionName ? subdivisionName : "-"
        //     }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
        //
        //     {
        //       headers: {
        //         "content-type": "application/json",
        //       },
        //       params: {},
        //       data: {},
        //     }
        //   )
        //   .then((res) => {
        //     const {
        //       navbarInfoList,
        //       pmaCoverPhotoDTO,
        //       mailerPrintInfoDTO,
        //       pmaQuarterlyAreaDTO,
        //       pmaQuarterlyCityDTO,
        //       missionStatementDTO,
        //       pmaQuarterlyCombinedCityDTO,
        //       quadrantInfoList,
        //       propertyPhotoDetailsDTO,
        //     } = res.data;
        //     console.log("data from print", res.data);
        //     const singleHOData = mailerPrintInfoDTO.mailerProDTO;
        //     const agentData = mailerPrintInfoDTO.listgenUserDTO;
        //     this.setState({
        //       quarterlyAreaTable: pmaQuarterlyAreaDTO,
        //       quarterlyCityTable: pmaQuarterlyCityDTO,
        //       MQCityDetails: pmaQuarterlyCityDTO,
        //       coverPageDetails: pmaCoverPhotoDTO,
        //       currentArea,
        //       testimonials: mailerPrintInfoDTO.lgTestimonialsDTO,
        //       quadrantDetails: quadrantInfoList,
        //       //propertyPhotoDetailsDTO,
        //       // version: "table-quadrants",
        //     });
        //   })
        //   .catch((err) => {
        //     console.log("error retrieving may api" + err);
        //   });

        let getPmaDetails = await axios
          .get(
            `/agent/getPMADetails?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
              subdivisionName ? subdivisionName : "-"
            }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,

            {
              headers: {
                "content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
              data: {},
            }
          )
          .then((res) => {
            const {
              mailerPrintInfoDTO,
              propertyPhotoDetailsDTO,
              pmaQuarterlyAreaDTO,
              pmaQuarterlyCityDTO,
              quadrantInfoList,
              pmaCoverPhotoDTO,
              missionStatementDTO,
            } = res.data;
            const singleHOData = mailerPrintInfoDTO.mailerProDTO;
            const agentData = mailerPrintInfoDTO.listgenUserDTO;

            const { countyID } = pmaCoverPhotoDTO;
            const { pmaScheduleID } = agentData;
            this.setState({
              quarterlyAreaTable: pmaQuarterlyAreaDTO,
              quarterlyCityTable: pmaQuarterlyCityDTO,
              MQCityDetails: pmaQuarterlyCityDTO,
              coverPageDetails: pmaCoverPhotoDTO,
              currentArea,
              testimonials: mailerPrintInfoDTO.lgTestimonialsDTO,
              quadrantDetails: quadrantInfoList,
              propertyPhotoDetailsDTO: propertyPhotoDetailsDTO,
              bubbleBoxes: res.data.marketValueInfoList,
              missionStatement: res.data.missionStatementDTO,
              //version: "qv2",
              // singleHOData,
              // agentData,
              // currentArea,
              // coverPageDetails: res.data.pmaCoverPhotoDTO,
              // quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
              aprilData: {
                pmaQuarterlyAreaDTO: res.data.pmaQuarterlyAreaDTO,
                pmaQuarterlyCityDTO: res.data.pmaQuarterlyCityDTO,
              },
              //version: "year-end-2022",

              //   // testimonials: res.data.lgTestmonialsDTO,
              //   // coverPageDetails: res.data.pmaCoverPhotoDTO,
              //   // quarterlyCityTable: res.data.pmaQuarterlyCityDTO,
              //   // quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
              //   bubbleBoxes: res.data.marketValueInfoList,
              //   // currentArea,
              //   // singleHOData,
              //   // agentData,
              //   // version: "bubble-compare",
            });
          })
          .catch((err) =>
            console.log("error retrieving table bubble data" + err)
          );
        // let decemberApi = await axios
        //   .get(
        //     `/agent/getMarketTrendsPMA?areaCityPeriod=${
        //       updatedID ? updatedID : mlsAreaID
        //     }-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
        //       subdivisionName ? subdivisionName : "-"
        //     }&listgenUserID=${listgenUserID}&mailerProID=${mailerProID}`,
        //     {
        //       headers: {
        //         "content-type": "application/json",
        //       },
        //       data: {},
        //     }
        //   )
        //   .then((res) => {
        //     const {
        //       pmaCoverPhotoDTO,
        //       mailerPrintInfoDTO,
        //       propertyPhotoDetailsDTO,
        //       pmaQuarterlyCombinedCityDTO,
        //       pmaQuarterlyAreaDTO,
        //       pmaQuarterlyCityDTO,
        //       quadrantInfoList,
        //     } = res.data;
        //     const {
        //       lgTestimonialsDTO,
        //       mailerProDTO,
        //       listgenUserDTO,
        //     } = mailerPrintInfoDTO;
        //     //  console.log("year end data", res.data);
        //     this.setState({
        //       aprilData: { pmaQuarterlyCityDTO, pmaQuarterlyAreaDTO },
        //
        //       currentArea,
        //       coverPageDetails: pmaCoverPhotoDTO,
        //       pmaQuarterlyCombinedCityDTO,
        //       propertyPhotoDetailsDTO,
        //       singleHOData: mailerProDTO,
        //       agentData: listgenUserDTO,
        //       testimonials: lgTestimonialsDTO,
        //       quadrantDetails: quadrantInfoList,
        //       version: "year-end-2022",
        //     });
        //   })
        //   .catch((err) =>
        //     console.log("error retrieving MQCityDetails activity" + err)
        //   );

        // let decemberApi = await axios
        //   .get(
        //     `/agent/getAprilPMADetails?areaCityPeriod=${
        //       updatedID ? updatedID : mlsAreaID
        //     }-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
        //       subdivisionName ? subdivisionName : "-"
        //     }&listgenUserID=${listgenUserID}&mailerProID=${mailerProID}`,
        //
        //     {
        //       headers: {
        //         "content-type": "application/json",
        //       },
        //       data: {},
        //     }
        //   )
        //   .then((res) => {
        //     console.log("***new", res.data);
        //     const {
        //       pmaAprilCityDTO,
        //       pmaAprilRunAreaDTO,
        //       pmaCoverPhotoDTO,
        //       mailerPrintInfoDTO,
        //       propertyPhotoDetailsDTO,
        //       pmaQuarterlyCombinedCityDTO,
        //     } = res.data;
        //     const {
        //       lgTestimonialsDTO,
        //       mailerProDTO,
        //       listgenUserDTO,
        //     } = mailerPrintInfoDTO;
        //     //console.log("lgTestimonialsDTO", lgTestimonialsDTO);
        //     this.setState({
        //       aprilData: { pmaAprilCityDTO, pmaAprilRunAreaDTO },
        //       currentArea,
        //       coverPageDetails: pmaCoverPhotoDTO,
        //       pmaQuarterlyCombinedCityDTO,
        //       propertyPhotoDetailsDTO,
        //       singleHOData: mailerProDTO,
        //       agentData: listgenUserDTO,
        //       testimonials: lgTestimonialsDTO,
        //     });
        //   })
        //   .catch((err) =>
        //     console.log("error retrieving MQCityDetails activity" + err)
        //   );
      }

      let replacedArea;
      if (mlsAreaID === "752") {
        replacedArea = "5389";
      } else if (mlsAreaID === "1522155") {
        replacedArea = "5370";
      }
      //  else if (mlsAreaID === "157") {
      //   replacedArea = "345";
      // }
      else {
        replacedArea = mlsCityID;
      }
    } else if (
      version === "xtable-quadrants" &&
      listgenUserID !== "103"
      // ||
      // (isDemo && thisArea === "DEMO-SCL-16B")
    ) {
      let date = new Date();
      let thisYear = date.getFullYear();
      const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
      const today = `${date.getDate()}`.padStart(2, 0);
      try {
        //Keep
        let mailPrint = await axios
          .get(
            `/admin/getMailPrintInfoDetails?listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
            {
              headers: {
                "content-type": "application/json",
              },
              data: {},
            }
          )
          .then((res) => {
            const singleHOData = res.data.mailerProDTO;
            const agentData = res.data.listgenUserDTO;
            this.setState({
              singleHOData,
              agentData,
            });
          })
          .catch((err) => console.log("error retrieving idDetails" + err));
      } catch (error) {
        console.log("error", error);
      }
      //Keep
      let navBar = await axios
        .get(`/agent/getNavbarInfo?listgenUserID=${listgenUserID}`, {
          headers: {
            "content-type": "application/json",
          },
          data: {},
        })

        .then((res) => {
          this.setState({
            mlsAreas: res.data,
          });
        })
        .catch((err) => {
          console.log("error retrieving navbarInfo" + err);
        });

      const thisArea = this.state.singleHOData.areaID;
      let areaAndDate = `${thisArea}-${thisYear}-${thisMonth}-${today}`;

      const currentArea = this.state.mlsAreas.filter(
        (area) => area.mlsAreaID === thisArea
      )[0];
      const { mlsCityID, mlsAreaID } = currentArea;
      const cityAndDate = `${currentArea.mlsCityID}-${thisYear}-${thisMonth}-${today}`;
      const { singleHOData } = this.state;
      const { subdivisionName } = singleHOData;

      if (singleHOData) {
        const { subdivisionName, mailerProID } = singleHOData;

        if (currentArea) {
          let dashboardAndTable = await axios
            .get(
              `/agent/getTableDataWithDonutAndAvgSalePriceChartPMADetails?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
                subdivisionName ? subdivisionName : "-"
              }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,

              {
                headers: {
                  "content-type": "application/json",
                },
                params: {},
                data: {},
              }
            )
            .then((res) => {
              const {
                navbarInfoList,
                pmaCoverPhotoDTO,
                mailerPrintInfoDTO,
                pmaQuarterlyAreaDTO,
                pmaQuarterlyCityDTO,
                missionStatementDTO,
                pmaQuarterlyCombinedCityDTO,
                quadrantInfoList,
                propertyPhotoDetailsDTO,
              } = res.data;
              const singleHOData = mailerPrintInfoDTO.mailerProDTO;
              const agentData = mailerPrintInfoDTO.listgenUserDTO;
              this.setState({
                quarterlyAreaTable: pmaQuarterlyAreaDTO,
                quarterlyCityTable: pmaQuarterlyCityDTO,
                MQCityDetails: pmaQuarterlyCityDTO,
                coverPageDetails: pmaCoverPhotoDTO,
                currentArea,
                testimonials: mailerPrintInfoDTO.lgTestimonialsDTO,
                quadrantDetails: quadrantInfoList,
                propertyPhotoDetailsDTO,
                version: "table-quadrants",
              });
            })
            .catch((err) => {
              console.log("error retrieving may api" + err);
            });
        }
      }
    } else if (
      version === "qv4" ||
      version === "year-end-2022" ||
      version === "bubble-compare"
    ) {
      // const mailProId = this.props.match.params.mailproid;
      // const listgenUserID = this.props.match.params.listgenUserID;
      let date = new Date();
      let thisYear = date.getFullYear();
      const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
      const today = `${date.getDate()}`.padStart(2, 0);

      try {
        let mailPrint = await axios
          .get(
            `/admin/getMailPrintInfoDetails?listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
            {
              headers: {
                "content-type": "application/json",
              },
              data: {},
            }
          )
          .then((res) => {
            const singleHOData = res.data.mailerProDTO;
            const agentData = res.data.listgenUserDTO;

            this.setState({
              singleHOData,
              agentData,
            });
          })
          .catch((err) => console.log("error retrieving idDetails" + err));

        let navBar = await axios
          .get(`/agent/getNavbarInfo?listgenUserID=${listgenUserID}`, {
            headers: {
              "content-type": "application/json",
            },
            data: {},
          })

          .then((res) => {
            this.setState({
              mlsAreas: res.data,
            });
          })
          .catch((err) => console.log("error retrieving navbarInfo" + err));
        const thisArea = this.state.singleHOData.areaID;
        const currentArea = this.state.mlsAreas.filter(
          (area) => area.mlsAreaID === thisArea
        )[0];
        const { singleHOData } = this.state;
        const { mlsCityID, mlsAreaID } = currentArea;
        let subdivision =
          singleHOData &&
          singleHOData.subdivisionName &&
          singleHOData.subdivisionName.length > 1
            ? singleHOData.subdivisionName
            : "-";
        //console.log("singleHOData", this.state.singleHOData);
        const cityAndDate = `${currentArea.mlsCityID}-${thisYear}-${thisMonth}-${today}`;
        const { mailerProID, subdivisionName } = singleHOData;

        if (currentArea) {
          let getPmaDetails = await axios
            .get(
              `/agent/getPMADetails?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
                subdivisionName ? subdivisionName : "-"
              }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,

              {
                headers: {
                  "content-type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                },
                data: {},
              }
            )
            .then((res) => {
              let {
                mailerPrintInfoDTO,
                bpciList,
                quadrantInfoList,
                pmaQuarterlyCityDTO,
                propertyPhotoDetailsDTO,
              } = res.data;

              const singleHOData = mailerPrintInfoDTO.mailerProDTO;
              //console.log("singleHOData", singleHOData);
              const agentData = mailerPrintInfoDTO.listgenUserDTO;

              this.setState({
                singleHOData,
                agentData,
                altAgentData: agentData,
                currentArea,
                missionStatement: res.data.missionStatementDTO,
                quadrantDetails: quadrantInfoList,
                volunteerDetails: bpciList,
                coverPageDetails: res.data.pmaCoverPhotoDTO,
                quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
                quarterlyCityTable: res.data.pmaQuarterlyCityDTO,
                aprilData: {
                  pmaQuarterlyAreaDTO: res.data.pmaQuarterlyAreaDTO,
                  pmaQuarterlyCityDTO: res.data.pmaQuarterlyCityDTO,
                },
                propertyPhotoDetailsDTO: propertyPhotoDetailsDTO,
                bubbleBoxes: res.data.marketValueInfoList,
                testimonials: res.data.lgTestmonialsDTO,
                MQCityDetails: pmaQuarterlyCityDTO,
              });
            })
            .catch((err) =>
              console.log("error retrieving table bubble data" + err)
            );

          // let decemberApi = await axios
          //   .get(
          //     `/agent/getMarketTrendsPMA?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
          //       subdivisionName ? subdivisionName : "-"
          //     }&listgenUserID=${listgenUserID}&mailerProID=${mailerProID}`,
          //
          //     {
          //       headers: {
          //         "content-type": "application/json",
          //       },
          //       data: {},
          //     }
          //   )
          //   .then((res) => {
          //     const {
          //       pmaCoverPhotoDTO,
          //       mailerPrintInfoDTO,
          //       propertyPhotoDetailsDTO,
          //       pmaQuarterlyCombinedCityDTO,
          //       pmaQuarterlyAreaDTO,
          //       pmaQuarterlyCityDTO,
          //       quadrantInfoList,
          //     } = res.data;
          //     const {
          //       lgTestimonialsDTO,
          //       mailerProDTO,
          //       listgenUserDTO,
          //     } = mailerPrintInfoDTO;
          //     //  console.log("year end data", res.data);
          //     this.setState({
          //       // aprilData: { pmaQuarterlyCityDTO, pmaQuarterlyAreaDTO },
          //       //
          //       // currentArea,
          //       // coverPageDetails: pmaCoverPhotoDTO,
          //       // pmaQuarterlyCombinedCityDTO,
          //       // propertyPhotoDetailsDTO,
          //       // singleHOData: mailerProDTO,
          //       // agentData: listgenUserDTO,
          //       // testimonials: lgTestimonialsDTO,
          //       quadrantDetails: quadrantInfoList,
          //       //version: "year-end-2022",
          //     });
          //   })
          //   .catch((err) =>
          //     console.log("error retrieving MQCityDetails activity" + err)
          //   );
        }
      } catch (error) {
        console.log("error with qv4 print apis", error);
      }
    }
  }

  render() {
    const { propertyData } = this.props;
    const {
      singleHOData,
      agentData,
      altAgentData,
      mlsAreas,
      marketActivity,
      MQCityDetails,
      currentArea,
      version,
      quarterlyCityTable,
      quarterlyAreaTable,
      localSellingAgents,
      coverPageDetails,
      testimonials,
      agentDesignations,
      agentAdvantages,
      salePriceForCities,
      yearEnd,
      qArea,
      qCity,
      aprilData,
      flipBook,
      missionStatement,
      propertyPhotoDetailsDTO,
      pmaQuarterlyCombinedCityDTO,
      quadrantDetails,
      volunteerDetails,
      bubbleBoxes,
    } = this.state;

    //below, show the main data points that we need so we can make sure the page doesnt show until the data is there. You may need to edit ye2 for September's api by putting the variables we will be using in ye2
    if (
      (version === "qv3" &&
        currentArea &&
        singleHOData &&
        agentData &&
        coverPageDetails) ||
      (version === "qv2" &&
        currentArea &&
        singleHOData &&
        agentData &&
        coverPageDetails &&
        aprilData &&
        bubbleBoxes &&
        bubbleBoxes.length >= 1) ||
      (version === "year-end-2022" &&
        currentArea &&
        singleHOData &&
        agentData &&
        aprilData &&
        coverPageDetails &&
        bubbleBoxes &&
        Object.keys(bubbleBoxes).length >= 1) ||
      // quarterlyAreaTable &&
      // quarterlyAreaTable.monthsSupplyTable &&
      (version === "table-quadrants" &&
        currentArea &&
        singleHOData &&
        agentData &&
        quarterlyAreaTable &&
        quarterlyAreaTable.monthsSupplyTable &&
        coverPageDetails &&
        // MQCityDetails &&

        quadrantDetails &&
        quadrantDetails.length &&
        MQCityDetails &&
        MQCityDetails.avgHomesSoldLast12Months) ||
      (version === "qv4" &&
        currentArea &&
        singleHOData &&
        aprilData &&
        coverPageDetails &&
        quarterlyAreaTable &&
        agentData &&
        MQCityDetails &&
        volunteerDetails) ||
      (version === "mq2" && currentArea && singleHOData && agentData) ||
      (version === "bubble-compare" &&
        currentArea &&
        singleHOData &&
        agentData &&
        bubbleBoxes &&
        agentData.listgenUserID === "100560" &&
        propertyPhotoDetailsDTO &&
        quarterlyCityTable &&
        Object.keys(bubbleBoxes).length >= 1) ||
      (version === "bubble-compare" &&
        currentArea &&
        singleHOData &&
        agentData &&
        bubbleBoxes &&
        quarterlyCityTable &&
        Object.keys(bubbleBoxes).length >= 1)

      // &&
      //   currentArea &&
      //   singleHOData &&
      //   agentData &&
      //   quarterlyAreaTable &&
      //   quarterlyCityTable &&
      //   coverPageDetails &&
      //   quadrantDetails &&
      //   quadrantDetails.length
    ) {
      return (
        <div className="pma-creator">
          <main>
            <section>
              <PmaEditor
                propertyData={propertyData}
                printVersion={true}
                version={version}
                singleHOData={singleHOData}
                agentData={agentData}
                altAgentData={altAgentData}
                currentArea={currentArea}
                marketActivity={marketActivity}
                MQCityDetails={MQCityDetails}
                mlsAreas={mlsAreas}
                quarterlyAreaTable={quarterlyAreaTable}
                quarterlyCityTable={quarterlyCityTable}
                localSellingAgents={localSellingAgents}
                coverPageDetails={coverPageDetails}
                printTestimonials={testimonials}
                printAgentAdvantages={agentAdvantages}
                printAgentDesignations={agentDesignations}
                salePriceForCities={salePriceForCities}
                yearEnd={yearEnd}
                qArea={qArea}
                qCity={qCity}
                aprilData={aprilData}
                flipBook={flipBook}
                dontShowPma={false}
                missionStatement={missionStatement}
                propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
                pmaQuarterlyCombinedCityDTO={pmaQuarterlyCombinedCityDTO}
                quadrantDetails={quadrantDetails}
                volunteerDetails={volunteerDetails}
                bubbleBoxes={bubbleBoxes}
              />
            </section>
          </main>
        </div>
      );
    } else {
      return <div>Loading PMA...</div>;
    }
  }
}

export default PrintPMA;
