import React, { Component } from "react";
import YETwoSecondLeft from "./YETwoSecondLeft";
import YETwoSecondLeftV2 from "./YETwoSecondLeftV2";
import YETwoSecondRight from "./YETwoSecondRight";
import YETwoSecondRightV2 from "./YETwoSecondRightV2";
import QVFourSecondLeft from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourSecondLeft";
import BubbleCompareSecondLeft from "views/Pma/PmaEditor/BubbleCompare/BubbleCompareSecondPage/BubbleCompareSecondLeft";
import MQThreeSecondLeft from "views/Pma/PmaEditor/MidQuarter/MQThree/MQThreeSecondPage/MQThreeSecondLeft";
import TableQuadrantsSecondLeft from "views/Pma/PmaEditor/TableQuadrants/TableQuadrantsPageTwo/TableQuadrantsSecondLeft";

class YETwoSecondPage extends Component {
  render() {
    const {
      agentData,
      hoData,
      currentArea,
      printVersion,
      version,
      salePriceForCities,
      MQCityDetails,
      mlsAreas,
      qArea,
      qCity,
      aprilData,
      quarterlyAreaTable,
      quarterlyCityTable,
      pmaQuarterlyCombinedCityDTO,
      propertyPhotoDetailsDTO,
      oldPhotoDetails,
      photoTableData,
      isDemo,
      missionStatement,
    } = this.props;
    const { listgenUserID, pmaScheduleID } = agentData;

    return (
      <div
        className={
          "pma-page-container " +
          (printVersion
            ? "ye2-print-page-container"
            : "ye2-pma-editor-page-container")
        }
      >
        {listgenUserID !== "x100190" && listgenUserID !== "x101001" ? (
          <YETwoSecondLeft
            currentArea={currentArea}
            agentData={agentData}
            printVersion={printVersion}
            version={version}
            mlsAreas={mlsAreas}
            hoData={hoData}
            quarterlyAreaTable={quarterlyAreaTable}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            oldPhotoDetails={photoTableData}
            photoTableData={photoTableData}
            isDemo={isDemo}
            MQCityDetails={MQCityDetails}
            qArea={qArea}
            qCity={qCity}
            aprilData={aprilData}
            quarterlyCityTable={quarterlyCityTable}
          />
        ) : (
          <BubbleCompareSecondLeft
            currentArea={currentArea}
            agentData={agentData}
            printVersion={printVersion}
            version={version}
            mlsAreas={mlsAreas}
            hoData={hoData}
            quarterlyAreaTable={quarterlyAreaTable}
            quarterlyCityTable={quarterlyCityTable}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            oldPhotoDetails={photoTableData}
            photoTableData={photoTableData}
            isDemo={isDemo}
            MQCityDetails={MQCityDetails}
          />
        )}

        {/*<QVFourSecondLeft
          currentArea={currentArea}
          hoData={hoData}
          agentData={agentData}
          printVersion={printVersion}
          quarterlyAreaTable={quarterlyAreaTable}
          quarterlyCityTable={quarterlyCityTable}
        />*/}
        {listgenUserID === "x" ? (
          <YETwoSecondRightV2
            currentArea={currentArea}
            agentData={agentData}
            printVersion={printVersion}
            version={version}
            mlsAreas={mlsAreas}
            hoData={hoData}
            quarterlyAreaTable={quarterlyAreaTable}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            oldPhotoDetails={photoTableData}
            photoTableData={photoTableData}
            isDemo={isDemo}
            MQCityDetails={MQCityDetails}
            missionStatement={missionStatement}
          />
        ) : (
          <YETwoSecondRight
            currentArea={currentArea}
            agentData={agentData}
            printVersion={printVersion}
            version={version}
            mlsAreas={mlsAreas}
            hoData={hoData}
            quarterlyAreaTable={quarterlyAreaTable}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            oldPhotoDetails={photoTableData}
            photoTableData={photoTableData}
            isDemo={isDemo}
            MQCityDetails={MQCityDetails}
          />
        )}
      </div>
    );
  }
}

export default YETwoSecondPage;
