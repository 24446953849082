import React, { Fragment, Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import {
  Button,
  Typography,
  Divider,
  Container,
  TextField,
  CircularProgress,
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { red } from "@material-ui/core/colors";
import classNames from "classnames";
import styles from "../../styles";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core";
import Filter from "bad-words";
import { connect } from "react-redux";
import { pmaActions } from "_store/_actions";
//import { EditableContentComponent } from 'components';
import { GlobalNotePSReviewDialogComponent } from "components";
import moment from "moment";
import { GetaLGConstantData } from "services/others";
import { IsValidSessionSwitch } from "common/storage";
import GlobalNoteAreaChooserDialog from "./GlobalNoteAreaChooserDialog";
import MailProNoteWarningDialog from "../../MailProNoteWarningDialog";
import { RichTextEditorV2Component } from "components";
import {
  agentBarSwitchClosureV2,
  MailProBlockedEditingDisabledBar,
  MailProCloseButton,
} from "../../MailProHTMLShortMethods";
import { renderLogicUtil } from "views/Pma/RenderLogicUtil";
import { propertyUtil } from "views/Pma/PropertyUtil";
import { noteUtils } from "../Common/NoteUtils";
import {
  MaxAllowedLinesInNote,
  perLineComfortableNoteCharCount,
} from "../Common/NotesConfig";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class GlobalNoteDialogV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      globalNote: "",
      plainNote: "",
      globalPS: "",
      beforeEditNote: "",
      beforeEditPS: "",
      submitted: false,
      error: "",
      isLoading: false,
      isBlocked: false,
      isGlobalNotePSUpdated: false,
      currentEditMode: "",
      startNotePSAreaMode: false,
      isASessionSwitch: false,
      showNotePSReviewDialog: false,
      textForGrammerCheck: "",
      showGrammerSection: false,
      grammerChecked: true,
      grammerCheckLoading: false,
      lastPrintDate: null,
      showGlobalNote: true,
      showPSWarningDialog: false,
      greetTextTop: "",
      signOffTextBottom: "",
      rteResetMode: false,
    };
    //define global note lengths
    Object.assign(this, noteUtils.geGlobalNoteSizes());
    /**bind methods */
    this.updateGlobalNote = this.updateGlobalNote.bind(this);
    this.updateGlobalPlainNote = this.updateGlobalPlainNote.bind(this);
    this.closeGlobalPSReviewDialog = this.closeGlobalPSReviewDialog.bind(this);
    this.showGlobalNotePSReviewDialog = this.showGlobalNotePSReviewDialog.bind(
      this
    );
    this.onSubmit = this.onSubmit.bind(this);
    this.beforeOnSubmit = this.beforeOnSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    //this.setGrammerLoading = this.setGrammerLoading.bind(this);
    /**new methods */
    //this.submitErrors = this.submitErrors.bind(this);
    this.updateNotePS = this.updateNotePS.bind(this);
    this.handleRTEDisableMode = this.handleRTEDisableMode.bind(this);
  }

  handleClickOpen = () => {
    this.setState({ open: true });
    /**check for block status */
    let {
      isMailProLocked,
      agentData,
      changeDetails,
      propertyDetails,
      property,
    } = this.props;
    const { listgenUserFirstName, teamMembers } = agentData;
    //check for valid session switch
    let isValidSwitchSession = IsValidSessionSwitch();
    //check for current logic
    let globalNoteCreationDate = this.getGlobalNoteDateByAreaLogic();
    let globalPSCreationDate = this.getGlobalPSDateByAreaLogic();
    let globalNoteShowHideLogic = renderLogicUtil.checkGlobalNoteShowHideLogicV2(
      globalNoteCreationDate,
      changeDetails,
      propertyDetails,
      globalPSCreationDate
    );

    //{showGlobalNote};
    //console.log('globalNoteShowHideLogic logged===', globalNoteShowHideLogic);
    /**check for default global PS */
    let addedGlobalNote = this.getGlobalNoteByAreaLogic();
    let addedGlobalPS = this.getGlobalPSByAreaLogic();
    //get the note nicknames
    let hoName = renderLogicUtil.getNoteGreetingTextV4(
      property.changeDetails,
      "&"
    );
    const { agentSignoffForGlobalNote } = propertyUtil;
    //let greetingTop = "Hi " + hoName + ",";
    let switchTeamOrder = false;
    // if (agentData.listgenUserID === "104504" && agentData.isMemberOfTeam) {
    //   switchTeamOrder = true;
    // }
    let agentSignoff = agentSignoffForGlobalNote(agentData, null, false);

    //let greetingBottom = `Warm Regards, ${agentSignoff}`;
    //last print date
    this.setState({
      globalNote: addedGlobalNote,
      plainNote: addedGlobalNote,
      globalPS: addedGlobalPS,
      isBlocked: isMailProLocked,
      isGlobalNotePSUpdated: false,
      isASessionSwitch: isValidSwitchSession,
      lastPrintDate: propertyUtil.getLastPrintDate(),
      showGlobalNote: globalNoteShowHideLogic.showGlobalNote,
      greetTextTop: hoName,
      signOffTextBottom: agentSignoff,
      beforeEditNote: addedGlobalNote,
      beforeEditPS: addedGlobalPS,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      globalNote: "",
      globalPS: "",
      submitted: false,
      error: "",
      isASessionSwitch: false,
      isGlobalNotePSUpdated: false,
      textForGrammerCheck: "",
      showGrammerSection: false,
      grammerChecked: false,
      grammerCheckLoading: false,
      startNotePSAreaMode: false,
      showPSWarningDialog: false,
      currentEditMode: "",
      disableRTEResetMode: false,
    });
  };

  handleRTEDisableMode = (value) => {
    this.setState({
      rteResetMode: value,
    });
  };

  updateGlobalNote = (htmlData, plainData) => {
    const lineBreaks = noteUtils.countLineBreaksInHtmlNote(
      htmlData,
      perLineComfortableNoteCharCount
    );
    if (lineBreaks > MaxAllowedLinesInNote) {
      this.setState({
        error: `A maximum of ${MaxAllowedLinesInNote} lines can be added for GLOBAL NOTE.`,
      });
      return;
    }
    this.setState({
      globalNote: htmlData,
      plainNote: plainData,
      //showGrammerSection: false,
      //grammerChecked: false,
      //grammerCheckLoading: false,
      submitted: false,
      error: "",
    });
  };

  updateGlobalPlainNote = (plainData) => {
    this.setState({
      plainNote: plainData,
      //showGrammerSection: false,
      //grammerChecked: false,
      //grammerCheckLoading: false,
      error: "",
    });
  };

  updateNotePS = (notePS) => {
    this.setState({
      globalPS: notePS,
      submitted: false,
      error: "",
    });
  };

  // resetGPS = e => {
  //   this.onSubmit(e, true);
  // };

  // enableNotePSEditMode = e => {
  //   this.setState({
  //     isGlobalNotePSUpdated: true
  //   });
  // };

  setCurrentEditNoteMode = (mode) => {
    this.setState({
      currentEditMode: mode,
      error: "",
      submitted: false,
    });
  };

  // cancelPSEditMode = e => {
  //   this.setState({
  //     isGlobalNotePSUpdated: false,
  //     showGrammerSection: false,
  //     grammerChecked: false,
  //     grammerCheckLoading: false
  //   });
  // };

  closeNotePSAreaModeDialog = () => {
    this.setState({
      startNotePSAreaMode: false,
    });
  };

  openWarningDialog = () => {
    //one more thing bypass the warning dialog if thers no global PS added. Dialog is only meant for PS so.
    const { globalPS, plainNote, currentEditMode } = this.state;
    //if theres no global ps and global note submit directly by bypassing warning/review dialog
    if (currentEditMode === "globalPS" && globalPS) {
      //globalPS
      this.setState({
        showPSWarningDialog: true,
      });
    } else {
      this.showGlobalNotePSReviewDialog();
    }
  };

  // closeWarningDialog = () => {
  //   this.setState({
  //     showPSWarningDialog: false
  //   });
  // };

  /**
   * Grammer Check Method
   */
  // setGrammerLoading = (isLoading) => {
  //   this.setState({
  //     grammerCheckLoading: isLoading,
  //   })
  // };

  // applyGrammerCheck = e => {
  //   const { plainNote } = this.state;
  //   this.setState({
  //     showGrammerSection: false,
  //     grammerCheckLoading: true,
  //   }, () => {
  //     this.setState({
  //       showGrammerSection: true,
  //       grammerChecked: true,
  //       textForGrammerCheck: plainNote
  //     })
  //   });
  // };

  showGlobalNotePSReviewDialog = (e) => {
    this.setState({
      showPSWarningDialog: false,
      showNotePSReviewDialog: true,
    });
  };

  closeGlobalPSReviewDialog = (e) => {
    this.setState({
      showNotePSReviewDialog: false,
    });
  };

  restoreSystemGlobalNote = (e) => {
    //in this method we need to set current edit mode becuase as of now we only reset flobal note
    if (this.state.isBlocked) {
      this.setState({
        error: "Mailpro has been locked for this mailing.",
        currentEditMode: "globalNote",
      });
    } else {
      let { agentData, mailingVersion } = this.props;
      let agentisMemberOfTeam =
        (agentData && agentData.isMemberOfTeam) || false;

      let constantNameIndividual = "globalNoteCurrentIndividual";
      let constantNameTeam = "globalNoteCurrentTeam";
      //ps values
      let constantPSIndividual = "globalPSCurrentIndividual";
      let constantPSTeam = "globalPSCurrentTeam";
      let finalKeyNote = agentisMemberOfTeam
        ? constantNameTeam
        : constantNameIndividual;
      let finalKeyPS = agentisMemberOfTeam
        ? constantPSTeam
        : constantPSIndividual;
      //now get the data and after getting save back in onsubmit.
      this.setState({ isLoading: true });
      GetaLGConstantData(finalKeyNote)
        .then((notedata) => {
          //set the state
          //Ps is not required for reset purpose if require in future then replicate this function based on current edit mode
          //GetaLGConstantData(finalKeyPS).then(psData => {
          //check whether we do have defualt note yes or no. remove the html data first
          let dummyGlobalNote = notedata
            .replace(/<[^>]+>/g, "")
            .replace(/(&nbsp;|<br>|<br \/>)/g, "");
          if (dummyGlobalNote === "" || dummyGlobalNote === "<p></p>") {
            this.setState({
              error: "No default global note found.",
              isLoading: false,
              currentEditMode: "globalNote",
            });
          } else {
            //set the state and submit global note
            this.setState(
              {
                globalNote: notedata,
                beforeEditNote: notedata, //add to before note edit also
                //globalPS: psData,
                //isGlobalNotePSUpdated: true,
                currentEditMode: "globalNote",
                rteResetMode: true,
              },
              () => {
                this.forceUpdate();
                this.onSubmit(e);
              }
            );
          }

          //})
        })
        .catch((err) => {
          this.setState({
            error: "Unable to fetch default note.",
            isLoading: false,
            currentEditMode: "globalNote",
          });
        });
    }
  };

  beforeOnSubmit(e) {
    //only enable this mode if area count is more than 1
    const { mlsAreas } = this.props;
    if (mlsAreas.length > 1) {
      this.setState({
        startNotePSAreaMode: true,
      });
    } else {
      this.onSubmit(e);
    }
  }

  formatDate = (d) => {
    return moment(new Date(d)).format("M/D/YYYY");
  };

  async onSubmit(e, areaMode = false, areaNoteIds = [], areaPSIds = []) {
    const {
      error,
      plainNote,
      isBlocked,
      isASessionSwitch,
      currentEditMode,
    } = this.state;
    let { globalNote, globalPS } = this.state;
    const { mlsAreas, currentArea, mailingVersion } = this.props;
    //check global note and ps for refactoring
    globalNote = propertyUtil.refactorNotePSWithRemovalBeforeSubmit(globalNote);
    globalPS = propertyUtil.refactorNotePSWithRemovalBeforeSubmit(globalPS);

    if (
      ((globalNote && globalNote.length >= 1) ||
        (globalPS && globalPS.length > 1)) &&
      error.length === 0
    ) {
      this.setState(
        {
          //isLoading: true,
        },
        async () => {
          setTimeout(async () => {
            let mlsAreasCopy = Object.assign([], mlsAreas); //[...mlsAreas];//mlsAreas.slice();
            let updatedMLSAreasCopy = [];
            updatedMLSAreasCopy = mlsAreasCopy.map((area, key) => {
              //check for area
              let anyOneUpdated = false;
              if (
                currentEditMode === "globalNote" &&
                ((areaMode && areaNoteIds.indexOf(area.mlsAreaID) !== -1) ||
                  area.mlsAreaID === currentArea.mlsAreaID)
              ) {
                let isNoteUpdated = this.getNotePSUpdationStatus(
                  "note",
                  globalNote,
                  area
                );
                anyOneUpdated = true;
                area["globalNoteCurrent"] = globalNote;
                area["isGlobalNoteUpdated"] = isASessionSwitch
                  ? false
                  : isNoteUpdated; //in session updated by agent would be false
                //Change this logic if require or if we want to track every edit as of now only first edit we need
                area["globalNoteCurrentDateTime"] = moment().format(
                  "YYYY-MM-DD H:m:s"
                );
                //!isGlobalNoteAddedInCurrentMailing ? moment().format('YYYY-MM-DD H:m:s') : (area['globalNoteCurrentDateTime'] ? area['globalNoteCurrentDateTime'] : moment().format('YYYY-MM-DD H:m:s'));
              }
              if (
                currentEditMode === "globalPS" &&
                ((areaMode && areaPSIds.indexOf(area.mlsAreaID) !== -1) ||
                  area.mlsAreaID === currentArea.mlsAreaID)
              ) {
                let isPSUpdated = this.getNotePSUpdationStatus(
                  "ps",
                  globalPS,
                  currentArea
                );
                //console.log('isPSUpdated value logged====', isPSUpdated);
                anyOneUpdated = true;
                area["globalPSCurrent"] = globalPS;
                area["isGlobalPSUpdated"] = isASessionSwitch
                  ? false
                  : isPSUpdated; //in session updated by agent would be false
                //Change this logic if require or if we want to track every edit as of now only first edit we need
                area["globalPSCurrentDateTime"] = moment().format(
                  "YYYY-MM-DD H:m:s"
                );
                //!isGlobalNoteAddedInCurrentMailing ? moment().format('YYYY-MM-DD H:m:s') : (area['globalPSCurrentDateTime'] ? area['globalPSCurrentDateTime'] : moment().format('YYYY-MM-DD H:m:s'));
              }
              //assign the numOfPMASending
              if (area.numOfPMASending === null) {
                area.numOfPMASending = 0;
              }
              // console.log('AREA going to return==', area)
              return area;
            });
            await this.props.updateNavbar(updatedMLSAreasCopy);
            //now update the current area data also. in mappendNav its workign without this because we are calling homeowner update property so that is updating the redux store.
            this.props.updateCurrentAreaData(
              updatedMLSAreasCopy.find(
                (area) => area.mlsAreaID === currentArea.mlsAreaID
              )
            );
            //update before Edit keys also accordingly whatever we are submitting
            let updateKey =
              currentEditMode === "globalNote"
                ? "beforeEditNote"
                : "beforeEditPS";
            this.setState(
              {
                submitted: true,
                isLoading: false,
                error: "",
                [updateKey]:
                  currentEditMode === "globalNote" ? globalNote : globalPS,
              },
              () => {
                setTimeout(() => {
                  this.handleClose();
                }, 1000);
              }
            );
          }, 300);
        }
      );
    } else {
      this.setState({
        error: "Error submitting, please try again.",
      });
    }
  }

  filterNote = (globalNote) => {
    const filter = new Filter();
    return filter.clean(globalNote);
  };

  getFormattedGlobalNote = (globalNote, globalPS, classes) => {
    /**
     * we need html mode because we are using same method into onsubmit method also.
     */
    globalNote =
      globalNote && globalNote !== "-"
        ? globalNote
            .replace(/<[^>]+>/g, "")
            .replace(/(&nbsp;|<br>|<br \/>)/g, "")
        : "";
    globalPS =
      globalPS && globalPS !== "-"
        ? globalPS.replace(/<[^>]+>/g, "").replace(/(&nbsp;|<br>|<br \/>)/g, "")
        : "";
    const getFormattedText = (text, word, characters) => {
      let returnData = "";
      try {
        if (!text) {
          returnData = "";
        } else if (text && text.length <= characters) {
          returnData = text;
        } else if (text && text.length > characters) {
          let cutGlobal = `${propertyUtil.iTextTruncater(
            text,
            word,
            characters
          )}...`;
          returnData = cutGlobal;
        } else {
          returnData = "";
        }
      } catch (err) {
        returnData = "";
      }
      return returnData;
    };
    /**Now do formatting accordingly */
    // if (!htmlMode) {
    //   // send directly data
    //   return returnData ? returnData : "";
    // } else {
    //check the updation key
    let currentArea = this.getCurrentArea();
    let isNoteUpdatedByAgent = currentArea.isGlobalNoteUpdated;
    let isPSUpdatedByAgent = currentArea.isGlobalPSUpdated;
    // convert into html mode
    return (
      <Fragment>
        <Fragment>
          <p className="dynamic-update-gps is-current-ps font-weight-bold breakAll">
            {getFormattedText(globalNote, 10, 30)}
          </p>
          <br />
          {globalPS ? (
            <p className="dynamic-update-gps is-current-note-ps breakAll">
              P.S. {getFormattedText(globalPS, 8, 20)}
            </p>
          ) : (
            ""
          )}
          <p className={classes.redColor} style={{ marginTop: "8px" }}>
            {isNoteUpdatedByAgent && isPSUpdatedByAgent
              ? "You have edited the SYSTEM GLOBAL NOTE and added a GLOBAL P.S."
              : isNoteUpdatedByAgent
              ? "You have EDITED the GLOBAL NOTE for this mailing"
              : isPSUpdatedByAgent
              ? "You have added a GLOBAL P.S. for all homeowners "
              : ""}
          </p>
        </Fragment>
      </Fragment>
    );
  };

  getGlobalNoteDateByAreaLogic = () => {
    //version instead of mailingVersion
    //let { currentArea, mlsAreas, mailingVersion } = this.props;
    /**check for latest current area data */
    let currentArea = this.getCurrentArea();
    //return currentArea && currentArea.globalPS; //old way
    //send data absed on current verion keys as mailpro only owrking with the current version.
    return currentArea &&
      currentArea.globalNoteCurrentDateTime &&
      currentArea.globalNoteCurrentDateTime !== "-"
      ? currentArea.globalNoteCurrentDateTime
      : null;
  };

  getGlobalPSDateByAreaLogic = () => {
    //version instead of mailingVersion
    //let { currentArea, mlsAreas, mailingVersion } = this.props;
    /**check for latest current area data */
    let currentArea = this.getCurrentArea();
    //return currentArea && currentArea.globalPS; //old way
    //send data absed on current verion keys as mailpro only owrking with the current version.
    return currentArea &&
      currentArea.globalPSCurrentDateTime &&
      currentArea.globalPSCurrentDateTime !== "-"
      ? currentArea.globalPSCurrentDateTime
      : null;
  };

  getGlobalPSByAreaLogic = () => {
    //version instead of mailingVersion
    // let { currentArea, mlsAreas, mailingVersion } = this.props;
    /**check for latest current area data */
    let currentArea = this.getCurrentArea();
    //return currentArea && currentArea.globalPS; //old way
    //send data absed on current verion keys as mailpro only owrking with the current version.
    //&& currentArea.globalPSCurrent !== '<p></p>'
    return currentArea &&
      currentArea.globalPSCurrent &&
      currentArea.globalPSCurrent !== "<p></p>" &&
      currentArea.globalPSCurrent !== "-"
      ? currentArea.globalPSCurrent
      : null;
  };

  getGlobalNoteByAreaLogic = () => {
    //version instead of mailingVersion
    //let { currentArea, mlsAreas, mailingVersion } = this.props;
    /**check for latest current area data */
    let currentArea = this.getCurrentArea();
    //return currentArea && currentArea.globalPS; //old way
    //send data absed on current verion keys as mailpro only owrking with the current version.
    //we only have to ssend from current version keys
    return currentArea &&
      currentArea.globalNoteCurrent &&
      currentArea.globalNoteCurrent !== null &&
      currentArea.globalNoteCurrent !== "<p></p>" &&
      currentArea.globalNoteCurrent !== "-"
      ? currentArea.globalNoteCurrent
      : "";
  };

  getNotePSUpdationStatus = (type, dataToCompare, currentArea) => {
    if (type === "note") {
      //if nothing changed in global note means no update by agent so send old value. if changed then only send a strue
      return currentArea.globalNoteCurrent === dataToCompare
        ? currentArea.isGlobalNoteUpdated
        : true;
    } else if (type === "ps") {
      //one more logic especially for PS if there no ps send as false
      return !currentArea.globalPSCurrent ||
        currentArea.globalPSCurrent.length === 0
        ? false
        : currentArea.globalPSCurrent === dataToCompare
        ? currentArea.isGlobalPSUpdated
        : true;
    }
  };

  getCurrentArea = () => {
    let { currentArea, mlsAreas } = this.props;
    return propertyUtil.getCurrentArea(mlsAreas, currentArea);
  };

  getCurrentAreaName = () => {
    return this.getCurrentArea().mlsNeighborhood;
    //return currentArea.mlsNeighborhood;
  };

  submitErrors = (event, editMode) => {
    const {
      plainNote,
      grammerChecked,
      globalPS,
      isBlocked,
      globalNote,
      beforeEditNote,
      beforeEditPS,
    } = this.state;
    let errors = [];
    if (isBlocked) {
      errors.push("Mailpro has been locked for this mailing.");
    } else if (editMode === "globalNote") {
      //check for global note
      //check for beforeEditNote also because support we only edited global note but did not submitted and current edit mode not equal to global note otherwise for first case it always shows errors
      if (
        !plainNote ||
        (!beforeEditNote && editMode !== "globalNote") ||
        (plainNote && plainNote.length < this.globalNoteMinLength)
      ) {
        errors.push(
          `A minimum of ${this.globalNoteMinLength} characters is required for GLOBAL NOTE.`
        );
      } else if (plainNote && plainNote.length > this.globalNoteMaxLength) {
        errors.push(
          `A maximum of ${this.globalNoteMaxLength} characters is can be added for GLOBAL NOTE.`
        );
      } else if (
        noteUtils.countLineBreaksInHtmlNote(
          globalNote,
          perLineComfortableNoteCharCount
        ) > MaxAllowedLinesInNote
      ) {
        errors.push(
          `A maximum of ${MaxAllowedLinesInNote} lines can be added for GLOBAL NOTE.`
        );
      }
      // else if (this.filterNote(plainNote).includes("*")) {
      //   errors.push('Please remove blacklisted word from your GLOBAL NOTE.');
      // }
    } else if (editMode === "globalPS") {
      //check for P.S
      if (
        (!globalPS && !beforeEditPS) ||
        (globalPS && globalPS.length < this.globalPSMinLength)
      ) {
        errors.push(
          `A minimum of ${this.globalPSMinLength} characters is required for Global P.S.`
        );
      } else if (globalPS && globalPS.length > this.globalPSMaxLength) {
        errors.push(
          `A maximum of ${this.globalPSMaxLength} characters is can be added for Global P.S.`
        );
      }
    }
    if (errors.length !== 0) {
      this.setState({
        error: errors.join(" "),
      });
    } else {
      //call the methods accoridngly
      //bypass the
      //!grammerChecked ? this.applyGrammerCheck(event) : this.openWarningDialog();
      this.openWarningDialog();
    }
  };

  getAllGlobalNoteHistory = () => {
    /**check for latest current area data */
    let currentArea = this.getCurrentArea();
    //we only have to ssend from current version keys
    //currentArea.globalNoteAndPSHistory !== null && currentArea.globalNoteAndPSHistory !== "<p></p>" && currentArea.globalNoteAndPSHistory !== "-"
    return currentArea && Array.isArray(currentArea.globalNoteAndPSHistory)
      ? currentArea.globalNoteAndPSHistory
      : "";
  };

  render() {
    // /version
    const {
      classes,
      mailingVersion,
      lastPMAPrintDate,
      currentArea,
    } = this.props;
    const {
      open,
      globalNote,
      globalPS,
      plainNote,
      submitted,
      error,
      isGlobalNotePSUpdated,
      isBlocked,
      showNotePSReviewDialog,
      showGrammerSection,
      grammerCheckLoading,
      grammerChecked,
      textForGrammerCheck,
      startNotePSAreaMode,
      showPSWarningDialog,
      greetTextTop,
      signOffTextBottom,
      currentEditMode,
      isLoading,
      rteResetMode,
      beforeEditNote,
      beforeEditPS,
    } = this.state;
    let {
      changeDetails,
      mailingDetails,
      agentData,
      propertyDetails,
      mlsAreas,
    } = this.props;

    /**proceed now */
    let globalPSCreationDate = this.getGlobalNoteDateByAreaLogic();
    //we need to call here again becuase in table we dont call open method
    let globalNoteShowHideLogic = renderLogicUtil.checkGlobalNoteShowHideLogicV2(
      globalPSCreationDate,
      changeDetails,
      propertyDetails
    );
    //get the global note now
    let globalXNote = this.getGlobalNoteByAreaLogic();
    let globalXPS = this.getGlobalPSByAreaLogic();
    //we need to check current mailing personal note. if its there then always show personal note
    const { textPS } = changeDetails ? changeDetails : {};
    let anyPersonalNoteAddedinCurrentMailing =
      textPS && textPS !== "<p></p>" && textPS !== "-";
    //get all global notes
    const xTableNotes = this.getAllGlobalNoteHistory();
    let salutationComma = ",";
    if (agentData.listgenUserID === "106145") {
      salutationComma = "—";
    }
    return (
      <Fragment>
        {showNotePSReviewDialog ? (
          <GlobalNotePSReviewDialogComponent
            version={mailingVersion}
            greetingTop={greetTextTop}
            greetingBottom={signOffTextBottom}
            globalNoteToReview={
              currentEditMode === "globalNote" ? globalNote : beforeEditNote
            }
            confirmationID={null}
            closeFn={this.closeGlobalPSReviewDialog}
            methodToCallOnConfirmation={this.beforeOnSubmit}
            cameFrom="gps"
            globalPS={currentEditMode === "globalPS" ? globalPS : beforeEditPS}
            globalPSTeam={""}
            listgenUserID={agentData.listgenUserID}
            agentData={agentData}
            currentArea={currentArea}
          />
        ) : (
          ""
        )}
        {startNotePSAreaMode ? (
          <GlobalNoteAreaChooserDialog
            currentArea={this.getCurrentArea()}
            mlsAreas={mlsAreas}
            closeFn={this.closeNotePSAreaModeDialog}
            submitFn={this.onSubmit}
            currentEditMode={currentEditMode}
          />
        ) : (
          ""
        )}
        {showPSWarningDialog ? (
          <MailProNoteWarningDialog
            closeFn={this.handleClose}
            submitFn={this.showGlobalNotePSReviewDialog}
            type={"gps"}
            areaName={this.getCurrentAreaName()}
            currentEditMode={currentEditMode}
          />
        ) : (
          ""
        )}
        <div className="global-global-note-body-container tableTdFlex">
          <div className="tableTdFlex-First textCursor">
            <div>
              {globalNoteShowHideLogic.showGlobalNote &&
              !anyPersonalNoteAddedinCurrentMailing ? (
                this.getFormattedGlobalNote(
                  globalXNote,
                  globalXPS,
                  classes,
                  true
                )
              ) : (
                <span>
                  You have written a PERSONAL NOTE / PS to this homeowner, which
                  overrides the GLOBAL NOTE for this mailing only.
                </span>
              )}
              {/* <span className={classes.redColor}>Clear PERSONAL NOTE / PS to use GLOBAL NOTE for this homeowner.</span> */}
            </div>
          </div>
          {globalNoteShowHideLogic.showGlobalNote &&
          !anyPersonalNoteAddedinCurrentMailing ? (
            <div
              className="tableTdFlex-Second pointer"
              onClick={(event) => this.handleClickOpen()}
            >
              <p className="blue-text mailProLinkClass">
                Edit
                <br />
                Global Note
              </p>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        {open ? (
          <Fragment>
            <Dialog
              open={true}
              onClose={this.handleClose}
              scroll={"paper"}
              aria-labelledby="propert-view-dialog"
              aria-describedby="propert-view-dialog-description"
              maxWidth={"md"}
              TransitionComponent={Transition}
              keepMounted
              fullWidth
            >
              <DialogTitle id="scroll-dialog-title">
                <div className={classes.propertyFactHeader}>
                  <div></div>
                  <div>
                    <Typography variant="h4" align="center">
                      Updating GLOBAL NOTE for {this.getCurrentAreaName()}
                    </Typography>
                    <Typography variant="body1" align="center">
                      This GLOBAL NOTE will automatically be printed on{" "}
                      <span className={classes.boldText}>all</span> your current
                      PMAs for {this.getCurrentAreaName()}.
                    </Typography>
                  </div>
                  <MailProCloseButton onClick={this.handleClose} />
                </div>
              </DialogTitle>
              {/* Ho Dynamic section html begin */}
              {agentBarSwitchClosureV2(classes).call(this)}
              {/* Ho Dynamic section html end */}
              <DialogContent
                dividers={true}
                className={classes.dialogContentPadding}
              >
                <div>
                  <div
                    className={classes.noteMoadlContainer}
                    //onClick={props.toggleDetailsModal}
                  >
                    <MailProBlockedEditingDisabledBar
                      isBlocked={isBlocked}
                      classes={classes}
                    />
                    <Container>
                      <div className={classes.fullField}>
                        <RichTextEditorV2Component
                          preText={
                            agentData.listgenUserID === "104835"
                              ? "Greetings " + greetTextTop + ","
                              : "Hello " + greetTextTop + ","
                          }
                          postText={`${propertyUtil.getSalutation(
                            mailingVersion,
                            agentData.listgenUserID
                          )}${salutationComma} ${signOffTextBottom}`}
                          showInlineButton={true}
                          minLength={this.globalNoteMinLength}
                          maxLength={this.globalNoteMaxLength}
                          onChange={this.updateGlobalNote}
                          updatePlainNoteFn={this.updateGlobalPlainNote}
                          preData={globalNote}
                          readOnly={!(currentEditMode === "globalNote")} //setCurrentEditNoteMode
                          type={"globalNote"}
                          category={"note"}
                          returnTextMode={"html"}
                          rteResetEditor={
                            currentEditMode === "globalNote"
                              ? rteResetMode
                              : false
                          }
                          disableRTEResetMode={this.handleRTEDisableMode}
                        >
                          <div>
                            {!(currentEditMode === "globalNote") ? (
                              <Button
                                color="secondary"
                                variant="contained"
                                size="small"
                                className={classes.richEditorButton}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  this.setCurrentEditNoteMode.bind(this)(
                                    "globalNote"
                                  );
                                }}
                                disabled={isLoading}
                              >
                                {
                                  "Click here to Edit the Global Note for ALL Homeowners"
                                }
                              </Button>
                            ) : (
                              <Button
                                color="secondary"
                                variant="contained"
                                size="small"
                                className={classes.editNoteButton}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  this.submitErrors.bind(this)(
                                    event,
                                    "globalNote"
                                  );
                                }}
                                disabled={isLoading}
                              >
                                SUBMIT GLOBAL NOTE TO ALL HOMEOWNERS
                              </Button>
                            )}
                          </div>
                        </RichTextEditorV2Component>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p
                            className={classes.restoreGlobalPSButton}
                            onClick={(event) =>
                              this.restoreSystemGlobalNote(event)
                            }
                            style={{
                              pointerEvents: isLoading ? "none" : "auto",
                            }}
                          >
                            Restore System Global Note
                          </p>
                        </div>
                      </div>
                      {submitted &&
                        currentEditMode === "globalNote" &&
                        globalNote.length >= 1 && (
                          <Typography variant="subtitle2" align="center">
                            <div
                              className={classNames(
                                classes.greenColor,
                                classes.centerAlign,
                                classes.lightBoldText
                              )}
                            >
                              Submitted Successfully!
                            </div>
                          </Typography>
                        )}
                      {error && currentEditMode === "globalNote" && (
                        <Typography
                          variant="subtitle2"
                          align="center"
                          style={{ margin: "8px" }}
                        >
                          <div
                            className={classNames(
                              classes.darkRedColor,
                              classes.centerAlign,
                              classes.lightBoldText
                            )}
                          >
                            {error}
                          </div>
                        </Typography>
                      )}
                      <div style={{ marginTop: "16px", display: "none" }}>
                        <RichTextEditorV2Component
                          preText={"P.S."}
                          postText={``}
                          showPlaceholder={true}
                          showInlineButton={true}
                          showToolbar={false}
                          placeholder={"Type your Global P.S. here"}
                          minLength={this.globalPSMinLength}
                          maxLength={this.globalPSMaxLength}
                          onChange={this.updateNotePS}
                          updatePlainNoteFn={this.updateNotePS}
                          preData={globalPS}
                          readOnly={!(currentEditMode === "globalPS")}
                          type={"globalPS"}
                          category={"ps"}
                          returnTextMode={"plain"}
                        >
                          <div>
                            {!(currentEditMode === "globalPS") ? (
                              <Button
                                color="secondary"
                                variant="contained"
                                size="small"
                                className={classes.richEditorButton}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  this.setCurrentEditNoteMode.bind(this)(
                                    "globalPS"
                                  );
                                }}
                                disabled={isLoading}
                              >
                                Click here to Create a P.S. to ALL your
                                Homeowners
                              </Button>
                            ) : (
                              <Button
                                color="secondary"
                                variant="contained"
                                size="small"
                                className={classes.editNoteButton}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  this.submitErrors.bind(this)(
                                    event,
                                    "globalPS"
                                  );
                                }}
                                disabled={isLoading}
                              >
                                SUBMIT GLOBAL P.S. TO ALL HOMEOWNERS
                              </Button>
                            )}
                          </div>
                        </RichTextEditorV2Component>
                      </div>
                      {submitted &&
                        currentEditMode === "globalPS" &&
                        globalPS.length >= 1 && (
                          <Typography variant="subtitle2" align="center">
                            <div
                              className={classNames(
                                classes.greenColor,
                                classes.centerAlign,
                                classes.lightBoldText
                              )}
                            >
                              Submitted Successfully!
                            </div>
                          </Typography>
                        )}
                      {error && currentEditMode === "globalPS" && (
                        <Typography
                          variant="subtitle2"
                          align="center"
                          style={{ margin: "8px" }}
                        >
                          <div
                            className={classNames(
                              classes.darkRedColor,
                              classes.centerAlign,
                              classes.lightBoldText
                            )}
                          >
                            {error}
                          </div>
                        </Typography>
                      )}
                      {/* <TextField
                        id="custom-note-global-note"
                        label="Type your Global P.S. here"
                        placeholder="Type your Global P.S. here"
                        className={classNames(classes.textField)}
                        value={globalPS}
                        onChange={event => { this.updateNotePS(event.target.value) }}
                        variant="outlined"
                        margin="dense"
                        fullWidth={true}
                        inputProps={{
                          maxLength: this.globalPSMaxLength,
                          minLength: this.globalPSMinLength,
                          className: isGlobalNotePSUpdated ? classes.lightBlueBackground : classes.disabledInput
                        }}
                        InputProps={{
                          startAdornment: isGlobalNotePSUpdated ? <InputAdornment className={classNames(classes.lightBlueBackground, classes.hoverBgLightBlue)} position="start"><span className={classes.warningPrimaryText}>P.S.</span></InputAdornment> : '',
                          className: isGlobalNotePSUpdated ? classNames(classes.lightBlueBackground, classes.hoverBgLightBlue) : ''
                        }}
                        helperText={`[Optional] Min ${this.globalPSMinLength} characters and Max ${this.globalPSMaxLength} characters allowed`}
                        style={{ marginTop: '16px' }}
                        disabled={!isGlobalNotePSUpdated}
                      />
                      <Typography variant="subtitle2" align="center" color="primary">
                        P.S. Character count {globalPS && globalPS.length ? globalPS.length : '0'}/{this.globalPSMaxLength}
                      </Typography> */}
                      {/* {showGrammerSection ?
                        <div className={classes.grammerInnerContainer}>
                          <GrammerChecker
                            textToCheck={textForGrammerCheck}
                            onSuccessFn={this.openWarningDialog}//open the warning dialog first//this.showGlobalNotePSReviewDialog
                            validateButtonLoadingMethod={this.setGrammerLoading}
                          />
                        </div>
                        : ''}
                      {submitted && globalNote.length >= 1 && (
                        <Typography variant="subtitle2" align="center">
                          <div
                            className={classNames(
                              classes.greenColor,
                              classes.centerAlign,
                              classes.lightBoldText
                            )}
                          >
                            Submitted Successfully!
                          </div>
                        </Typography>
                      )}
                      {error && (
                        <Typography variant="subtitle2" align="center" style={{ margin: '8px' }}>
                          <div
                            className={classNames(
                              classes.darkRedColor,
                              classes.centerAlign,
                              classes.lightBoldText
                            )}
                          >
                            {error}
                          </div>
                        </Typography>
                      )} */}
                      {/* //dont show the header is global note is not in the current mailing */}
                      {/* /|| (mailingVersion !== version) */}
                      {/* {isBlocked ? '' : */}
                      {/* <Fragment>
                        {this.state.isLoading ? (
                          <div className={classNames(classes.fullWidth, classes.cProgressStopBlinking)}>
                            <CircularProgress className={classes.cProgress} />
                          </div>
                        ) : (
                          <Fragment>
                            <div className={classes.portletFooter}>
                              <div className={classes.footerButtons}>
                                <div className={classes.footerButtonsLeftRight}>
                                  {isGlobalNotePSUpdated ?
                                    <Button
                                      variant="outlined"
                                      color="default"
                                      onClick={event => this.cancelPSEditMode(event)}
                                    >
                                      Cancel Editing
                                    </Button>
                                    : ''
                                  }
                                </div>
                                <div className={classes.footerButtonsCenter}>
                                  {!isGlobalNotePSUpdated ?
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={event => this.enableNotePSEditMode(event)}
                                    >
                                      Edit Global Note
                                    </Button>
                                    :
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      disabled={grammerCheckLoading}
                                      onClick={event => this.submitErrors(event)}
                                    >
                                      {!grammerCheckLoading ? 'Submit' : 'Analyzing note...'}
                                    </Button>
                                  }
                                </div>
                                <div className={classNames(classes.footerButtonsLeftRight, classes.endAlignemnt)}>
                                  <Button
                                    variant="contained"
                                    color="default"
                                    onClick={event => this.restoreSystemGlobalPS(event)}
                                  >
                                    Restore Default Note
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Fragment>)
                        }
                      </Fragment> */}
                      {/* } */}
                      {/* {submitted && globalNote.length >= 1 && (
                        <Typography variant="subtitle2" align="center">
                          <div
                            className={classNames(
                              classes.greenColor,
                              classes.centerAlign,
                              classes.lightBoldText
                            )}
                          >
                            Submitted Successfully!
                 </div>
                        </Typography>
                      )} */}
                    </Container>
                  </div>
                </div>
                <div className={classes.extraMarginTableSection}>
                  {/* <Typography variant="h5" align="center">
                    Added Notes
                  </Typography> */}
                  {/* <div className="divider">
                    <Divider />
                  </div> */}
                  <Fragment>
                    <Table
                      className={classNames(
                        classes.fullWidth,
                        classes.tableMarginBottom
                      )}
                      size="small"
                    >
                      <TableHead>
                        <TableRow className={classes.fullWidthTableRow}>
                          <TableCell align="center" style={{ width: "10%" }}>
                            Created on
                          </TableCell>
                          <TableCell align="center" style={{ width: "70%" }}>
                            OLD GLOBAL NOTES History
                          </TableCell>
                          {/* <TableCell align="center" style={{ width: '20%' }}>OLD GLOBAL NOTES Type</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {xTableNotes.length === 0 ? (
                          <TableCell
                            component="td"
                            scope="row"
                            align="center"
                            colSpan={3}
                          >
                            <Typography variant="subtitle2">
                              No OLD GLOBAL NOTE has been added for this
                              Homeowner yet.
                            </Typography>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        {propertyUtil
                          .reverseNotes(
                            Array.isArray(xTableNotes) ? xTableNotes : []
                          )
                          .map((item, index) => {
                            return (
                              <TableRow
                                key={index}
                                className={classes.fullWidthTableRow}
                              >
                                <TableCell
                                  component="td"
                                  scope="row"
                                  align="center"
                                >
                                  {item.isGlobalNoteUpdated ? (
                                    <Fragment>
                                      <div>You created this global note</div>
                                      <br />
                                    </Fragment>
                                  ) : (
                                    <div></div>
                                  )}
                                  {(item.GlobaNoteCurrentDateTime &&
                                    this.formatDate(
                                      item.GlobaNoteCurrentDateTime
                                    )) ||
                                    (item.GlobalPSCurrentDateTime &&
                                      this.formatDate(
                                        item.GlobalPSCurrentDateTime
                                      ))}
                                </TableCell>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  align="center"
                                >
                                  <Typography
                                    variant="subtitle2"
                                    className={classNames(
                                      "breakAll",
                                      classes.lightBlueBackground,
                                      item.isGlobalNoteUpdated
                                        ? classes.greenColor
                                        : ""
                                    )}
                                    style={{
                                      padding: "8px",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    {item.GlobalNoteCurrent && (
                                      <div
                                        className="richTextEditorGlobalCss"
                                        dangerouslySetInnerHTML={{
                                          __html: item.GlobalNoteCurrent,
                                        }}
                                      />
                                    )}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    className={classNames(
                                      "breakAll",
                                      classes.lightBlueBackground,
                                      item.isGlobalPSUpdated
                                        ? classes.greenColor
                                        : ""
                                    )}
                                    align="left"
                                    style={{
                                      marginTop: "8px",
                                      padding: "8px",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    P.S.:&nbsp;
                                    {item.GlobalPSCurrent
                                      ? item.GlobalPSCurrent
                                      : "Not added"}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </Fragment>
                </div>
              </DialogContent>
            </Dialog>
          </Fragment>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentArea: state.pma.currentArea,
    mailingVersion: state.pma.mailingVersion,
    mlsAreas: state.pma.mlsAreas,
    //version: state.pma.version,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateNavbar: (mlsAreas) => dispatch(pmaActions.updateNavbar(mlsAreas)),
    updateCurrentAreaData: (currentArea) =>
      dispatch(pmaActions.updateCurrentAreaData(currentArea)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(withStyles(styles))(GlobalNoteDialogV2));
