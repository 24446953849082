import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { GetStateStorage } from "common/storage";
import moment from "moment";
import { propertyUtil } from "./PropertyUtil";
import { MailProConstants } from "./MailPro/PmaMailingList/MailProConstants";
import { noteUtils } from "./MailPro/components/Notes/Common/NoteUtils";
import { NotesCameFrom } from "./MailPro/components/Notes/Common/NotesConfig";

/**
 * @name getAutoTagMapping
 * @param {*} propertyDetails
 * @param {*} agentData
 * @returns
 */
const getAutoTagMapping = (propertyDetails, agentData) => {
  /**logic to check whether agent name is equal to buyerfull naem or not  */
  const { agentLicenseNumber } = agentData ? agentData : {};
  const {
    listingStatus,
    listingAgentLicenseID,
    sellingAgentLicenseID,
    oldSalesFlag,
  } = propertyDetails ? propertyDetails : {};
  let blockSystemTags = [];
  if (
    listingStatus === "Active" &&
    Number(listingAgentLicenseID) === Number(agentLicenseNumber)
  ) {
    //MyListings
    blockSystemTags.push("My Active Listing");
  } else if (
    (listingStatus === "Pending" || listingStatus === "Contingent") &&
    Number(listingAgentLicenseID) === Number(agentLicenseNumber)
  ) {
    //My Escrow
    blockSystemTags.push("My Pending Sale"); //My Escrow
  } else if (
    listingStatus === "Sold" &&
    Number(sellingAgentLicenseID) === Number(agentLicenseNumber)
  ) {
    //My Solds
    blockSystemTags.push("My Buyer Sold");
  } else if (
    listingStatus === "Sold" &&
    Number(listingAgentLicenseID) === Number(agentLicenseNumber)
  ) {
    //My Listing Sold
    blockSystemTags.push("My Listing Sold");
  }
  //check for old sale flag also. It would be seprate from other block items.(My Sold, My Listing and My Escrow)
  if (oldSalesFlag) {
    //My Old Sale
    blockSystemTags.push("My Old Sale");
  }
  return blockSystemTags;
};

/**
 * @name getPropertyTotalTagCount
 * @param {*} property single property data
 * @param {*} agentData  agent data to compare license number
 * @returns
 */
const getPropertyTotalTagCount = (property, agentData) => {
  return (
    (property &&
    property.changeDetails &&
    property.changeDetails.tags &&
    Array.isArray(property.changeDetails.tags)
      ? property.changeDetails.tags.length
      : 0) +
    getAutoTagMapping(
      property.propertyDetails ? property.propertyDetails : {},
      agentData
    ).length
  );
};

/**
 * @name showGlobalNoteOrNot
 * @description lastPrintDate is last print date moment object, globalPSCreationDate is global ps creation date moment object
 */
const checkGlobalNoteShowHideLogic = (
  globalNoteCreationDate,
  changeDetails,
  propertyDetails,
  globalPSCreationDate = null
) => {
  let { personalPSHistory } = changeDetails
    ? changeDetails
    : propertyDetails
    ? propertyDetails
    : {};
  personalPSHistory = personalPSHistory ? personalPSHistory : [];
  let lastPrintDate = propertyUtil.getLastPrintDate();
  //let { lastPrintDate } = this.state;
  //let globalNoteCreationDate = this.getGlobalPSDateByAreaLogic();
  let lastPSNoteData = personalPSHistory[personalPSHistory.length - 1];
  let personalPSCreationDate = lastPSNoteData && lastPSNoteData.date;
  //build the moment object
  globalNoteCreationDate = globalNoteCreationDate
    ? moment(new Date(globalNoteCreationDate), "YYYY-MM-DD H:m:s")
    : null;
  personalPSCreationDate = personalPSCreationDate
    ? moment(new Date(personalPSCreationDate), "YYYY-MM-DD H:m:s")
    : null;
  lastPrintDate = moment(new Date(lastPrintDate), "YYYY-MM-DD H:m:s");
  //decalre variables
  let showGlobalNote = true;
  let isGlobalNoteAddedInCurrentMailing = true;
  let isGlobalPSAddedInCurrentMailing = true;
  /**
   * First check show global note or not logic
   */
  //1. no global ps date, personal ps is after print date cant show the gps
  //2. both not null and personalPSCreationDate is after the globalNoteCreationDate
  // both false then show global note
  if (
    (!globalNoteCreationDate &&
      personalPSCreationDate &&
      personalPSCreationDate.isAfter(lastPrintDate)) ||
    (personalPSCreationDate &&
      globalNoteCreationDate &&
      personalPSCreationDate.isAfter(globalNoteCreationDate) &&
      personalPSCreationDate.isAfter(lastPrintDate))
  ) {
    showGlobalNote = false;
  }
  /**
   * Check for isGlobalNoteAddedInCurrentMailing. compare gps creation date with last print date. it should be more than that.
   */
  /**
   * Both date check is not needed because from backend we will reset the global note and its related fields
   */
  // if (
  //   !globalNoteCreationDate ||
  //   (globalNoteCreationDate && globalNoteCreationDate.isSameOrBefore(lastPrintDate))
  // ) {
  //   isGlobalNoteAddedInCurrentMailing = false;
  // }
  //check for global ps also
  // if (
  //   !globalPSCreationDate ||
  //   (globalPSCreationDate && globalPSCreationDate.isSameOrBefore(lastPrintDate))
  // ) {
  //   isGlobalNoteAddedInCurrentMailing = false;
  // }
  return {
    showGlobalNote,
    isGlobalNoteAddedInCurrentMailing,
    isGlobalPSAddedInCurrentMailing,
  };
};

/**
 * @name checkGlobalNoteShowHideLogicV2
 * @description Based on new personal note flags will check what to show global note or personal note
 * @param {*} globalNoteCreationDate
 * @param {*} changeDetails
 * @param {*} propertyDetails
 * @param {*} globalPSCreationDate
 * @returns
 */
const checkGlobalNoteShowHideLogicV2 = (
  globalNoteCreationDate,
  changeDetails,
  propertyDetails
) => {
  let { textPSDate, personalPSDate, textPS, personalPS } = changeDetails
    ? changeDetails
    : {};

  //if textPSDate is greate than globalNoteCreationDate than show personal note otherwise show personal note
  globalNoteCreationDate = globalNoteCreationDate
    ? moment(new Date(globalNoteCreationDate), "YYYY-MM-DD H:m:s")
    : null;
  let personalPSCreationDate = textPSDate
    ? moment(new Date(textPSDate), "YYYY-MM-DD H:m:s")
    : null;
  let lastPrintDate = propertyUtil.getLastPrintDate();

  let showGlobalNote = true;
  let isGlobalNoteAddedInCurrentMailing = true;
  let isGlobalPSAddedInCurrentMailing = true;

  //put check of -, p tag also because it ccan have
  if (
    (textPS && textPS !== "-" && textPS !== "<p></p>") ||
    (personalPS && personalPS !== "-" && personalPS !== "<p></p>")
  ) {
    showGlobalNote = false;
  }

  // if (
  //   (!globalNoteCreationDate &&
  //     personalPSCreationDate &&
  //     personalPSCreationDate.isAfter(lastPrintDate)) ||
  //   (personalPSCreationDate &&
  //     globalNoteCreationDate &&
  //     personalPSCreationDate.isAfter(globalNoteCreationDate) &&
  //     personalPSCreationDate.isAfter(lastPrintDate))
  // ) {
  //   showGlobalNote = false;
  // }

  return {
    showGlobalNote,
    isGlobalNoteAddedInCurrentMailing,
    isGlobalPSAddedInCurrentMailing,
  };

  // let { personalPSHistory } = changeDetails
  //   ? changeDetails
  //   : propertyDetails
  //   ? propertyDetails
  //   : {};
  // personalPSHistory = personalPSHistory ? personalPSHistory : [];
  // let lastPrintDate = propertyUtil.getLastPrintDate();
  // //let { lastPrintDate } = this.state;
  // //let globalNoteCreationDate = this.getGlobalPSDateByAreaLogic();
  // let lastPSNoteData = personalPSHistory[personalPSHistory.length - 1];
  // let personalPSCreationDate = lastPSNoteData && lastPSNoteData.date;
  // //build the moment object
  // globalNoteCreationDate = globalNoteCreationDate
  //   ? moment(new Date(globalNoteCreationDate), "YYYY-MM-DD H:m:s")
  //   : null;
  // personalPSCreationDate = personalPSCreationDate
  //   ? moment(new Date(personalPSCreationDate), "YYYY-MM-DD H:m:s")
  //   : null;
  // lastPrintDate = moment(new Date(lastPrintDate), "YYYY-MM-DD H:m:s");
  // //decalre variables
  // let showGlobalNote = true;
  // let isGlobalNoteAddedInCurrentMailing = true;
  // let isGlobalPSAddedInCurrentMailing = true;
  /**
   * First check show global note or not logic
   */
  //1. no global ps date, personal ps is after print date cant show the gps
  //2. both not null and personalPSCreationDate is after the globalNoteCreationDate
  // both false then show global note
  // if (
  //   (!globalNoteCreationDate &&
  //     personalPSCreationDate &&
  //     personalPSCreationDate.isAfter(lastPrintDate)) ||
  //   (personalPSCreationDate &&
  //     globalNoteCreationDate &&
  //     personalPSCreationDate.isAfter(globalNoteCreationDate) &&
  //     personalPSCreationDate.isAfter(lastPrintDate))
  // ) {
  //   showGlobalNote = false;
  // }
  /**
   * Check for isGlobalNoteAddedInCurrentMailing. compare gps creation date with last print date. it should be more than that.
   */
  /**
   * Both date check is not needed because from backend we will reset the global note and its related fields
   */
  // if (
  //   !globalNoteCreationDate ||
  //   (globalNoteCreationDate && globalNoteCreationDate.isSameOrBefore(lastPrintDate))
  // ) {
  //   isGlobalNoteAddedInCurrentMailing = false;
  // }
  //check for global ps also
  // if (
  //   !globalPSCreationDate ||
  //   (globalPSCreationDate && globalPSCreationDate.isSameOrBefore(lastPrintDate))
  // ) {
  //   isGlobalNoteAddedInCurrentMailing = false;
  // }
  return {
    showGlobalNote,
    isGlobalNoteAddedInCurrentMailing,
    isGlobalPSAddedInCurrentMailing,
  };
};

// /**
//  * @name getCurrentMailingPersonalNote
//  * @description Can be used to get current mailing personal note with type checking or without type checking
//  */
const getCurrentMailingPersonalNote = (
  personalPSHistory,
  lastPrintDate,
  cameFrom,
  applyTypeCheck = true
) => {
  //check this method
  //let isHavingNote = false;
  let afterDateNote = null;
  /**no need of reverse mode because we only want last one */
  (personalPSHistory ? personalPSHistory : []).map((item) => {
    let noteDate = moment(new Date(item.date), "YYYY-MM-DD");
    let lastXPrintDate = moment(new Date(lastPrintDate), "YYYY-MM-DD");
    /**put special check for type also */
    if (noteDate.isAfter(lastXPrintDate) || noteDate.isSame(lastXPrintDate)) {
      //personalNote date is after last print date Yoo!!! we have a new note to show in the textbox.
      //check whether we want type check or not
      if (applyTypeCheck) {
        //check for type also
        if (item.type && item.type === cameFrom) {
          //if type matching than only send data
          // isHavingNote = true;
          afterDateNote = item;
        }
      } else {
        //means to type check send data
        // isHavingNote = true;
        afterDateNote = item;
      }
    }
  });
  return afterDateNote;
};

/**
 * Check whether to show the dialog or not
 */
const showNewPropertyMailProDialog = (
  currentArea,
  propertyData,
  isMailProLocked,
  mode = MailProConstants
) => {
  /**
   * Conditions:
   * if Mailpro is blocked should not show this
   * Show if its monday and have properties with newMailing as true and isRemindMeLater is false
   * Show if newMailing we have and remind me is for the CURRENT DAY. Not valid for before one
   * In case of showNewPropertyMailProDialog we will hide the mail pro layout like navbar (search) and mailing table and will show the new mailing component table
   * !isRemindMeDateIsToday w ehave to do with is{any Day after today} check becauseafter click of remind me later we need to hide the new mailing component and show it on next day
   */
  //return false;
  if (isMailProLocked) {
    return false;
  }
  let anyNewMailing = propertyData.filter(
    (item) =>
      item &&
      item.changeDetails &&
      (mode
        ? item.changeDetails[mode]
        : item.changeDetails[MailProConstants.newMailing] ||
          item.changeDetails.isNewProperty)
  );
  //check for isNewProperty - new Mailing change
  if (mode === MailProConstants.newMailing) {
    //filter out isNewPropertyfrom newMailing
    anyNewMailing = (anyNewMailing || []).filter(
      (item) =>
        !(item && item.changeDetails && item.changeDetails.isNewProperty)
    );
    anyNewMailing = anyNewMailing.length > 0 ? anyNewMailing[0] : undefined;
  }
  let isRemindMeDateIsToday = true;
  // currentArea &&
  //   currentArea.isRemindMeLater &&
  //   currentArea.remindMeLaterDateTime &&
  //   moment(new Date(currentArea.remindMeLaterDateTime)).isSame(
  //     new Date(),
  //     "day"
  //   )
  //   ? true
  //   : false;
  let isRemindMeDateIsDayAfterToday = false;
  // currentArea &&
  //   currentArea.isRemindMeLater &&
  //   currentArea.remindMeLaterDateTime &&
  //   moment(new Date(currentArea.remindMeLaterDateTime)).isAfter(
  //     new Date(),
  //     "day"
  //   )
  //   ? true
  //   : false;
  let isMonday = moment().isoWeekday() === 1;
  return (
    //isMonday &&
    (anyNewMailing &&
      Object.keys(anyNewMailing).length > 0 &&
      !isRemindMeDateIsDayAfterToday) ||
    (isRemindMeDateIsToday &&
      anyNewMailing &&
      Object.keys(anyNewMailing).length > 0)
  );
};

/**
 * @name showNewAnniversaryMailProDialog
 * @param {*} propertyData
 * @param {*} hoFilter
 * @returns
 */
const showNewAnniversaryMailProDialog = (propertyData, hoFilter = null) => {
  const anniversaryHomeowners = (propertyData || []).find(
    (prop) => noteUtils.getAPropertyPendingAnniversaryNoteStatuswrtHOFilter(prop, NotesCameFrom.Anniversary, hoFilter));
  return anniversaryHomeowners ? true : false;
};

/**
 * @name countMailProInitProperties
 * @description To count new properties in the homeowenrs array
 * @param {*} propertyData
 * @returns
 */
const countMailProInitProperties = (
  propertyData,
  returnCount = false,
  mode = MailProConstants,
  hoFilter = null
) => {
  let newHO = [];
  if (
    mode === MailProConstants.isNewProperty ||
    mode === MailProConstants.newMailing
  ) {
    newHO = [
      ...propertyData.filter(
        (item) =>
          item &&
          item.changeDetails &&
          (mode
            ? item.changeDetails[mode]
            : item.changeDetails.newMailing || item.changeDetails.isNewProperty)
      ),
    ];
    //check for isNewProperty - new Mailing change
    if (mode === MailProConstants.newMailing) {
      //filter out isNewPropertyfrom newMailing
      newHO = (newHO || []).filter(
        (item) =>
          !(item && item.changeDetails && item.changeDetails.isNewProperty)
      );
    }
  } else if (mode === MailProConstants.newAnniversary) {
    newHO = (propertyData || []).filter(
      (prop) =>
        prop.HOQuickFilterData.isAnniversary &&
        checkPropertyStatuswrtHOFilterStatus(prop, hoFilter)
    );
  }
  return returnCount ? newHO.length : newHO;
};

/**
 * @name parcelNumberBuilder
 * @description Can be used to build and format parcel number
 * @param {*} parcelNumber
 * @returns Formatted parcel number
 */
const parcelNumberBuilder = (parcelNumber) => {
  return parcelNumber
    ? propertyUtil
        .valueCorrector(parcelNumber)
        .split(" ")
        .slice(0, 3)
        .join("-")
        .slice(0, 11)
    : "";
};

//remove this method once natalie integrate new logic
const homeownerNamesV3 = () => {
  return {};
};

const getNoteGreetingTextV3 = () => {
  return "";
};

const getFirstWordofaName = (name) => {
  let regex = new RegExp(/[^\s]+/);
  name = name ? regex.exec(name.trim()) : [];
  return name[0] ? name[0].trim() : "";
};

const getNoteGreetingTextV4 = (
  { lgOwnerFirstName, lgSecondaryOwnerFirstName },
  symbol
) => {
  lgOwnerFirstName =
    lgOwnerFirstName && lgOwnerFirstName !== "-" ? lgOwnerFirstName : ""; //.split(' ')
  lgSecondaryOwnerFirstName =
    lgSecondaryOwnerFirstName && lgSecondaryOwnerFirstName !== "-"
      ? lgSecondaryOwnerFirstName
      : ""; //.split(" ")
  return `${
    lgOwnerFirstName
      ? `${lgOwnerFirstName}${
          lgSecondaryOwnerFirstName
            ? `${symbol ? " " + symbol + " " : ""}${lgSecondaryOwnerFirstName}`
            : ``
        }`
      : ``
  }`;
  //return `${lgOwnerFirstName[0] ? `${lgOwnerFirstName[0]}${lgSecondaryOwnerFirstName[0] ? `${symbol ? " " + symbol + " " : ""}${lgSecondaryOwnerFirstName[0]}`  : ``}` : ``}`;
};

function isAbsenteeBypassAgent() {
  let storedData = GetStateStorage();
  //(agentData && (agentData.listgenUserID === "100458" || agentData.listgenUserID === "100526") ? true : false);
  try {
    if (
      storedData &&
      storedData.userData &&
      storedData.userData.lastPMAPrintDate
    ) {
      return storedData.userData.lastPMAPrintDate;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}

const isABypassAgent = (agentData) => {
  return agentData &&
    (agentData.listgenUserID === "100458" ||
      agentData.listgenUserID === "100526" ||
      agentData.listgenUserID === "100902")
    ? true
    : false;
};

const isHomeownerAbsentie = (property, bypassMailingCityCheck) => {
  let {
    coincidence,
    MailAddress,
    MailCity,
    MailState,
    SiteNumber,
    StreetSuffix,
    SiteStreet,
    SiteCity,
    SiteState,
    IsOwnerOccupied,
  } = property.mailingDetails || {};
  //first check based on mailing details IsOwnerOccupied key 1 means owner occupied, 0 means absentee HO
  if (IsOwnerOccupied !== undefined) {
    return !Number(IsOwnerOccupied);
  }
  //NOTE:- once we have IsOwnerOccupied for all counties then comment below logic // TODO
  /**clean the data */
  MailAddress = propertyUtil.emptyValueCheck(MailAddress);
  MailCity = propertyUtil.emptyValueCheck(MailCity);
  MailState = propertyUtil.emptyValueCheck(MailState);
  SiteNumber = propertyUtil.emptyValueCheck(SiteNumber);
  SiteStreet = propertyUtil.emptyValueCheck(SiteStreet);
  SiteCity = propertyUtil.emptyValueCheck(SiteCity);
  SiteState = propertyUtil.emptyValueCheck(SiteState);
  SiteStreet = propertyUtil.emptyValueCheck(SiteStreet);
  /**
   * If MailAddress, SiteNumber or SiteStreet is empty then don't show home owner absent text
   */
  if (!MailAddress || !SiteStreet) {
    return false;
  } //bypassMailingCityCheck
  let xMailAddress =
    MailAddress +
    (!bypassMailingCityCheck ? " " + MailCity : "") +
    " " +
    MailState; //
  let siteAddress =
    SiteNumber +
    " " +
    SiteStreet +
    (!bypassMailingCityCheck ? " " + SiteCity : "") +
    " " +
    SiteState; //

  //compare initial two words
  xMailAddress = xMailAddress.split(" ").slice(0, 2).join(" ");
  siteAddress = siteAddress.split(" ").slice(0, 2).join(" ");

  if (
    xMailAddress.toLowerCase() === siteAddress.toLowerCase() ||
    (MailCity === SiteCity &&
      MailState === SiteState &&
      MailAddress.includes(SiteNumber + " " + SiteStreet))
  ) {
    return false;
    // not a absentie HO
  } else {
    return true;
    //not following any of condition absentee Ho
  }
};

const checkPropertyStatuswrtHOFilterStatus = (prop, hoFilter) => {
  if (!hoFilter || hoFilter === "all" || hoFilter === "") return true;
  else {
    return hoFilter === "active"
      ? prop.HOQuickFilterData.isActive
      : hoFilter === "blocked"
      ? prop.HOQuickFilterData.isBlocked
      : hoFilter === "delete"
      ? prop.HOQuickFilterData.isDeleted
      : true;
  }
};

const getQuickFilterData = (
  propertyData,
  nextPMAPrintDate,
  lastPMAPrintDate,
  agentData = {}
) => {
  propertyData = [...propertyData];
  if (Array.isArray(propertyData)) {
    propertyData.map((props) => {
      if (parseInt(props.agentID) === 0) {
        const { listingStatus } = props.propertyDetails
          ? props.propertyDetails
          : {};
        const isPropertyMapped = props.isPropertyMapped;
        const { latestSaleDate } = props;
        // const lastSaleDate =
        //   props.propertyDetails && props.propertyDetails.lastSaleDate
        //     ? props.propertyDetails.lastSaleDate
        //     : props.mailingDetails && props.mailingDetails.Docdate
        //       ? props.mailingDetails.Docdate
        //       : "";
        //build logic now
        let xLastSaleDate = moment(new Date(latestSaleDate)).format(
          "YYYY-MM-DD"
        );
        let isSoldDateBwLastPrintandToday = propertyUtil.checkIsSoldDateBwLastPrintMinus3MonthsandToday(
          xLastSaleDate,
          lastPMAPrintDate
        );
        let isCongrats = propertyUtil.checkCongratsLogic(
          xLastSaleDate,
          lastPMAPrintDate
        );
        console.log('Anniversary Logic Dates: (latestSaleDate, lastPMAPrintDate, nextPMAPrintDate)', latestSaleDate, lastPMAPrintDate, nextPMAPrintDate);
        let isAnniversary = propertyUtil.checkAnniversaryLogic(
          latestSaleDate,
          lastPMAPrintDate,
          nextPMAPrintDate
        );
        let isExpired =
          listingStatus === "Expired" && isSoldDateBwLastPrintandToday;
        const isAbsentee = isHomeownerAbsentie(
          props,
          isABypassAgent(agentData)
        );
        const isCancelled =
          isPropertyMapped &&
          listingStatus == "Cancelled" &&
          isSoldDateBwLastPrintandToday;
        const isBlocked =
          props.changeDetails &&
          props.changeDetails.blocked &&
          props.changeDetails.blocked !== null &&
          props.changeDetails.blocked !== "Delete"
            ? true
            : false;
        const isDeleted =
          props.changeDetails &&
          props.changeDetails.blocked &&
          props.changeDetails.blocked !== null &&
          props.changeDetails.blocked === "Delete"
            ? true
            : false;
        const isActive =
          !props.changeDetails ||
          !props.changeDetails.blocked ||
          props.changeDetails.blocked === null;
        const duplicateMailingAddress =
          props.changeDetails.blocked &&
          props.changeDetails.blocked === "Duplicate Mailing Addresses";

        //build object now
        props.HOQuickFilterData = {
          isCongrats,
          isAnniversary,
          isExpired,
          isAbsentee,
          isCancelled,
          isBlocked,
          isDeleted,
          isActive,
          duplicateMailingAddress,
        };
      } else {
        props.HOQuickFilterData = {};
      }
    });
  }
  return propertyData;
};

const getTagsComments = (changeDetails) => {
  const { tagsNotes } = changeDetails ? changeDetails : {};
  return (
    tagsNotes &&
    tagsNotes.replace(/<[^>]+>/g, "").replace(/(&nbsp;|<br>|<br \/>)/g, "")
  );
};

const getTagsAndCustomTags = (changeDetails) => {
  const { tags, customTags } = changeDetails ? changeDetails : {};
  return { tags, customTags };
};

const getAllAdvanceFilterOptionsSet = (properties, agentData) => {
  let allTags = [];
  let allPropertyTypes = [];
  properties.map((property) => {
    const { tags } = getTagsAndCustomTags(property.changeDetails);
    let xAutoTag = getAutoTagMapping(property.propertyDetails, agentData);
    allTags = [...allTags, ...xAutoTag];
    const propertyType = getPropertyType(property);
    if (Array.isArray(tags)) {
      allTags = [...allTags, ...tags];
    }
    if (propertyType) {
      allPropertyTypes.push(propertyType);
    }
  });
  return {
    property_tags: [...new Set(allTags)],
    property_types: [...new Set(allPropertyTypes)],
  };
};

const advanceHOFilter = (
  property,
  agentData,
  hoAdvanceFilter,
  advanceFilterOptionData
) => {
  const { changeDetails } = property;
  if (hoAdvanceFilter === "property_first_ho") {
    const { lgOwnerFirstName, lgSecondaryOwnerFirstName } = changeDetails
      ? changeDetails
      : {};
    return !!(
      propertyUtil.isValidHOName(lgOwnerFirstName) &&
      !propertyUtil.isValidHOName(lgSecondaryOwnerFirstName)
    );
  } else if (hoAdvanceFilter === "property_both_ho") {
    const { lgOwnerFirstName, lgSecondaryOwnerFirstName } = changeDetails
      ? changeDetails
      : {};
    return !!(
      propertyUtil.isValidHOName(lgOwnerFirstName) &&
      propertyUtil.isValidHOName(lgSecondaryOwnerFirstName)
    );
  } else if (hoAdvanceFilter === "property_ho_not_modified") {
    const { lgIsOwnerNameModified } = changeDetails ? changeDetails : {};
    return lgIsOwnerNameModified === true ? false : true;
  } else if (hoAdvanceFilter === "property_ho_modified") {
    const { lgIsOwnerNameModified } = changeDetails ? changeDetails : {};
    return lgIsOwnerNameModified === true ? true : false;
  } else if (hoAdvanceFilter === "property_pn_not_ps") {
    //personla ps is ps..
    //textPS is personla note
    const { textPS, personalPS } = changeDetails ? changeDetails : {};
    return !!(!personalPS && textPS);
  } else if (hoAdvanceFilter === "property_not_pn_ps") {
    const { textPS, personalPS } = changeDetails ? changeDetails : {};
    return !!(personalPS && !textPS);
  } else if (hoAdvanceFilter === "property_not_pn_not_ps") {
    const { textPS, personalPS } = changeDetails ? changeDetails : {};
    return !!(!personalPS && !textPS);
  } else if (hoAdvanceFilter === "property_pn_ps") {
    const { textPS, personalPS } = changeDetails ? changeDetails : {};
    return !!(personalPS && textPS);
  } else if (hoAdvanceFilter === "added_comments") {
    const tagsNotesPlain = getTagsComments(changeDetails);
    return !!tagsNotesPlain;
  } else if (hoAdvanceFilter === "adu_properties") {
    const isADU = isPropertyIsADU(property);
    return isADU;
  } else if (hoAdvanceFilter === "property_tags") {
    const selectedTags =
      advanceFilterOptionData && advanceFilterOptionData[hoAdvanceFilter]
        ? advanceFilterOptionData[hoAdvanceFilter]
        : [];
    const { tags } = getTagsAndCustomTags(property.changeDetails);
    const autoTags = getAutoTagMapping(property.propertyDetails, agentData);
    const finalTags = [...(Array.isArray(tags) ? tags : []), ...autoTags];
    return finalTags.some((r) => selectedTags.indexOf(r) >= 0);
  } else if (hoAdvanceFilter === "property_types") {
    const selectedPropertyTypes =
      advanceFilterOptionData && advanceFilterOptionData[hoAdvanceFilter]
        ? advanceFilterOptionData[hoAdvanceFilter]
        : [];
    return selectedPropertyTypes.includes(getPropertyType(property));
  }
  return true;
};

const isPropertyIsADU = (property) => {
  const isADU = (property || {}).isPropertyADUAttached === true;
  return isADU;
};

const getPropertyType = (property) => {
  const { ExpandedPropertyType } = (property || {}).mailingDetails || {};
  return ExpandedPropertyType;
};

const getFormattedSaleDateandPrice = (property) => {
  const { latestSaleDate, latestSalePrice } = property;
  let formattedSold =
    latestSaleDate && latestSaleDate !== "-"
      ? new Date(latestSaleDate).toLocaleDateString("en-US")
      : "";
  let formattedSale =
    parseInt(latestSalePrice) > 0
      ? Number(latestSalePrice).toLocaleString()
      : "";
  let isValidSoldDate = propertyUtil.isValidDate(latestSaleDate);
  return { formattedSold, formattedSale: isValidSoldDate ? formattedSale : "" };
};

const getForceBlockEditing = (agentData) =>
  agentData && agentData.hasOwnProperty("enableMailProAccess")
    ? !agentData.enableMailProAccess
    : false;

const getHOAnniversaryNumber = (property) => {
  const { formattedSold } = renderLogicUtil.getFormattedSaleDateandPrice(
    property
  );
  const soldDateYear = (formattedSold ? formattedSold : "").split("/");
  return new Date().getFullYear() - (soldDateYear ? soldDateYear[2] : 0);
};

const buildNavbarDataForMailPro = (serverNavbarInfoData = {}) => {
  const navbarCompleteData = serverNavbarInfoData;
  const navbarInfoDetails = navbarCompleteData.navbarInfoDetailsForDTOs || [];
  delete navbarCompleteData.navbarInfoDetailsForDTOs;

  const modifiedDetails = navbarInfoDetails.map((detail) => ({
    ...detail,
    ...{ paymentData: navbarCompleteData },
  }));
  return modifiedDetails;
};

const findEmojisInText = (text) => {
  // const emojiRegex = /(?:\p{Emoji}(?<!\p{Nd}))/gu;
  // const emojis = text.match(emojiRegex);
  // const unicodeEntities = emojis ? emojis.map(emoji => `&#x${emoji.codePointAt(0).toString(16).toUpperCase()};`) : [];
  // return { emojis: emojis ? emojis : [], unicodeEntities: unicodeEntities };
};

export const renderLogicUtil = {
  getAutoTagMapping,
  getPropertyTotalTagCount,
  checkGlobalNoteShowHideLogic,
  checkGlobalNoteShowHideLogicV2,
  showNewPropertyMailProDialog,
  showNewAnniversaryMailProDialog,
  countMailProInitProperties,
  parcelNumberBuilder,
  homeownerNamesV3,
  getNoteGreetingTextV3,
  getNoteGreetingTextV4,
  getCurrentMailingPersonalNote,
  getQuickFilterData,
  isHomeownerAbsentie,
  advanceHOFilter,
  getTagsComments,
  getTagsAndCustomTags,
  getAllAdvanceFilterOptionsSet,
  isPropertyIsADU,
  getPropertyType,
  checkPropertyStatuswrtHOFilterStatus,
  getFormattedSaleDateandPrice,
  getForceBlockEditing,
  getHOAnniversaryNumber,
  buildNavbarDataForMailPro,
  findEmojisInText,
};
