import React, { Component } from "react";
import YETwoReviewBoxV4 from "./YETwoReviewBoxV4";
import { propertyUtil } from "views/Pma/PropertyUtil";

class YETwoYearInReview2024 extends Component {
  componentDidMount() {
    if (this.props.printVersion) {
      require("./printv4.scss");
    } else {
      require("./editorv4.scss");
    }
  }
  formatPreviousDate = (date, useYearOverYear) => {
    if (useYearOverYear) {
      return date;
    }
    let splitDate = date.slice(0, -6);

    return splitDate;
  };
  formatCityTitle = (cityName) => {
    const { mlsAreaID } = this.props.currentArea;
    const { capitalizeWords } = propertyUtil;
    if (mlsAreaID === "SMA-277") {
      return "City of Woodside";
    } else if (cityName && cityName.length >= 24) {
      return "Your City";
    } else {
      return capitalizeWords(cityName);
    }
  };
  formatAreaTitle = (areaName, subdivisionName, coverPageHeader) => {
    const { mlsAreaID } = this.props.currentArea;
    if (mlsAreaID === "SMA-277") {
      return "Woodside Neighborhood";
    } else if (coverPageHeader) {
      return coverPageHeader;
    } else if (subdivisionName === "Serra Village/Creekside") {
      return "Your Neighborhood";
    } else if (areaName === "Santa Ynez Valley Ranches") {
      return "SYV Ranches";
    } else if (areaName === "Coastal Ranch") {
      return "Coastal Ranches";
    } else if (subdivisionName) {
      return subdivisionName;
    } else if (areaName && areaName.length >= 24) {
      return "Your Neighborhood";
    } else {
      return areaName;
    }
  };
  divideAreas = (areas) => {
    const { currentArea, hoData } = this.props;
    const { subdivisionName, coverPageHeader } = this.props.hoData;
    const { mlsNeighborhood, mlsCityName } = currentArea;
    const { splitAreas } = propertyUtil;
    let areaSplit = splitAreas(areas);
    let len = areaSplit.length;

    if (mlsNeighborhood == "Santa Barbara Downtown Investment Property") {
      return (
        <div className="y2-year-in-review-area-name-v4">
          <p>Santa Barbara</p>
          <p>Downtown Investment Property</p>
        </div>
      );
    } else if (subdivisionName && subdivisionName.length > 1) {
      return (
        <div className="y2-year-in-review-area-name-v4">
          <p>{subdivisionName}</p>
        </div>
      );
    } else if (len === 3) {
      return (
        <div className="y2-year-in-review-area-name-v4">
          <p>
            {areaSplit[0]} + {areaSplit[1]} +
          </p>
          <p>{areaSplit[2]}</p>
        </div>
      );
    } else {
      return (
        <div className="y2-year-in-review-area-name-v4">
          <p>{areaSplit.join(" + ")}</p>
        </div>
      );
    }
  };

  posOrNeg = (data) => {
    if (data > 0) {
      return `+${Math.abs(data)}`;
    } else if (data === 0) {
      return data;
    } else if (data < 0) {
      return `-${Math.abs(data)}`;
    }
  };

  render() {
    const {
      currentArea,
      aprilData,
      pmaQuarterlyCombinedCityDTO,
      agentData,
      hoData,
      printVersion,
    } = this.props;

    const { listgenUserID } = agentData;
    const {
      posOrNeg,
      formatCityTitle,
      formatAreaTitle,
      formatPreviousDate,
    } = this;

    const { subdivisionName, coverPageHeader } = hoData;
    const { mlsNeighborhood, mlsCityName, mlsAreaID } = currentArea;
    const { roundSalePrice, calculateDiff, splitAreas } = propertyUtil;
    const { pmaQuarterlyCityDTO, pmaQuarterlyAreaDTO } = aprilData;
    let isJacquieAdams = listgenUserID === "103563";
    let useMedianSalePrice = false;
    if (listgenUserID === "102548") {
      useMedianSalePrice = true;
    }
    const { divideAreas } = this;
    let city2020;
    let city2021;
    let useYearOverYear = true;
    let comparisonTitle = "LAST 6 MONTHS VS PREVIOUS 6 MONTHS";
    if (isJacquieAdams == "x") {
      useYearOverYear = true;
      comparisonTitle = "LAST 12 MONTHS VS PREVIOUS 12 MONTHS";
    }
    if (listgenUserID === "106550" || listgenUserID === "105246") {
      comparisonTitle = null;
    }
    if (useYearOverYear) {
      city2020 = pmaQuarterlyCityDTO.marketTrends.find(
        (x) => x.TrendName === "Past Year - Trending"
      );
      city2021 = pmaQuarterlyCityDTO.marketTrends.find(
        (x) => x.TrendName === "Current Year - Trending"
      );
      useYearOverYear = true;
    } else {
      city2020 = pmaQuarterlyCityDTO.marketTrends.find(
        (x) => x.TrendName === "Past 6-Months Trending"
      );
      city2021 = pmaQuarterlyCityDTO.marketTrends.find(
        (x) => x.TrendName === "6-Months Trending"
      );
    }

    let combinedArea2020;
    let combinedArea2021;
    let combinedAreaDiff;

    if (
      pmaQuarterlyCombinedCityDTO &&
      pmaQuarterlyCombinedCityDTO.marketTrends
    ) {
      combinedArea2020 = pmaQuarterlyCombinedCityDTO.marketTrends.find(
        (x) => x.TrendName === "Past Year - Trending"
      );
      combinedArea2021 = pmaQuarterlyCombinedCityDTO.marketTrends.find(
        (x) => x.TrendName === "Current Year - Trending"
      );
      combinedAreaDiff = calculateDiff(
        combinedArea2021.HomesSold,
        combinedArea2020.HomesSold
      );
    }

    let area2020;
    let area2021;
    let startDate;
    if (useYearOverYear) {
      area2020 = pmaQuarterlyAreaDTO.marketTrends.find(
        (x) => x.TrendName === "Past Year - Trending"
      );
      area2021 = pmaQuarterlyAreaDTO.marketTrends.find(
        (x) => x.TrendName === "Current Year - Trending"
      );
      startDate = area2020.TrendsStartDate;
    } else {
      area2020 = pmaQuarterlyAreaDTO.marketTrends.find(
        (x) => x.TrendName === "Past 6-Months Trending"
      );
      area2021 = pmaQuarterlyAreaDTO.marketTrends.find(
        (x) => x.TrendName === "6-Months Trending"
      );
    }

    let cityDiff = calculateDiff(city2021.HomesSold, city2020.HomesSold);

    let areaDiff = calculateDiff(area2021.HomesSold, area2020.HomesSold);

    let isBuellton = mlsAreaID === "SBA-40F" || mlsAreaID === "SBA-40G";
    let citySubtitle = false;
    if (
      mlsAreaID === "SBA-20FCondo" ||
      mlsAreaID === "SBA-20FRes" ||
      mlsAreaID === "SBA-20FInv"
    ) {
      citySubtitle = "(This Includes Single Family Homes and Condos)";
    }
    let show1Area;

    if (mlsAreaID === "x150" || listgenUserID === "102286") {
      show1Area = true;
    }
    // if (
    //   mlsAreaID === "SBA-45H" ||
    //   mlsAreaID === "SBA-45I" ||
    //   mlsAreaID === "46C" ||
    //   mlsAreaID === "108" ||
    //   mlsAreaID === "162" ||
    //   mlsAreaID === "1522155" ||
    //   mlsAreaID === "SCL-10K"
    // ) {
    //   show1Area = true;
    // }
    // console.log(
    //   "city2021.AvgPercentOfReceiving",
    //   city2021.AvgPercentOfReceiving
    // );

    let page2TitleForNonShow1City = (
      <p className="y2-comparing-description-title">
        <span className="large-c">C</span>omparing...
      </p>
    );
    if (listgenUserID === "102843") {
      page2TitleForNonShow1City = (
        <p className="y2-comparing-description-title">
          <span className="large-c">L</span>ocal&nbsp;
          <span className="large-c">D</span>ata
        </p>
      );
    }

    if (show1Area) {
      return (
        <div
          className={
            printVersion
              ? "print-y2-year-in-review-v4"
              : "editor-y2-year-in-review-v4"
          }
        >
          <div className="y2-year-in-review-bg-v4">
            {/*<img
              src="https://d2onbxnrte2nim.cloudfront.net/JAN23_YearEnd_Dashboard-ImageBG_SMALL.jpg"
              alt="dashboard background"
            />*/}
          </div>
          <div className="y2-year-in-review-inner-container-v4">
            <div className="y2-year-in-review-header-v4">
              <div className="y2-year-in-review-title-container-v4">
                <div className="y2-comparing-description-container-v4">
                  <div className="y2-comparing-description-title-row">
                    {page2TitleForNonShow1City}
                  </div>
                  {listgenUserID === "102843" ? (
                    <div className="y2-comparing-description-text-col">
                      <div className="y2-comparing-description-text">
                        See How {mlsNeighborhood} compares
                      </div>
                      <div className="y2-comparing-description-text">
                        year to year
                      </div>
                    </div>
                  ) : (
                    <div className="y2-comparing-description-text-col">
                      <div className="y2-comparing-description-text">
                        Your Neighborhood Trends
                      </div>
                      <div className="y2-comparing-description-text">
                        helps give you a bigger picture of the local market.
                      </div>
                    </div>
                  )}
                </div>

                <div className="y2-year-in-review-header-v4">
                  <div className="y2-year-in-review-title-container-v4">
                    <div className="y2-year-in-review-title-row-v4">
                      <div className="review-title-year-v4">2023</div>
                      <div className="review-title-vs-v4">vs</div>
                      <div className="review-title-year-v4">2024</div>
                    </div>
                    <div className="y2-year-in-review-subtitle-v4">
                      <div className="y2-year-in-review-subtitle-text-v4">
                        Year End Review
                      </div>
                    </div>
                  </div>
                  <div className="y2-year-in-review-area-names-v4"></div>
                </div>
              </div>
            </div>

            {citySubtitle ? (
              <div className="y2-year-in-review-names-row-v4 special-review-mt-negative">
                <div className="y2-review-names-section">
                  <div className="y2-year-in-review-city-name">
                    {formatAreaTitle(
                      mlsNeighborhood,
                      subdivisionName,
                      coverPageHeader
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="y2-year-in-review-names-row-v4">
                <div className="y2-review-names-section">
                  <div className="y2-year-in-review-city-name">
                    {mlsAreaID === "SCL-16J"
                      ? "Your Neighborhood"
                      : mlsAreaID === "SMA-398"
                      ? "Treasure Isle Neighborhood 1"
                      : mlsAreaID === "SMA-392"
                      ? "Isle Cove Neighborhood 9"
                      : mlsAreaID === "SMA-390"
                      ? "Sea Colony Neighborhood 7"
                      : formatAreaTitle(
                          mlsNeighborhood,
                          subdivisionName,
                          coverPageHeader
                        )}
                  </div>
                </div>
              </div>
            )}

            <div className="ye2-year-in-review-body-v4">
              <div className="ye2-year-in-review-col-v4">
                <div className="sideways-previous-6-months-dashboard">2023</div>
                <div className="sideways-past-6-months-dashboard">2024</div>
                <YETwoReviewBoxV4
                  category="Avg Sale Price"
                  useMedianSalePrice={useMedianSalePrice}
                  data2020={
                    useMedianSalePrice
                      ? area2020.MedianSalePrice
                      : area2020.AvgSalePrice
                  }
                  data2021={
                    useMedianSalePrice
                      ? area2021.MedianSalePrice
                      : area2021.AvgSalePrice
                  }
                  sellersAdv={true}
                  index={1}
                />
                <YETwoReviewBoxV4
                  category="Avg Price per SqFt"
                  data2020={area2020.AvgPricePerSqFt}
                  data2021={area2021.AvgPricePerSqFt}
                  sellersAdv={true}
                  index={2}
                />
                <YETwoReviewBoxV4
                  category="% Received of Asking"
                  data2020={area2020.AvgPercentOfReceiving}
                  data2021={area2021.AvgPercentOfReceiving}
                  sellersAdv={true}
                  index={3}
                />
                <YETwoReviewBoxV4
                  category="Avg Days on Market"
                  data2020={area2020.AvgDaysOnMarket}
                  data2021={area2021.AvgDaysOnMarket}
                  sellersAdv={true}
                  index={4}
                />
                {listgenUserID === "102286" && (
                  <div className="year-in-review-disclaimer">
                    <p>*Stats include only Single Family Homes</p>
                  </div>
                )}
                <div className="units-sold-container-v4">
                  <div className="units-sold-second-row">
                    <div className="units-sold-title-v4">Units Sold</div>
                    <div className="units-sold-data-v4">
                      2023: <strong>{area2020.HomesSold}</strong>
                    </div>
                    <div className="units-sold-divider-v4"></div>
                    <div className="units-sold-data-v4">
                      2024: <strong>{area2021.HomesSold}</strong>
                    </div>
                    <div className="units-sold-not-divider-v4"></div>
                    {posOrNeg(areaDiff) === 0 ? (
                      <div className="units-sold-data data-percentage no-change-data-perc-v4">
                        <div>No</div>
                        <div>Change</div>
                      </div>
                    ) : (
                      <div className="units-sold-data-v4 data-percentage-v4">
                        {posOrNeg(areaDiff)}%
                      </div>
                    )}
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={
            printVersion
              ? "print-y2-year-in-review-v4"
              : "editor-y2-year-in-review-v4"
          }
        >
          <div className="y2-year-in-review-bg-v4">
            {/*<img
              src="https://d2onbxnrte2nim.cloudfront.net/JAN23_YearEnd_Dashboard-ImageBG_SMALL.jpg"
              alt="dashboard background"
            />*/}
          </div>
          <div className="y2-year-in-review-inner-container-v4">
            <div className="y2-year-in-review-header-v4">
              <div className="y2-year-in-review-title-container-v4">
                <div className="y2-comparing-description-container-v4">
                  <div className="y2-comparing-description-title-row">
                    {page2TitleForNonShow1City}
                  </div>
                  {listgenUserID === "102843" ? (
                    <div className="y2-comparing-description-text-col">
                      <div className="y2-comparing-description-text">
                        See How {mlsNeighborhood} compares
                      </div>
                      <div className="y2-comparing-description-text">
                        to the rest of {formatCityTitle(mlsCityName)}
                      </div>
                    </div>
                  ) : (
                    <div className="y2-comparing-description-text-col">
                      <div className="y2-comparing-description-text">
                        Your City to Your Neighborhood Trends
                      </div>
                      <div className="y2-comparing-description-text">
                        helps give you a bigger picture of the local market.
                      </div>
                    </div>
                  )}
                </div>

                <div className="y2-year-in-review-header-v4">
                  <div className="y2-year-in-review-title-container-v4">
                    <div className="y2-year-in-review-title-row-v4">
                      <div className="review-title-year-v4">2023</div>
                      <div className="review-title-vs-v4">vs</div>
                      <div className="review-title-year-v4">2024</div>
                    </div>
                    <div className="y2-year-in-review-subtitle-v4">
                      <div className="y2-year-in-review-subtitle-text-v4">
                        Year End Review
                      </div>
                    </div>
                  </div>
                  <div className="y2-year-in-review-area-names-v4"></div>
                </div>
              </div>
            </div>

            {citySubtitle ? (
              <div className="y2-year-in-review-names-row-v4 special-review-mt-negative">
                <div className="y2-review-names-section special-section-1">
                  <div className="y2-year-in-review-special-city-name">
                    {formatCityTitle(mlsCityName)}
                  </div>
                  {citySubtitle && (
                    <div className="y2-year-in-review-area-name-subtitle-v4">
                      {citySubtitle}
                    </div>
                  )}{" "}
                </div>
                <div className="y2-review-names-section">
                  <div className="y2-year-in-review-city-name">
                    {formatAreaTitle(
                      mlsNeighborhood,
                      subdivisionName,
                      coverPageHeader
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="y2-year-in-review-names-row-v4">
                <div className="y2-review-names-section">
                  <div className="y2-year-in-review-city-name">
                    {mlsAreaID === "SCL-16J"
                      ? "Your City"
                      : formatCityTitle(mlsCityName)}
                  </div>{" "}
                </div>
                <div className="y2-review-names-section">
                  <div className="y2-year-in-review-city-name">
                    {mlsAreaID === "SCL-16J"
                      ? "Your Neighborhood"
                      : mlsAreaID === "SMA-398"
                      ? "Treasure Isle Neighborhood 1"
                      : mlsAreaID === "SMA-392"
                      ? "Isle Cove Neighborhood 9"
                      : mlsAreaID === "SMA-390"
                      ? "Sea Colony Neighborhood 7"
                      : formatAreaTitle(
                          mlsNeighborhood,
                          subdivisionName,
                          coverPageHeader
                        )}
                  </div>
                </div>
              </div>
            )}

            <div className="ye2-year-in-review-body-v4">
              <div className="ye2-year-in-review-col-v4">
                <div className="sideways-previous-6-months-dashboard">2023</div>
                <div className="sideways-past-6-months-dashboard">2024</div>
                <YETwoReviewBoxV4
                  category="Avg Sale Price"
                  useMedianSalePrice={useMedianSalePrice}
                  data2020={
                    useMedianSalePrice
                      ? city2020.MedianSalePrice
                      : city2020.AvgSalePrice
                  }
                  data2021={
                    useMedianSalePrice
                      ? city2021.MedianSalePrice
                      : city2021.AvgSalePrice
                  }
                  sellersAdv={true}
                  index={1}
                />
                <YETwoReviewBoxV4
                  category="Avg Price per SqFt"
                  data2020={city2020.AvgPricePerSqFt}
                  data2021={city2021.AvgPricePerSqFt}
                  sellersAdv={true}
                  index={2}
                />
                <YETwoReviewBoxV4
                  category="% Received of Asking"
                  data2020={city2020.AvgPercentOfReceiving}
                  data2021={city2021.AvgPercentOfReceiving}
                  sellersAdv={true}
                  index={3}
                />
                <YETwoReviewBoxV4
                  category="Avg Days on Market"
                  data2020={city2020.AvgDaysOnMarket}
                  data2021={city2021.AvgDaysOnMarket}
                  sellersAdv={true}
                  index={4}
                />
                {listgenUserID === "102286" && (
                  <div className="year-in-review-disclaimer">
                    <p>*Stats include only Single Family Homes</p>
                  </div>
                )}
                <div className="units-sold-container-v4">
                  <div className="units-sold-second-row">
                    <div className="units-sold-title-v4">Units Sold</div>
                    <div className="units-sold-data-v4">
                      2023: <strong>{city2020.HomesSold}</strong>
                    </div>
                    <div className="units-sold-divider-v4"></div>
                    <div className="units-sold-data-v4">
                      2024: <strong>{city2021.HomesSold}</strong>
                    </div>
                    <div className="units-sold-not-divider-v4"></div>
                    {posOrNeg(cityDiff) === 0 ? (
                      <div className="units-sold-data-v4 data-percentage-v4 no-change-data-perc-v4">
                        <div>No</div>
                        <div>Change</div>
                      </div>
                    ) : (
                      <div className="units-sold-data-v4 data-percentage-v4">
                        {posOrNeg(cityDiff)}%
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="ye2-year-in-review-col-v4">
                <div className="sideways-previous-6-months-dashboard">2023</div>
                <div className="sideways-past-6-months-dashboard">2024</div>
                <YETwoReviewBoxV4
                  category="Avg Sale Price"
                  useMedianSalePrice={useMedianSalePrice}
                  data2020={
                    useMedianSalePrice
                      ? area2020.MedianSalePrice
                      : area2020.AvgSalePrice
                  }
                  data2021={
                    useMedianSalePrice
                      ? area2021.MedianSalePrice
                      : area2021.AvgSalePrice
                  }
                  sellersAdv={true}
                  index={1}
                />
                <YETwoReviewBoxV4
                  category="Avg Price per SqFt"
                  data2020={area2020.AvgPricePerSqFt}
                  data2021={area2021.AvgPricePerSqFt}
                  sellersAdv={true}
                  index={2}
                />
                <YETwoReviewBoxV4
                  category="% Received of Asking"
                  data2020={area2020.AvgPercentOfReceiving}
                  data2021={area2021.AvgPercentOfReceiving}
                  sellersAdv={true}
                  index={3}
                />
                <YETwoReviewBoxV4
                  category="Avg Days on Market"
                  data2020={area2020.AvgDaysOnMarket}
                  data2021={area2021.AvgDaysOnMarket}
                  sellersAdv={true}
                  index={4}
                />
                {listgenUserID === "102286" && (
                  <div className="year-in-review-disclaimer">
                    <p>*Stats include only Single Family Homes</p>
                  </div>
                )}
                <div className="units-sold-container-v4">
                  <div className="units-sold-second-row">
                    <div className="units-sold-title-v4">Units Sold</div>
                    <div className="units-sold-data-v4">
                      2023: <strong>{area2020.HomesSold}</strong>
                    </div>
                    <div className="units-sold-divider-v4"></div>
                    <div className="units-sold-data-v4">
                      2024: <strong>{area2021.HomesSold}</strong>
                    </div>
                    <div className="units-sold-not-divider-v4"></div>
                    {posOrNeg(areaDiff) === 0 ? (
                      <div className="units-sold-data data-percentage no-change-data-perc-v4">
                        <div>No</div>
                        <div>Change</div>
                      </div>
                    ) : (
                      <div className="units-sold-data-v4 data-percentage-v4">
                        {posOrNeg(areaDiff)}%
                      </div>
                    )}
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default YETwoYearInReview2024;
