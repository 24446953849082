import React, { Component } from "react";
import YETwoReviewBoxV2 from "./YETwoReviewBoxV2";
import { propertyUtil } from "views/Pma/PropertyUtil";

class V2YearEnd extends Component {
  componentDidMount() {
    if (this.props.printVersion) {
      console.log("this.props.printVersion", this.props.printVersion);
      require("./printv2.scss");
    } else {
      require("./editorv2.scss");
    }
  }
  formatCityTitle = (cityName) => {
    const { capitalizeWords } = propertyUtil;
    if (cityName && cityName.length >= 24) {
      return "Your City";
    } else {
      return capitalizeWords(cityName);
    }
  };
  formatAreaTitle = (areaName, subdivisionName, coverPageHeader) => {
    const { mlsAreaID } = this.props;
    if (coverPageHeader) {
      return coverPageHeader;
    } else if (subdivisionName === "Serra Village/Creekside") {
      return "Your Neighborhood";
    } else if (areaName === "Santa Ynez Valley Ranches") {
      return "SYV Ranches";
    } else if (areaName === "Coastal Ranch") {
      return "Coastal Ranches";
    } else if (subdivisionName) {
      return subdivisionName;
    } else if (areaName && areaName.length >= 24) {
      return "Your Neighborhood";
    } else {
      return areaName;
    }
  };
  divideAreas = (areas) => {
    const { currentArea, hoData } = this.props;
    const { subdivisionName, coverPageHeader } = this.props.hoData;
    const { mlsNeighborhood, mlsCityName } = currentArea;
    const { splitAreas } = propertyUtil;
    let areaSplit = splitAreas(areas);
    let len = areaSplit.length;

    if (mlsNeighborhood == "Santa Barbara Downtown Investment Property") {
      return (
        <div className="y2-year-in-review-area-name-v2">
          <p>Santa Barbara</p>
          <p>Downtown Investment Property</p>
        </div>
      );
    } else if (subdivisionName && subdivisionName.length > 1) {
      return (
        <div className="y2-year-in-review-area-name-v2">
          <p>{subdivisionName}</p>
        </div>
      );
    } else if (len === 3) {
      return (
        <div className="y2-year-in-review-area-name-v2">
          <p>
            {areaSplit[0]} + {areaSplit[1]} +
          </p>
          <p>{areaSplit[2]}</p>
        </div>
      );
    } else {
      return (
        <div className="y2-year-in-review-area-name-v2">
          <p>{areaSplit.join(" + ")}</p>
        </div>
      );
    }
  };

  posOrNeg = (data) => {
    if (data > 0) {
      return `+${Math.abs(data)}`;
    } else if (data === 0) {
      return data;
    } else if (data < 0) {
      return `-${Math.abs(data)}`;
    }
  };

  render() {
    const {
      currentArea,
      aprilData,
      pmaQuarterlyCombinedCityDTO,
      agentData,
      hoData,
    } = this.props;

    const { listgenUserID } = agentData;
    const { posOrNeg, formatCityTitle, formatAreaTitle } = this;
    const { subdivisionName, coverPageHeader } = hoData;
    const { mlsNeighborhood, mlsCityName, mlsAreaID } = currentArea;
    const { roundSalePrice, calculateDiff, splitAreas } = propertyUtil;
    const { pmaQuarterlyCityDTO, pmaQuarterlyAreaDTO } = aprilData;

    const { divideAreas } = this;
    let city2020 = pmaQuarterlyCityDTO.marketTrends.find(
      (x) => x.TrendName === "Past 1-Year Trending"
    );
    let city2021 = pmaQuarterlyCityDTO.marketTrends.find(
      (x) => x.TrendName === "1-Year Trending"
    );
    let combinedArea2020;
    let combinedArea2021;
    let combinedAreaDiff;

    if (
      pmaQuarterlyCombinedCityDTO &&
      pmaQuarterlyCombinedCityDTO.marketTrends
    ) {
      combinedArea2020 = pmaQuarterlyCombinedCityDTO.marketTrends.find(
        (x) => x.TrendName === "Past 1-Year Trending"
      );
      combinedArea2021 = pmaQuarterlyCombinedCityDTO.marketTrends.find(
        (x) => x.TrendName === "1-Year Trending"
      );
      combinedAreaDiff = calculateDiff(
        combinedArea2021.HomesSold,
        combinedArea2020.HomesSold
      );
    }

    // console.log("combinedArea2020", combinedArea2020);
    // console.log("combinedArea2021", combinedArea2021);

    let area2020 = pmaQuarterlyAreaDTO.marketTrends.find(
      (x) => x.TrendName === "Past 1-Year Trending"
    );
    let area2021 = pmaQuarterlyAreaDTO.marketTrends.find(
      (x) => x.TrendName === "1-Year Trending"
    );

    let cityDiff = calculateDiff(city2021.HomesSold, city2020.HomesSold);

    let areaDiff = calculateDiff(area2021.HomesSold, area2020.HomesSold);

    let isBuellton = mlsAreaID === "SBA-40F" || mlsAreaID === "SBA-40G";
    let citySubtitle = false;
    if (
      mlsAreaID === "SBA-20FCondo" ||
      mlsAreaID === "SBA-20FRes" ||
      mlsAreaID === "SBA-20FInv"
    ) {
      citySubtitle = "(This Includes Single Family Homes and Condos)";
    }
    let show1Area;

    if (mlsAreaID === "150") {
      show1Area = "show1City";
    }
    // if (
    //   mlsAreaID === "SBA-45H" ||
    //   mlsAreaID === "SBA-45I" ||
    //   mlsAreaID === "46C" ||
    //   mlsAreaID === "108" ||
    //   mlsAreaID === "162" ||
    //   mlsAreaID === "1522155" ||
    //   mlsAreaID === "SCL-10K"
    // ) {
    //   show1Area = true;
    // }
    // console.log(
    //   "city2021.AvgPercentOfReceiving",
    //   city2021.AvgPercentOfReceiving
    // );

    if (
      isBuellton &&
      pmaQuarterlyCombinedCityDTO &&
      pmaQuarterlyCombinedCityDTO.cityName
    ) {
      let areaLength = splitAreas(pmaQuarterlyCombinedCityDTO.cityName).length;
      return (
        <div className="y2-year-in-review-v2">
          <div className="y2-year-in-review-bg-v2">
            {/*<img
              src="https://d2onbxnrte2nim.cloudfront.net/JAN23_YearEnd_Dashboard-ImageBG_SMALL.jpg"
              alt="dashboard background"
            />*/}
          </div>
          <div className="y2-year-in-review-header-v2">
            <div className="y2-year-in-review-title-container-v2">
              <div className="y2-year-in-review-subtitle-v2">
                <div className="y2-year-in-review-subtitle-text-v2">
                  Year End Review
                </div>
              </div>
              <div className="y2-year-in-review-title-row-v2">
                <div className="review-title-year-v2">2024</div>
                <div className="review-title-vs-v2">vs</div>
                <div className="review-title-year-v2">2024</div>
              </div>
            </div>
            <div className="y2-year-in-review-area-names-v2"></div>
          </div>
          <div className="ye2-year-in-review-body-v2">
            <div className="ye2-year-in-review-col-v2">
              <div className="y2-year-in-review-area-names-column-v2">
                <div className="y2-year-in-review-area-title-v2">
                  Neighboring Cities
                </div>

                {divideAreas(pmaQuarterlyCombinedCityDTO.cityName)}
              </div>
              <div className="ye2-combined-cities-avg-v2">
                [ AVERAGES ARE FOR ALL {areaLength} CITIES COMBINED ]
              </div>

              <YETwoReviewBoxV2
                category="Avg Sale Price"
                data2020={combinedArea2020.AvgSalePrice}
                data2021={combinedArea2021.AvgSalePrice}
                sellersAdv={true}
                index={1}
              />
              <YETwoReviewBoxV2
                category="Avg Days on Market"
                data2020={combinedArea2020.AvgDaysOnMarket}
                data2021={combinedArea2021.AvgDaysOnMarket}
                sellersAdv={true}
                index={2}
              />
              <YETwoReviewBoxV2
                category="% Received of Asking"
                data2020={combinedArea2020.AvgPercentOfReceiving}
                data2021={combinedArea2021.AvgPercentOfReceiving}
                sellersAdv={true}
                index={3}
              />
              <YETwoReviewBoxV2
                category="Avg Price per SqFt"
                data2020={combinedArea2020.AvgPricePerSqFt}
                data2021={combinedArea2021.AvgPricePerSqFt}
                sellersAdv={true}
                index={4}
              />
              <div className="units-sold-container-v2">
                <div className="units-sold-title-v2">Units Sold</div>
                <div className="units-sold-second-row">
                  <div className="units-sold-data-v2">
                    2023: {combinedArea2020.HomesSold}
                  </div>
                  <div className="units-sold-divider-v2"></div>
                  <div className="units-sold-data-v2">
                    2024: {combinedArea2021.HomesSold}
                  </div>
                  <div className="units-sold-not-divider-v2"></div>
                  {posOrNeg(combinedAreaDiff) === 0 ? (
                    <div className="units-sold-data-v2 data-percentage-v2 no-change-data-perc-v2">
                      <div>No</div>
                      <div>Change</div>
                    </div>
                  ) : (
                    <div className="units-sold-data-v2 data-percentage-v2">
                      {posOrNeg(combinedAreaDiff)}%
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="ye2-year-in-review-col-v2">
              <div className="y2-year-in-review-area-names-column-v2">
                <div className="y2-year-in-review-area-title-v2">Your City</div>
                <div className="y2-year-in-review-area-name-v2">
                  {pmaQuarterlyCityDTO.cityName}
                </div>
              </div>

              <YETwoReviewBoxV2
                category="Avg Sale Price"
                data2020={city2020.AvgSalePrice}
                data2021={city2021.AvgSalePrice}
                sellersAdv={true}
                index={1}
              />
              <YETwoReviewBoxV2
                category="Avg Days on Market"
                data2020={city2020.AvgDaysOnMarket}
                data2021={city2021.AvgDaysOnMarket}
                sellersAdv={true}
                index={2}
              />
              <YETwoReviewBoxV2
                category="% Received of Asking"
                data2020={city2020.AvgPercentOfReceiving}
                data2021={city2021.AvgPercentOfReceiving}
                sellersAdv={true}
                index={3}
              />

              <YETwoReviewBoxV2
                category="Avg Price per SqFt"
                data2020={city2020.AvgPricePerSqFt}
                data2021={city2021.AvgPricePerSqFt}
                sellersAdv={true}
                index={4}
              />
              <div className="units-sold-container-v2">
                <div className="units-sold-title-v2">Units Sold</div>
                <div className="units-sold-second-row">
                  <div className="units-sold-data-v2">
                    2023: {city2020.HomesSold}
                  </div>
                  <div className="units-sold-divider-v2"></div>
                  <div className="units-sold-data-v2">
                    2024: {city2021.HomesSold}
                  </div>
                  <div className="units-sold-not-divider-v2"></div>
                  {posOrNeg(cityDiff) === 0 ? (
                    <div className="units-sold-data-v2 data-percentage-v2 no-change-data-perc-v2">
                      <div>No</div>
                      <div>Change</div>
                    </div>
                  ) : (
                    <div className="units-sold-data data-percentage-v2">
                      {posOrNeg(cityDiff)}%
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (show1Area == "show1City") {
      return (
        <div className="y2-year-in-review-v2">
          <div className="y2-year-in-review-bg-v2"></div>
          <div className="y2-year-in-review-inner-container-v2">
            <div className="y2-year-in-review-header-v2">
              <div className="y2-year-in-review-title-container-v2">
                <div className="y2-comparing-description-container-v2">
                  <div className="y2-comparing-description-title-row">
                    <p className="y2-comparing-description-title">
                      <span className="large-c">C</span>omparing
                    </p>
                  </div>
                  <div className="y2-comparing-description-text-col">
                    <div className="y2-comparing-description-text">
                      Comparing your City Trends
                    </div>
                    <div className="y2-comparing-description-text">
                      helps give you a bigger picture of the local market
                    </div>
                  </div>
                </div>

                <div className="y2-year-in-review-title-row-v2">
                  <div className="review-title-year-v2">2023</div>
                  <div className="review-title-vs-v2">vs</div>
                  <div className="review-title-year-v2">2024</div>
                </div>
                <div className="y2-year-in-review-subtitle-v2">
                  <div className="y2-year-in-review-subtitle-text-v2">
                    Year End Review
                  </div>
                </div>
              </div>
            </div>

            {citySubtitle ? (
              <div className="y2-year-in-review-names-row-v2 special-review-mt-negative">
                <div className="y2-review-names-section special-section-1">
                  <div className="y2-year-in-review-special-city-name">
                    {formatCityTitle(mlsCityName)}
                  </div>
                  {citySubtitle && (
                    <div className="y2-year-in-review-area-name-subtitle-v2">
                      {citySubtitle}
                    </div>
                  )}{" "}
                </div>
              </div>
            ) : (
              <div className="y2-year-in-review-names-row-v2">
                <div className="y2-review-names-section">
                  <div className="y2-year-in-review-city-name">
                    {mlsAreaID === "SCL-16J"
                      ? "Your City"
                      : formatCityTitle(mlsCityName)}
                  </div>{" "}
                </div>
              </div>
            )}

            <div className="ye2-year-in-review-body-v2">
              <div className="ye2-year-in-review-col-v2">
                <YETwoReviewBoxV2
                  category="Avg Sale Price"
                  data2020={city2020.AvgSalePrice}
                  data2021={city2021.AvgSalePrice}
                  sellersAdv={true}
                  index={1}
                />
                <YETwoReviewBoxV2
                  category="Avg Days on Market"
                  data2020={city2020.AvgDaysOnMarket}
                  data2021={city2021.AvgDaysOnMarket}
                  sellersAdv={true}
                  index={2}
                />
                <YETwoReviewBoxV2
                  category="% Received of Asking"
                  data2020={city2020.AvgPercentOfReceiving}
                  data2021={city2021.AvgPercentOfReceiving}
                  sellersAdv={true}
                  index={3}
                />

                <YETwoReviewBoxV2
                  category="Avg Price per SqFt"
                  data2020={city2020.AvgPricePerSqFt}
                  data2021={city2021.AvgPricePerSqFt}
                  sellersAdv={true}
                  index={4}
                />
                <div className="units-sold-container-v2">
                  <div className="units-sold-title-v2">Units Sold</div>
                  <div className="units-sold-second-row">
                    <div className="units-sold-data-v2">
                      2023: <strong>{city2020.HomesSold}</strong>
                    </div>
                    <div className="units-sold-divider-v2"></div>
                    <div className="units-sold-data-v2">
                      2024: <strong>{city2021.HomesSold}</strong>
                    </div>
                    <div className="units-sold-not-divider-v2"></div>
                    {posOrNeg(cityDiff) === 0 ? (
                      <div className="units-sold-data-v2 data-percentage-v2 no-change-data-perc-v2">
                        <div>No</div>
                        <div>Change</div>
                      </div>
                    ) : (
                      <div className="units-sold-data-v2 data-percentage-v2">
                        {posOrNeg(cityDiff)}%
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="y2-year-in-review-v2">
          <div className="y2-year-in-review-bg-v2">
            {/*<img
              src="https://d2onbxnrte2nim.cloudfront.net/JAN23_YearEnd_Dashboard-ImageBG_SMALL.jpg"
              alt="dashboard background"
            />*/}
          </div>
          <div className="y2-year-in-review-inner-container-v2">
            <div className="y2-year-in-review-header-v2">
              <div className="y2-year-in-review-title-container-v2">
                <div className="y2-comparing-description-container-v2">
                  <div className="y2-comparing-description-title-row">
                    <p className="y2-comparing-description-title">
                      <span className="large-c">C</span>omparing
                    </p>
                  </div>
                  {listgenUserID === "102843" ? (
                    <div className="y2-comparing-description-text-col">
                      <div className="y2-comparing-description-text">
                        How {mlsNeighborhood} compares
                      </div>
                      <div className="y2-comparing-description-text">
                        to the rest of {formatCityTitle(mlsCityName)}
                      </div>
                    </div>
                  ) : (
                    <div className="y2-comparing-description-text-col">
                      <div className="y2-comparing-description-text">
                        Comparing your City to your Neighborhood Trends
                      </div>
                      <div className="y2-comparing-description-text">
                        helps give you a bigger picture of the local market
                      </div>
                    </div>
                  )}
                </div>

                <div className="y2-year-in-review-title-row-v2">
                  <div className="review-title-year-v2">2023</div>
                  <div className="review-title-vs-v2">vs</div>
                  <div className="review-title-year-v2">2024</div>
                </div>
                <div className="y2-year-in-review-subtitle-v2">
                  <div className="y2-year-in-review-subtitle-text-v2">
                    Year End Review
                  </div>
                </div>
              </div>
            </div>

            {citySubtitle ? (
              <div className="y2-year-in-review-names-row-v2 special-review-mt-negative">
                <div className="y2-review-names-section special-section-1">
                  <div className="y2-year-in-review-special-city-name">
                    {formatCityTitle(mlsCityName)}
                  </div>
                  {citySubtitle && (
                    <div className="y2-year-in-review-area-name-subtitle-v2">
                      {citySubtitle}
                    </div>
                  )}{" "}
                </div>
                <div className="y2-review-names-section">
                  <div className="y2-year-in-review-city-name">
                    {formatAreaTitle(
                      mlsNeighborhood,
                      subdivisionName,
                      coverPageHeader
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="y2-year-in-review-names-row-v2">
                <div className="y2-review-names-section">
                  <div className="y2-year-in-review-city-name">
                    {mlsAreaID === "SCL-16J"
                      ? "Your City"
                      : formatCityTitle(mlsCityName)}
                  </div>{" "}
                </div>
                <div className="y2-review-names-section">
                  <div className="y2-year-in-review-city-name">
                    {mlsAreaID === "SCL-16J"
                      ? "Your Neighborhood"
                      : formatAreaTitle(
                          mlsNeighborhood,
                          subdivisionName,
                          coverPageHeader
                        )}
                  </div>
                </div>
              </div>
            )}

            <div className="ye2-year-in-review-body-v2">
              <div className="ye2-year-in-review-col-v2">
                <YETwoReviewBoxV2
                  category="Avg Sale Price"
                  data2020={city2020.AvgSalePrice}
                  data2021={city2021.AvgSalePrice}
                  sellersAdv={true}
                  index={1}
                />
                <YETwoReviewBoxV2
                  category="Avg Days on Market"
                  data2020={city2020.AvgDaysOnMarket}
                  data2021={city2021.AvgDaysOnMarket}
                  sellersAdv={true}
                  index={2}
                />
                <YETwoReviewBoxV2
                  category="% Received of Asking"
                  data2020={city2020.AvgPercentOfReceiving}
                  data2021={city2021.AvgPercentOfReceiving}
                  sellersAdv={true}
                  index={3}
                />

                <YETwoReviewBoxV2
                  category="Avg Price per SqFt"
                  data2020={city2020.AvgPricePerSqFt}
                  data2021={city2021.AvgPricePerSqFt}
                  sellersAdv={true}
                  index={4}
                />
                <div className="units-sold-container-v2">
                  <div className="units-sold-title-v2">Units Sold</div>
                  <div className="units-sold-second-row">
                    <div className="units-sold-data-v2">
                      2023: <strong>{city2020.HomesSold}</strong>
                    </div>
                    <div className="units-sold-divider-v2"></div>
                    <div className="units-sold-data-v2">
                      2024: <strong>{city2021.HomesSold}</strong>
                    </div>
                    <div className="units-sold-not-divider-v2"></div>
                    {posOrNeg(cityDiff) === 0 ? (
                      <div className="units-sold-data-v2 data-percentage-v2 no-change-data-perc-v2">
                        <div>No</div>
                        <div>Change</div>
                      </div>
                    ) : (
                      <div className="units-sold-data-v2 data-percentage-v2">
                        {posOrNeg(cityDiff)}%
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="ye2-year-in-review-col-v2">
                <YETwoReviewBoxV2
                  category="Avg Sale Price"
                  data2020={area2020.AvgSalePrice}
                  data2021={area2021.AvgSalePrice}
                  sellersAdv={true}
                  index={1}
                />
                <YETwoReviewBoxV2
                  category="Avg Days on Market"
                  data2020={area2020.AvgDaysOnMarket}
                  data2021={area2021.AvgDaysOnMarket}
                  sellersAdv={true}
                  index={2}
                />
                <YETwoReviewBoxV2
                  category="% Received of Asking"
                  data2020={area2020.AvgPercentOfReceiving}
                  data2021={area2021.AvgPercentOfReceiving}
                  sellersAdv={true}
                  index={3}
                />
                <YETwoReviewBoxV2
                  category="Avg Price per SqFt"
                  data2020={area2020.AvgPricePerSqFt}
                  data2021={area2021.AvgPricePerSqFt}
                  sellersAdv={true}
                  index={4}
                />
                <div className="units-sold-container-v2">
                  <div className="units-sold-title-v2">Units Sold</div>
                  <div className="units-sold-second-row">
                    <div className="units-sold-data-v2">
                      2023: <strong>{area2020.HomesSold}</strong>
                    </div>
                    <div className="units-sold-divider-v2"></div>
                    <div className="units-sold-data-v2">
                      2024: <strong>{area2021.HomesSold}</strong>
                    </div>
                    <div className="units-sold-not-divider-v2"></div>
                    {posOrNeg(areaDiff) === 0 ? (
                      <div className="units-sold-data data-percentage no-change-data-perc-v2">
                        <div>No</div>
                        <div>Change</div>
                      </div>
                    ) : (
                      <div className="units-sold-data-v2 data-percentage-v2">
                        {posOrNeg(areaDiff)}%
                      </div>
                    )}
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default V2YearEnd;
