import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";

class YETwoHighlightsProp extends Component {
  extraActiveDetails = (prop, status) => {
    //update2

    const { listgenUserID } = this.props.agentData;

    if (
      (listgenUserID === "106647" && prop.streetNumber === "1030Before") ||
      prop.streetNumber === "1030After"
    ) {
      return "";
    } else if (
      status === "Active" &&
      listgenUserID === "100329" &&
      prop.streetName === "Los Olivos" &&
      prop.streetNumber === "116"
    ) {
      return <span className="bolded-listing-text">NEW PRICE! </span>;
    } else if (
      status === "Active" &&
      listgenUserID !== "104480" &&
      listgenUserID !== "100138" &&
      listgenUserID !== "102527" &&
      listgenUserID !== "100746"
    ) {
      return <span className="bolded-listing-text">NEW LISTING - </span>;
    }
  };

  extraLine = (prop, lineNum) => {
    const { listgenUserID } = this.props.agentData;
    const { streetName, streetNumber } = prop;
    let isSharonWitte = listgenUserID === "105801";
    let isGalliTeam = listgenUserID === "102843";
    let isSophieTsang = listgenUserID === "102567";
    console.log("**prop is", prop);
    if (
      listgenUserID === "105642" &&
      prop.streetName === "Blanchard" &&
      prop.streetNumber === "17596"
    ) {
      return (
        <div className="extra-highlight-prop-line-container">
          <p>Sold for $600K over list price!</p>
        </div>
      );
    } else if (
      listgenUserID === "102527" &&
      prop.streetName === "Allardice" &&
      prop.streetNumber === "855"
    ) {
      return (
        <div className="extra-highlight-prop-line-container">
          <p>Co-Listed with Shari Ornstein</p>
        </div>
      );
    } else if (isSophieTsang) {
      if (streetNumber === "2893" && streetName === "Ramona") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>Sold in 7 days 14% over asking price</p>
          </div>
        );
      } else if (streetNumber === "640" && streetName === "Forest") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>Sold in 11 days, 22% over asking price</p>
          </div>
        );
      } else if (streetNumber === "153" && streetName === "California") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>
              Previously listed with another broker for 320 days and not sold
            </p>
            <p>I sold it in 12 days, over asking price</p>
          </div>
        );
      } else if (streetNumber === "507" && streetName === "Magdalena") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>
              Previously listed with another broker for 92 days and not sold
            </p>
            <p>I sold it in 7 days</p>
          </div>
        );
      }
    } else if (isGalliTeam) {
      if (streetName === "California" && streetNumber === "863") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>Sold with 8 offers and $62k over asking!</p>
          </div>
        );
      } else if (streetName === "Caribou" && streetNumber === "748") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>Sold with 18 offers and $702k over asking!</p>
          </div>
        );
      } else if (streetName === "Bonita" && streetNumber === "1639") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>Sold with 12 offers and $452k over asking!</p>
          </div>
        );
      } else if (streetName === "Condor" && streetNumber === "5756") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>Sold with multiple offers and $200k over asking!</p>
          </div>
        );
      } else if (streetName === "Pettis" && streetNumber === "238") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>Received multiple offers!</p>
          </div>
        );
      } else if (streetName === "Pettis" && streetNumber === "376") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>Received multiple offers!</p>
          </div>
        );
      } else if (streetName === "Mountain View" && streetNumber === "430") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>Won against multiple offers!</p>
          </div>
        );
      } else if (streetName === "View" && streetNumber === "568") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>We received multiple offers!</p>
          </div>
        );
      } else if (streetName === "Bryant" && streetNumber === "312") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>Negotiated under asking price!</p>
          </div>
        );
      } else if (streetName === "Pettis" && streetNumber === "660") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>Won against multiple offers!</p>
          </div>
        );
      } else if (streetName === "Olive" && streetNumber === "63") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>We received 6 offers</p>
            <p>Sold for $312k over asking</p>
          </div>
        );
      } else if (streetName === "Church" && streetNumber === "38") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>Negotiated under asking price!</p>
          </div>
        );
      } else if (streetName === "Nilda" && streetNumber === "1038") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>Sold for $322k over asking</p>
          </div>
        );
      } else if (streetName === "Leona" && streetNumber === "657") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>We received 9 offers!</p>
            <p>Sold for $253k over asking</p>
          </div>
        );
      } else if (streetName === "Nilda" && streetNumber === "1038") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>Sold for $322k over asking</p>
          </div>
        );
      } else if (streetName === "Leona" && streetNumber === "657") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>We received 9 offers!</p>
            <p>Sold for $253k over asking</p>
          </div>
        );
      } else if (streetName === "Hudson" && streetNumber === "1060") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>Sold with 12 offers and $352k over asking!</p>
          </div>
        );
      } else if (streetName === "Bonita" && streetNumber === "1584") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>We received 7 offers!</p>
            <p>Sold for $420k over asking</p>
          </div>
        );
      } else if (streetName === "Everett" && streetNumber === "575") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>Only 5 days on the market!</p>
            <p>Sold for $212k over asking!</p>
          </div>
        );
      } else if (streetName === "Bayview" && streetNumber === "152") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p>Accepted pre-emptive offer!</p>
            <p>Sold for $122k over asking</p>
          </div>
        );
      }
    } else if (isSharonWitte) {
      if (streetName === "Grove" && streetNumber === "3727") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p className="extra-mt-highlight-prop-line">
              We Received 14 Offers!
            </p>
          </div>
        );
      } else if (streetName === "High" && streetNumber === "2251") {
        return (
          <div className="extra-highlight-prop-line-container">
            <p className="extra-mt-highlight-prop-line">
              We Received 10 Offers!
            </p>
          </div>
        );
      }
    } else {
      return "";
    }
  };
  hasExtraRepresentation = (prop) => {
    let extraRepresentationText = "";

    return extraRepresentationText;
  };
  formatStatus = (prop) => {
    let useComingSoon = false;
    const { listgenUserID } = this.props.agentData;
    let isGalliTeam = listgenUserID === "102843";
    let hideSalePrice = false;
    if (listgenUserID === "102843") {
      hideSalePrice = true;
    }
    let hideStatus = false;
    if (isGalliTeam) {
      hideStatus = true;
    }

    let showNone = false;
    let statusTextStyles;
    if (listgenUserID === "102548") {
      statusTextStyles = "no-bold-regular-case";
    }
    if (prop.unitNumber && prop.unitNumber.includes("no status")) {
      return "";
    }
    if (hideStatus) {
      return <div className="no-status-height"></div>;
    } else if (
      (listgenUserID === "106647" && prop.streetNumber === "1030Before") ||
      prop.streetNumber === "1030After"
    ) {
      if (prop.streetNumber === "1030Before") {
        return <div>Hillsborough</div>;
      } else if (prop.streetNumber === "1030After") {
        return <div>Hillsborough</div>;
      }
    } else if (
      listgenUserID === "102356" &&
      prop.streetNumber === "Coming " &&
      prop.streetName === "Soon in Cambrian Park"
    ) {
      return <div>Call for Details</div>;
    } else if (useComingSoon) {
      return (
        <div>
          <div className="extra-status-row">
            <p className="coming-soon-representation">Coming Soon</p>
          </div>
        </div>
      );
    } else if (
      (prop.salePrice === "0" || prop.salePrice === "-") &&
      prop.status === "Sold"
    ) {
      return <div></div>;
    } else if (prop.status === "Active") {
      return (
        <div>
          Actively Listed — Offered at $
          {this.formatSalePrice(prop.listPrice).toLocaleString()}
        </div>
      );
    } else if (prop.status === "Coming Soon") {
      if (prop.listPrice == "0") {
        return (
          <div
            className={`${
              statusTextStyles ? statusTextStyles : "bolded-listing-price"
            }`}
          >
            Coming Soon
          </div>
        );
      } else {
        return (
          <div
            className={`${
              statusTextStyles ? statusTextStyles : "bolded-listing-price"
            }`}
          >
            Coming Soon
          </div>
        );
      }
    } else if (prop.status === "In Escrow") {
      if (prop.listPrice == "0") {
        return (
          <div
            className={`${
              statusTextStyles ? statusTextStyles : "bolded-listing-price"
            }`}
          >
            In Escrow
          </div>
        );
      } else {
        return (
          <div
            className={`${
              statusTextStyles ? statusTextStyles : "bolded-listing-price"
            }`}
          >
            In Escrow
          </div>
        );
      }
    } else if (prop.status === "Contingent") {
      if (prop.listPrice == "0") {
        return (
          <div
            className={`${
              statusTextStyles ? statusTextStyles : "bolded-listing-price"
            }`}
          >
            Pending Sale
          </div>
        );
      } else {
        return (
          <div
            className={`${
              statusTextStyles ? statusTextStyles : "bolded-listing-price"
            }`}
          >
            Pending Sale — Listed for $
            {this.formatSalePrice(prop.listPrice).toLocaleString()}
          </div>
        );
      }
    } else if (prop.status === "Pending") {
      if (
        prop.streetName === "Davis" &&
        prop.streetNumber === "2228" &&
        listgenUserID === "106463"
      ) {
        return (
          <div
            className={`${
              statusTextStyles ? statusTextStyles : "bolded-listing-price"
            }`}
          >
            Currently Pending
          </div>
        );
      } else if (prop.listPrice == "0") {
        return (
          <div
            className={`${
              statusTextStyles ? statusTextStyles : "bolded-listing-price"
            }`}
          >
            Pending Sale
          </div>
        );
      } else {
        return (
          <div
            className={`${
              statusTextStyles ? statusTextStyles : "bolded-listing-price"
            }`}
          >
            Pending Sale — Listed for $
            {this.formatSalePrice(prop.listPrice).toLocaleString()}
          </div>
        );
      }
    } else if (listgenUserID === "105047" || listgenUserID === "102286") {
      return <div>SOLD</div>;
    } else if (
      prop.salePrice === "0" ||
      prop.salePrice === "-" ||
      !prop.salePrice
    ) {
      return <div></div>;
    } else if (
      listgenUserID === "106421" &&
      prop.streetNumber === "821" &&
      prop.streetName === "Regulus"
    ) {
      return (
        <div className="bolded-listing-price-regular-case">
          SOLD OFF MARKET - Sold for $2,865,000
        </div>
      );
    } else if (hideSalePrice) {
      return <div>SOLD</div>;
    } else {
      return (
        <div>
          Sold for ${this.formatSalePrice(prop.salePrice).toLocaleString()}
        </div>
      );
    }
  };
  formatAddress = (prop) => {
    const { emptyValueCheck, formatSuffix, capitalizeWords } = propertyUtil;

    const { listgenUserID, mlsAreaID } = this.props.agentData;
    const { isSellingAgent, status } = prop;
    let constructedAddress;
    let showStreetAddress = false;
    let showCity = false;
    if (
      listgenUserID === "105047" ||
      listgenUserID === "102286" ||
      listgenUserID === "106024" ||
      listgenUserID === "105068" ||
      listgenUserID === "106531" ||
      listgenUserID === "102843" ||
      listgenUserID === "106421" ||
      listgenUserID === "104504" ||
      listgenUserID === "100190"
    ) {
      showCity = true;
    }
    if (
      listgenUserID === "101001" ||
      listgenUserID === "104504" ||
      listgenUserID === "102687" ||
      listgenUserID === "102286" ||
      listgenUserID === "104835" ||
      listgenUserID === "105068" ||
      isSellingAgent ||
      status === "Sold" ||
      listgenUserID === "106531"
    ) {
      showStreetAddress = true;
    }
    let showZipCodes = false;
    if (listgenUserID === "104504") {
      showZipCodes = true;
      //REMOVE FOR February
    }

    if (showZipCodes) {
      let zipCodeNum;
      if (prop.streetNumber === "755" && prop.streetName === "Bonita") {
        zipCodeNum = "95116";
      } else if (
        prop.streetNumber === "1126" &&
        prop.streetName === "Shenandoah"
      ) {
        zipCodeNum = "95125";
      } else if (
        prop.streetNumber === "1221" &&
        prop.streetName === "Sierra Mar"
      ) {
        zipCodeNum = "95118";
      } else if (
        prop.streetNumber === "1809" &&
        prop.streetName === "Kirkmont"
      ) {
        zipCodeNum = "95124";
      } else {
        showZipCodes = false;
      }
      constructedAddress = `${emptyValueCheck(
        prop.streetNumber
      )} ${emptyValueCheck(prop.streetName)}${
        prop.streetSuffix !== "-"
          ? `${formatSuffix(prop.streetSuffix, mlsAreaID)}`
          : ""
      }${
        prop.unitNumber && prop.unitNumber.includes("Co-Listed")
          ? `, ${prop.unitNumber}`
          : prop.unitNumber &&
            prop.unitNumber !== "-" &&
            prop.unitNumber !== null
          ? `, #${prop.unitNumber}`
          : ""
      }${
        showCity ? `, ${capitalizeWords(prop.cityName)}, CA ${zipCodeNum}` : ""
      }`;
    } else if (
      (listgenUserID === "106647" && prop.streetNumber === "1030Before") ||
      prop.streetNumber === "1030After"
    ) {
      if (prop.streetNumber === "1030Before") {
        constructedAddress = "1030 Woodland Drive";
      } else if (prop.streetNumber === "1030After") {
        constructedAddress = "1030 Woodland Drive";
      }
    } else if (showStreetAddress) {
      constructedAddress = `${emptyValueCheck(
        prop.streetNumber
      )} ${emptyValueCheck(prop.streetName)}${
        prop.streetSuffix !== "-"
          ? `${formatSuffix(prop.streetSuffix, mlsAreaID)}`
          : ""
      }${
        (prop.unitNumber && prop.unitNumber.includes("Co-Listed")) ||
        (prop.unitNumber && prop.unitNumber.includes("Private Exclusive!"))
          ? `, ${prop.unitNumber}`
          : prop.unitNumber &&
            prop.unitNumber !== "-" &&
            prop.unitNumber !== null
          ? `, #${prop.unitNumber}`
          : ""
      }${showCity ? `, ${capitalizeWords(prop.cityName)}` : ""}`;
    } else {
      constructedAddress = `${emptyValueCheck(prop.streetName)}${
        prop.streetSuffix !== "-"
          ? `${formatSuffix(prop.streetSuffix, mlsAreaID)}`
          : ""
      }${showCity ? `, ${capitalizeWords(prop.cityName)}` : ""}`;
    }

    return constructedAddress;
  };
  formatSalePrice = (price) => {
    let salePriceOnlyNumbers = ((price || 0) + "").replace(/\D/g, "");
    return Number(salePriceOnlyNumbers);
  };
  determineRepresentation = (prop) => {
    const { isMemberOfTeam, listgenUserID } = this.props.agentData;
    const { use1Prop3Photos } = this.props;
    const { streetName, streetNumber } = prop;
    let isDianneJohnson = listgenUserID === "100988";

    let pronoun;

    let isJulieFlouty = listgenUserID === "106421";
    let useNameRepresentation = false;
    if (listgenUserID === "104747") {
      useNameRepresentation = true;
    }

    if (listgenUserID === "106647" && prop.streetNumber === "1030Before") {
      return "List price was $2,995,000";
    } else if (
      listgenUserID === "106647" &&
      prop.streetNumber === "1030After"
    ) {
      return "Sold price $3,682,000";
    } else if (
      listgenUserID === "104819" &&
      streetName === "Broadway" &&
      streetNumber === "1500"
    ) {
      return "Representing the Sellers";
    } else if (listgenUserID === "103486") {
      pronoun = "";
    } else if (listgenUserID === "100656") {
      pronoun = "C&H";
    } else if (
      isMemberOfTeam ||
      isDianneJohnson ||
      listgenUserID === "102843" ||
      listgenUserID === "104835" ||
      listgenUserID === "104480"
    ) {
      pronoun = "We";
    } else {
      pronoun = "I";
    }
    const {
      isSellingAgent,
      isBuyingAgent,
      salePrice,
      listingAgentName,
      sellingAgentName,
    } = prop;
    let contractedPronoun = "";

    if (useNameRepresentation) {
      if (isSellingAgent && listingAgentName) {
        contractedPronoun =
          listingAgentName && listingAgentName.includes(" ")
            ? listingAgentName.split(" ")[0]
            : listingAgentName;
      } else if (isBuyingAgent && sellingAgentName) {
        contractedPronoun =
          sellingAgentName && sellingAgentName.includes(" ")
            ? sellingAgentName.split(" ")[0]
            : sellingAgentName;
      } else if (
        (prop.streetName === "Hillcrest" && prop.streetNumber == "445") ||
        (prop.streetName === "Edgewood" && prop.streetNumber == "647")
      ) {
        contractedPronoun = "Serena";
        pronoun = "Serena";
      } else if (
        (prop.streetName === "Virginia" && prop.streetNumber == "500") ||
        (prop.streetName === "Nevada" && prop.streetNumber == "519")
      ) {
        contractedPronoun = "Sheldon";
        pronoun = "Sheldon";
      } else {
        contractedPronoun = "We";
      }
    } else if (
      isMemberOfTeam ||
      isDianneJohnson ||
      listgenUserID === "102843" ||
      listgenUserID === "104835" ||
      listgenUserID === "104480"
    ) {
      contractedPronoun = "We're";
    } else {
      contractedPronoun = "I'm";
    }
    // console.log("pronoun", pronoun);
    // console.log("contractedPronoun", contractedPronoun);

    if (
      listgenUserID === "100138" &&
      prop.streetNumber === "128" &&
      prop.streetName === "Navigator"
    ) {
      return "Sold Off Market — I Represented the Sellers";
    } else if (listgenUserID === "106421") {
      if (
        prop.status === "Active" ||
        prop.status === "Pending" ||
        prop.status === "Contingent" ||
        prop.status === "Coming Soon" ||
        prop.status === "In Escrow"
      ) {
        if (isSellingAgent) {
          return `Representing the Sellers`;
        } else if (listgenUserID === "102687") {
          return "Representing the Buyers";
        }
      } else if (isSellingAgent && isBuyingAgent) {
        return "Represented the Sellers and Buyers";
      } else if (isSellingAgent) {
        return `Represented the Sellers`;
      } else if (isBuyingAgent) {
        return `Represented the Buyers`;
      }
    } else if (
      listgenUserID === "105047" ||
      listgenUserID === "102286" ||
      listgenUserID === "106531" ||
      listgenUserID === "104819" ||
      listgenUserID === "103455" ||
      listgenUserID === "106531"
    ) {
      if (
        prop.status === "Active" ||
        prop.status === "Pending" ||
        prop.status === "Contingent" ||
        prop.status === "Coming Soon" ||
        prop.status === "In Escrow"
      ) {
        if (isSellingAgent) {
          return `Representing the Seller`;
        } else if (isBuyingAgent) {
          return "Representing the Buyer";
        }
      } else if (isSellingAgent && isBuyingAgent) {
        return "Represented the Seller and Buyer";
      } else if (isSellingAgent) {
        return `Represented the Seller`;
      } else if (isBuyingAgent) {
        return `Represented the Buyer`;
      }
    } else if (listgenUserID === "102687") {
      if (
        prop.status === "Active" ||
        prop.status === "Pending" ||
        prop.status === "Contingent" ||
        prop.status === "Coming Soon" ||
        prop.status === "In Escrow"
      ) {
        if (isSellingAgent) {
          return `Representing Seller`;
        } else if (listgenUserID === "102687") {
          return "Representing Buyer";
        }
      } else if (isSellingAgent && isBuyingAgent) {
        return "Represented Seller and Buyer";
      } else if (isSellingAgent) {
        return `Represented Seller`;
      } else if (isBuyingAgent) {
        return `Represented Buyer`;
      }
    }
    if (prop.unitNumber && prop.unitNumber.includes("alt representing=")) {
      let formatAltText = prop.unitNumber.split("alt representing=")[1];

      return formatAltText;
    } else if (prop.unitNumber && prop.unitNumber.includes("no representing")) {
      return "";
    } else if (listgenUserID === "102340") {
      return "";
    } else if (
      prop.status === "Active" ||
      prop.status === "Pending" ||
      prop.status === "Contingent" ||
      prop.status === "Coming Soon" ||
      prop.status === "In Escrow"
    ) {
      if (isSellingAgent && isBuyingAgent) {
        return `${contractedPronoun} Representing the Seller and Buyer`;
      } else if (isSellingAgent) {
        return `${contractedPronoun} Representing the Sellers`;
      } else {
        return `${contractedPronoun} Representing the Buyers`;
      }
    } else if (prop.status === "Active" && isDianneJohnson) {
      return `www.WelcomeHomeFoothills.com`;
    } else if (isSellingAgent && isBuyingAgent) {
      return `${pronoun} Represented the Seller and the Buyer`;
    } else if (isSellingAgent && useNameRepresentation) {
      return `${contractedPronoun} Represented the Sellers`;
    } else if (
      isSellingAgent ||
      (listgenUserID === "105" && salePrice !== "-")
    ) {
      return `${pronoun} Represented the Sellers`;
    } else if (isBuyingAgent || listgenUserID === "105") {
      return `${pronoun} Represented the Buyers`;
    }
  };
  shortenCourtesy = (brokerage, isSellingAgent, isBuyingAgent, prop) => {
    const { printVersion, agentData } = this.props;
    const { brokerageName, listgenUserID } = agentData;
    let len = brokerage.length;
    let updatedBrokerage = brokerage.slice();

    if (brokerage === "Village Properties - 1") {
      updatedBrokerage = "Village Properties";
    } else if (
      brokerage === "Berkshire Hathaway HomeServices California Properties" &&
      brokerageName === "Berkshire Hathaway Home Services California Properties"
    ) {
      updatedBrokerage =
        "Berkshire Hathaway HomeServices California Properties";
    }

    if (
      (listgenUserID === "102286" && prop.streetNumber.trim() === "1231") ||
      prop.streetNumber.trim() === "845"
    ) {
      return "";
    } else if (
      listgenUserID === "100462" ||
      (listgenUserID === "102512" &&
        prop.streetNumber.trim() === "2588" &&
        prop.streetName === "Dell") ||
      (listgenUserID === "102512" &&
        prop.streetNumber === "903" &&
        prop.streetName === "Mears")
    ) {
      //remove in JUNE
      return "";
    } else if (listgenUserID === "100210") {
      if ((!isSellingAgent && len >= 35) || (isBuyingAgent && len >= 35)) {
        return `Image courtesy of ${updatedBrokerage.slice(0, 35) + "..."}`;
      } else if (isBuyingAgent || !isSellingAgent) {
        return `Image courtesy of ${updatedBrokerage}`;
      } else {
        return "";
      }
    } else if (
      (!isSellingAgent && brokerageName === updatedBrokerage) ||
      listgenUserID === "101677"
    ) {
      return "";
    } else if (
      isSellingAgent ||
      updatedBrokerage == " " ||
      updatedBrokerage == ""
    ) {
      return "";
    } else if (!isSellingAgent && len >= 35) {
      return `Image courtesy of ${updatedBrokerage.slice(0, 35) + "..."}`;
    } else {
      return `Image courtesy of ${updatedBrokerage}`;
    }
  };
  photoURLToUse = (prop) => {
    let url;
    if (prop.optimizedPhotoURL === "ERROR") {
      url = prop.photoURL;
    } else if (prop.optimizedPhotoURL) {
      url = prop.optimizedPhotoURL;
    } else {
      url = prop.photoURL;
    }
    return url;
  };
  render() {
    let {
      currentSoldListings,
      mlsAreaID,
      isAgentPage,
      setHighlightTitleDialog,
      setHighlightFooterDialog,
      title,
      subtitle,
      footerText1,
      footerText2,
      onHighlightTitleOpen,
      onHighlightFooterOpen,
      agentData,
      printVersion,
      currentArea,
      isDemo,
      openEditDialogFn,
      prop,
      useAlt,
      use1Prop,
      use1Prop3Photos,
      altBanner,
      showBanner,
      firstProp,
      use2Props,
    } = this.props;

    const { listgenUserID, brokerageName } = agentData;
    const {
      handlePropertyHighlightDialog,
      determineRepresentation,
      shortenCourtesy,
      photoURLToUse,
      formatAddress,
      formatStatus,
      hasExtraRepresentation,
      extraLine,
      extraActiveDetails,
    } = this;

    let isCountryClubGate = mlsAreaID === "132" ? true : false;
    const {
      formatSuffix,
      formatBaths,
      toAcre,
      emptyValueCheck,
      endsWithS,
      statusContingentToPending,
    } = propertyUtil;
    let isDianneJohnson = listgenUserID === "100988";
    let isGalliTeam = listgenUserID === "102843";
    let isSophieTsang = listgenUserID === "102567";
    let isSharonWitte = listgenUserID === "105801";
    let isJulieFlouty = listgenUserID === "106421";

    let showTitle = false;
    let specialImagePosition = "";
    let photoFooterStyles;
    let bannerTextStyles;
    if (
      //isGalliTeam ||
      isSharonWitte ||
      isSophieTsang
    ) {
      photoFooterStyles = "less-photo-footer-margin";
      bannerTextStyles = "lower-banner-text";
    }

    if (use2Props) {
      specialImagePosition = "highlight-img-contain";
    }

    // if (use1Prop) {
    //   return (
    //     <div>
    //       <div className="one-prop-banner-row"></div>
    //       <img
    //         src={photoURLToUse(prop)}
    //         className={`property-highlight-photo-0 ${specialImagePosition}`}
    //       />
    //     </div>
    //   );
    // } else

    if (isGalliTeam) {
      return (
        <>
          <div
            className={`row-banner-text ${
              hasExtraRepresentation(prop) && "extra-representation-banner-text"
            } ${bannerTextStyles} galli-team-prop-highlight`}
          >
            <div>{formatAddress(prop)}</div>
            {formatStatus(prop)}
            <div className={`photo-footer-text ${photoFooterStyles}`}>
              <div className="representation-row">
                <div>
                  {" "}
                  {extraActiveDetails(prop, prop.status)}{" "}
                  {determineRepresentation(prop)}
                </div>
              </div>

              {extraLine(prop)}
            </div>
          </div>
          <img
            src={photoURLToUse(prop)}
            className={`property-highlight-photo-0 ${specialImagePosition}`}
          />
        </>
      );
    } else if (use1Prop) {
      return (
        <div>
          <div className="one-prop-banner-row">
            <div className="one-prop-row-banner-text">
              <div style={{ textTransform: "capitalize" }}>
                {formatAddress(prop)}
              </div>
              {formatStatus(prop)}
            </div>
            <div
              className={`one-prop-photo-footer-text ${useAlt && "white-text"}`}
            >
              <div>
                {extraActiveDetails(prop, prop.status)}{" "}
                {determineRepresentation(prop)}
              </div>

              {prop.brokerageName && (
                <div className="brokerage-courtesy">
                  {shortenCourtesy(
                    prop.brokerageName,
                    prop.isSellingAgent,
                    prop.isBuyingAgent,
                    prop
                  )}
                </div>
              )}
            </div>
          </div>
          <img
            src={photoURLToUse(prop)}
            className={`property-highlight-photo-0 ${specialImagePosition}`}
          />
        </div>
      );
    } else if (use1Prop3Photos && !showBanner && altBanner) {
      {
        return (
          <>
            <img
              src={photoURLToUse(prop)}
              className={`property-highlight-photo-0 ${specialImagePosition}`}
            />
          </>
        );
      }
    } else if (use1Prop3Photos && showBanner && altBanner) {
      let courtesy = shortenCourtesy(
        firstProp.brokerageName,
        firstProp.isSellingAgent,
        firstProp.isBuyingAgent,
        firstProp
      );
      {
        return (
          <>
            <div className="row-banner-text lowered-banner">
              <div
                className={`photo-footer-text ${
                  altBanner && "no-mt-banner-text"
                }`}
              >
                <div>
                  {" "}
                  {extraActiveDetails(prop, prop.status)}{" "}
                  {determineRepresentation(firstProp)}
                </div>
                {firstProp.brokerageName && courtesy && (
                  <div className="brokerage-courtesy">
                    {shortenCourtesy(
                      firstProp.brokerageName,
                      firstProp.isSellingAgent,
                      firstProp.isBuyingAgent,
                      firstProp
                    )}
                  </div>
                )}
              </div>
            </div>
            <img
              src={photoURLToUse(prop)}
              className={`property-highlight-photo-0 ${specialImagePosition}`}
            />
          </>
        );
      }
    } else if (use1Prop3Photos) {
      {
        return (
          <>
            <div className="row-banner-text lowered-banner">
              <div>{formatAddress(prop)}</div>
              {formatStatus(prop)}
            </div>
            <img
              src={photoURLToUse(prop)}
              className={`property-highlight-photo-0 ${specialImagePosition}`}
            />
          </>
        );
      }
    } else if (use2Props) {
      let useComingSoon = false;

      if (isGalliTeam) {
        specialImagePosition = "reduced-image-height";
      } else if (isSophieTsang) {
        specialImagePosition = "extra-reduced-image-height";
      }
      return (
        <>
          <div
            className={`row-banner-text ${
              hasExtraRepresentation(prop) && "extra-representation-banner-text"
            } ${bannerTextStyles}`}
          >
            <div>{formatAddress(prop)}</div>
            {formatStatus(prop)}
          </div>
          <img
            src={photoURLToUse(prop)}
            className={`property-highlight-photo-0 ${specialImagePosition}`}
          />
          <div
            className={`photo-footer-text ${
              useAlt && "white-text"
            } ${photoFooterStyles}`}
          >
            <div className="representation-row">
              <div>
                {" "}
                {extraActiveDetails(prop, prop.status)}{" "}
                {determineRepresentation(prop)}
              </div>
            </div>

            {prop.brokerageName &&
            !isGalliTeam &&
            !isSharonWitte &&
            !isSophieTsang &&
            listgenUserID !== "102527" &&
            listgenUserID !== "105642" ? (
              <div className="brokerage-courtesy">
                {shortenCourtesy(
                  prop.brokerageName,
                  prop.isSellingAgent,
                  prop.isBuyingAgent,
                  prop
                )}
              </div>
            ) : (
              <div className="brokerage-courtesy">{extraLine(prop)}</div>
            )}
          </div>
        </>
      );
    } else {
      let useComingSoon = false;

      if (isGalliTeam) {
        specialImagePosition = "reduced-image-height";
      } else if (isSophieTsang) {
        specialImagePosition = "extra-reduced-image-height";
      }
      return (
        <>
          <div
            className={`row-banner-text ${
              hasExtraRepresentation(prop) && "extra-representation-banner-text"
            } ${bannerTextStyles}`}
          >
            <div>{formatAddress(prop)}</div>
            {formatStatus(prop)}
          </div>
          <img
            src={photoURLToUse(prop)}
            className={`property-highlight-photo-0 ${specialImagePosition}`}
          />
          <div
            className={`photo-footer-text ${
              useAlt && "white-text"
            } ${photoFooterStyles}`}
          >
            <div className="representation-row">
              <div>
                {" "}
                {extraActiveDetails(prop, prop.status)}{" "}
                {determineRepresentation(prop)}
              </div>
            </div>

            {prop.brokerageName &&
            !isGalliTeam &&
            !isSharonWitte &&
            !isSophieTsang &&
            listgenUserID !== "102527" &&
            listgenUserID !== "105642" ? (
              <div className="brokerage-courtesy">
                {shortenCourtesy(
                  prop.brokerageName,
                  prop.isSellingAgent,
                  prop.isBuyingAgent,
                  prop
                )}
              </div>
            ) : (
              <div className="brokerage-courtesy">{extraLine(prop)}</div>
            )}
          </div>
        </>
      );
    }
  }
}

export default YETwoHighlightsProp;
